// import { api } from "@/services/api";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { api } from "../services/api";
import { getUsuarioSecretaria, userByEmail } from "../providers/users";

interface UserProviderProps {
  children: ReactNode;
}

type TSecretaria = {
  id: number;
  nome: string;
  sigla: string;
  current: boolean;
}

interface UserContextData {
  user: User;
  updateUser(user: User): void;
  createUser(user: User): void;
  createToken(tk?: string, refreshToken?: string): void;
  setSecretarias(tk?: string): void;
  handleSetSecretariaSelected(sec: TSecretaria): void;
  removeToken(): void;
  token: string;
  refreshToken: string;
  secretariasUser: TSecretaria[];
  secretariaSelected: TSecretaria
};

interface User {
  name?: string;
  email?: string;
  username?: string;
  initial?: string;
}

const UserContext = createContext({} as UserContextData);

export function UserProvider({children}: UserProviderProps) {
  const [token, setToken] = useState(() => {
    const storageToken = localStorage.getItem('ProcessoGov:token');
    if (!!storageToken) {
      api.defaults.headers.authorization = `Bearer ${storageToken}`;
      return storageToken;
    }
    return '';
  })
  const [refreshToken, setRefreshToken] = useState(() => {
    const storageToken = localStorage.getItem('ProcessoGov:refreshToken');
    if (!!storageToken) {
      return storageToken;
    }
    return '';
  })

  const [user, setUser] = useState<User>({} as User);
  const [secretariasUser, setSecretariasUser] = useState<TSecretaria[]>([]);
  const [secretariaSelected, setSecretariaSelected] = useState<TSecretaria>({} as TSecretaria)

  function signOut() {
    removeToken();
  }

  function refreshTokenUpdated(newToken: string, newRefreshToken: string) {
    localStorage.setItem('ProcessoGov:token', newToken)
    localStorage.setItem('ProcessoGov:refreshToken', newRefreshToken)
    setToken(newToken);
    setToken(newRefreshToken);
  }

  useEffect(() => {
    const subscribe = api.registerInterceptTokenManager({
      signOut,
      refreshTokenUpdated,
    });

    return () => {
      subscribe();
    };
  }, [signOut]);

  useEffect(() => {
    const storageToken = localStorage.getItem('ProcessoGov:token');
    if (!!storageToken) {
      api.defaults.headers.authorization = `Bearer ${storageToken}`;
      const decoded = jwtDecode(storageToken) as any;
      const initial = getInitials(decoded.name);

      setUser({
        email: decoded.email,
        name: decoded.name,
        username: decoded.preferred_username,
        initial
      })

      setToken(storageToken)
    }

    const storageRefreshToken = localStorage.getItem('ProcessoGov:refreshToken');
    if (!!storageRefreshToken) {
      setRefreshToken(storageRefreshToken);
    }

    const storageSecretariaSelected = localStorage.getItem('ProcessoGov:currentSecretaria');
    console.log('storageSecretariaSelected', storageSecretariaSelected)
    if (!!storageSecretariaSelected) {
      const parseStorageSecretariaSelected = JSON.parse(storageSecretariaSelected);
      setSecretariaSelected(parseStorageSecretariaSelected)
    }

  }, [])

  useEffect(() => {
    const storageSecretariaSelected = localStorage.getItem('ProcessoGov:currentSecretaria');
    if (secretariasUser.length > 0 && !storageSecretariaSelected) {
      setSecretariaSelected(secretariasUser[0])
    }
  }, [secretariasUser])
  
  function updateUser(user: User) {
    
  }

  function createUser(user: User) {
    
  }

  function handleSetSecretariaSelected(item: TSecretaria) {
    localStorage.setItem('ProcessoGov:currentSecretaria', JSON.stringify(item));
    setSecretariaSelected(item);
  }

  function removeToken() {
    localStorage.removeItem('ProcessoGov:token')
    localStorage.removeItem('ProcessoGov:refreshToken')
    setUser({} as User);
    setToken('');
    setRefreshToken('');
    delete api.defaults.headers.authorization;
  }

  function createToken(tk = '', refreshToken = '') {
    localStorage.setItem('ProcessoGov:token', tk)
    localStorage.setItem('ProcessoGov:refreshToken', refreshToken)
    setToken(tk);
    setRefreshToken(refreshToken)

    const decoded = jwtDecode(tk) as any;

    const initial = getInitials(decoded.name);

    setUser({
      email: decoded.email,
      name: decoded.name,
      username: decoded.preferred_username,
      initial
    })

    api.defaults.headers.authorization = `Bearer ${token}`;
  }

  async function setSecretarias(tk: string) {
    try {
      const decoded = jwtDecode(tk) as any;
      const response = await userByEmail(decoded.email)

      if (response.length) {
    
        const responseSecretarias = await getUsuarioSecretaria(response[0].id)
        const formatSecretarias = responseSecretarias.map(rs => {

          return {
            id: rs.secretaria.id,
            nome: rs.secretaria.nome,
            sigla: rs.secretaria.sigla,
            current: secretariaSelected.id === rs.secretaria.id
          }
        })

        setSecretariasUser(formatSecretarias)

        
      }

    } catch {

    }
  }

  function getInitials(name: string) {
    const nameArray = name.split(" ")
    const firstNameIn = nameArray[0].charAt(0).toUpperCase()
    const lastNameIn = nameArray[nameArray.length - 1].charAt(0).toUpperCase()
    return firstNameIn + lastNameIn
  }

  return (
    <UserContext.Provider value={{user, updateUser, createUser, token, createToken, removeToken, refreshToken, setSecretarias, secretariasUser, handleSetSecretariaSelected, secretariaSelected}}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext);