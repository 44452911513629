import { api } from "../services/api";

type TParamsCreate = {
  nome: string;
  riscoEnum: string;
  comarca: string;
  estimativa: string;
  valor: number;
  registro: string;
  parteAdversa: string;
  statusEnum: string;
  secretariaId: number;
}

export type TResponseAllDemanda = {
  nome: string;
  riscoEnum: string;
  comarca: string;
  estimativa: string;
  valor: number;
  registro: string;
  parteAdversa: string;
  statusEnum: string;
  secretariaId: number;
  id: number;
}


export async function createDemanda(params: TParamsCreate) {
  try {
    const { data } = await api.post(`/demandas`, params);
    return data
  } catch(err) {
    throw err;
  }
}

export async function updateDemanda(id: number, params: TParamsCreate) {
  try {
    const { data } = await api.put(`/demandas/${id}`, params);
    return data
  } catch(err) {
    throw err;
  }
}

export async function deleteDemanda(id: number) {
  try {
    const { data } = await api.delete(`/demandas/${id}`);
    return data
  } catch(err) {
    throw err;
  }
}



export async function allDemandas(secretariaId: number): Promise<TResponseAllDemanda[]> {
  try {
    const { data } = await api.get(`/demandas`, {
      params: {
        secretariaId
      }
    });
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function demandaById(id: number): Promise<TResponseAllDemanda> {
  try {
    const { data } = await api.get(`/demandas/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

type TParamsAtuacao = {
  descricao: string;
  demandaId: number;
}

export type TAtuacao = {
  descricao: string;
  id: number;
  autor: string;
  dataCriacao: string;
}

export async function createDemandaAtuacao(params: TParamsAtuacao) {
  try {
    const { data } = await api.post(`/demandas/atuacoes`, params);
    return data
  } catch(err) {
    throw err;
  }
}

export async function demandasAtuacaoByIdDemanda(id: number): Promise<TAtuacao[]> {
  try {
    const { data } = await api.get(`/demandas/${id}/atuacoes`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function demandasAtuacaoById(id: number): Promise<TAtuacao> {
  try {
    const { data } = await api.get(`/demandas/atuacoes/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function deleteDemandasAtuacao(id: number): Promise<TAtuacao> {
  try {
    const { data } = await api.delete(`/demandas/atuacoes/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function updateDemandasAtuacao(id: number, params: TParamsAgendamento): Promise<TAtuacao> {
  try {
    const { data } = await api.put(`/demandas/atuacoes/${id}`, params);
    return data.data;
  } catch(err) {
    throw err;
  }
}

type TParamsAgendamento = {
  nome: string;
  descricao: string;
  prazo: string;
  demandaId: number;
}

export async function createDemandaAgendamento(params: TParamsAgendamento) {
  try {
    const { data } = await api.post(`/demandas/agendamentos`, params);
    return data
  } catch(err) {
    throw err;
  }
}

export type TAgendamento = {
  id: number;
  nome: string;
  descricao: string;
  prazo: string;
  demandaId: number;
}

export async function demandasAgendamentoByIdDemanda(id: number): Promise<TAgendamento[]> {
  try {
    const { data } = await api.get(`/demandas/${id}/agendamentos`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function updateDemandaAgendamento(id: number, params: TParamsAgendamento) {
  try {
    const { data } = await api.put(`/demandas/agendamentos/${id}`, params);
    return data
  } catch(err) {
    throw err;
  }
}

export async function deleteDemandaAgendamento(id: number) {
  try {
    const { data } = await api.delete(`/demandas/agendamentos/${id}`);
    return data
  } catch(err) {
    throw err;
  }
}

export async function demandaAgendamentoById(id: number): Promise<TAgendamento> {
  try {
    const { data } = await api.get(`/demandas/agendamentos/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allDemandaAgendamentos(secretariaId: number): Promise<TResponseAllDemanda[]> {
  try {
    const { data } = await api.get(`/demandas/agendamentos`, {
      params: {
        secretariaId
      }
    });
    return data.data;
  } catch (err) {
    throw err;
  }
}