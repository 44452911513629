import { useState } from "react";
import { TSolucaoInicial } from "../../providers/solucao-inicial"
import { DialogFormSolucao } from "../DialogFormSolucao";

type TProps = {
  data: TSolucaoInicial;
}

export function CardSolucao({ data }: TProps) {
  const [openForm, setOpenForm] = useState(false)

  function handleOpen() {
    setOpenForm((cur) => !cur)
  }

  return (
    <div className="flex flex-col justify-between p-[16px] bg-[#F5F8FA] mr-[16px] min-w-[270px] max-w-[270px] h-[380px] mb-[8px]">
      <div>
        <div>
          <h1 className="text-dark-tint text-lg font-semibold">{data.nome}</h1>
        </div>
        <div className="my-[8px]">
          <p className="text-base font-normal text-dark-tint overflow-hidden">{data.descricao}</p>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="h-[24px] px-[16px] pt-[1px] items-center justify-between border border-blue-card text-blue-card bg-blue-card-opacity rounded-full text-sm font-semibold">
          <h1>{data.status?.nome}</h1>
        </div>
        <div className="flex flex-row items-center">
          <a className="font-light text-[#3A57F2] text-sm cursor-pointer" onClick={handleOpen}>Visualizar {'>'}</a>
        </div>
      </div>
      <DialogFormSolucao open={openForm} handleOpen={handleOpen} idSituacao={data.situacaoId} solucao={data} />
    </div>
  )
}