import { Alert } from "@material-tailwind/react";
import { FaSearch } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";

type TProps = {
  text: string;
}

export function BoxError({ text }: TProps) {
  return (
    <Alert variant="outlined" className="flex flex-row items-center justify-center h-[100px] border-dashed border-red-card bg-red-card-opacity">
      <div className="flex">
        <MdErrorOutline size={28} color="#f00" />
        <h1 className="font-medium text-xl ml-[16px] text-red-card">{text}</h1>
      </div>
    </Alert>
  )
}