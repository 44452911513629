import { Alert, Button, Spinner } from "@material-tailwind/react";
import { useAllSituacao } from "../../hooks/query/useSituacaoQuery";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { DialogFormSituacao } from "../../components/DialogFormSituacao";
import { EmptyState } from "../../components/EmptyState";
import { BoxError } from "../../components/BoxError";
import { formatTipoCrise } from "../../utils/situacao.utils";

export function ListSituacao() {
  const navigate = useNavigate();

  const [openModalFormSituacao, setOpenModalFormSituacao] = useState(false);

  const allSituacao = useAllSituacao();
  const situacoes = allSituacao.data || [];

  function handleOpenModalFormSituacao() {
    setOpenModalFormSituacao((prev) => !prev);
  }

  function handleGoSituacao(id: number) {
    navigate(`/internal/sala-situacao/${id}`)
  }

  function formatDate(date: string) {
    try {
      const splitDate = date.split('T');
      const splitDate2 = splitDate[0].split('-');
      // return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
      return `${splitDate2[0]}`;
    } catch(err) {
      return ''
    }
  }

  return (
    <div>
      <div className="flex flex-col mb-[24px] lg:items-center lg:justify-between  lg:flex-row">
        <div>
          <h1 className="text-[24px] text-dark-base lg:text-[24px]">Sala de Situação</h1>
        </div>
        <div>
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button w-full lg:w-auto" variant="outlined" onClick={handleOpenModalFormSituacao}>Nova situação</Button>
        </div>
      </div>

      {!allSituacao.isLoading && allSituacao.isSuccess && (
        <div>
          {situacoes.length > 0 ? (
            <div className="grid lg:grid-cols-2 gap-4">
              {situacoes.map(s => (
                <div key={s.id} className="p-[24px] border border-[#F0F1F3] bg-[#F5F8FA]">
                  <div className="mb-[24px] lg:flex lg:flex-row lg:items-center">
                    <div className="flex flex-col flex-[0.7]">
                      <h1 className="text-2xl text-dark-tint font-bold mb-[4px]">{s.nome}</h1>
                      <h6>Ano: {!!s.dataInicial ? formatDate(s.dataInicial) : '-'}</h6>
                    </div>
                    <div className="w-full h-[0.5px] bg-[#E4E4E4] mt-[14px] mb-[14px] lg:hidden"></div>
                    <div>
                      <div className="flex flex-col flex-[0.3] items-start pb-[10px] border-b border-b-[#F1F1F1]">
                        <label className="text-xs text-dark-tint font-light">Tipo de crise</label>
                        <p className="text-dark-base font-normal text-base capitalize">{!!s.tipoCriseEnum ? formatTipoCrise(s.tipoCriseEnum) : '-'}</p>
                      </div>
                      <div className="flex flex-col flex-[0.3] items-start mt-[10px] pb-[10px] border-b border-b-[#F1F1F1]">
                        <label className="text-xs text-dark-tint font-light">Frequência da ocorrência</label>
                        <p className="text-dark-base font-normal text-base capitalize">{!! s.frequenciaOcorrenciaEnum ? s.frequenciaOcorrenciaEnum.toLowerCase() : '-'}</p>
                      </div>
                      <div className="flex flex-col flex-[0.3] items-start mt-[10px]">
                        <label className="text-xs text-dark-tint font-light">Gravidade da ocorrência</label>
                        <p className="text-dark-base font-normal text-base capitalize">{!!s.gravidadeOcorrenciaEnum ? s.gravidadeOcorrenciaEnum.toLowerCase() : '-'}</p>
                      </div>
                    </div>
                  </div>
                  <Button className="normal-case text-sm font-medium text-blue-button bg-white border-blue-button border-solid" fullWidth variant="outlined" onClick={() => handleGoSituacao(s.id)}>Acessar situação</Button>
                </div>
              ))}
            </div>
          ) : (
            <EmptyState text="Nenhuma situação cadastrada" />
          )}
        </div>
      )}

      {allSituacao.isLoading && (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!allSituacao.isLoading && allSituacao.isError && (
        <div className="w-full">
          <BoxError text="Erro ao carregar as informações" />
        </div>
      )}

      <DialogFormSituacao
        handleOpen={handleOpenModalFormSituacao}
        open={openModalFormSituacao}
      />
    </div>
  )
}