import { useFindEstrategiaBySituacaoId } from "../../../hooks/query/useEstrategiaQuery";

type TProps = {
  idSituacao: number;
}

export function BoxEstrategia({ idSituacao }: TProps) {

  const estrategiasQuery = useFindEstrategiaBySituacaoId(idSituacao);
  const estrategias = estrategiasQuery.data || [];

  return (
    <>
      {!estrategiasQuery.isLoading && estrategiasQuery.isSuccess && (
        estrategias.length > 0 ? (
          estrategias.map((estrategia, index) => (
            <div key={index} className="mt-[30px] bg-white rounded border border-[#F0F1F3] p-[16px]">
              <div className="pb-[16px]">
                <h1 className="text-dark-base text-3xl font-medium">{estrategia.nome}</h1>
              </div>
              <div className="w-full h-[1px] bg-[#CBCBCB] mb-[16px]"></div>
              <div>
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Descrição</label>
                  <h1 className="text-dark-base text-2xl font-normal">{estrategia.descricao}</h1>
                </div>
              </div>
              <div className="grid grid-cols-2 mt-[16px]">
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Recurso estimado</label>
                  <h1 className="text-dark-base text-2xl font-normal">{estrategia.recursoEstimado}</h1>
                </div>
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Custo previsto</label>
                  <h1 className="text-dark-base text-2xl font-normal">{estrategia.custoPrevisto}</h1>
                </div>
              </div>
              <div className="grid grid-cols-2 mt-[16px]">
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Prazo estimado</label>
                  <h1 className="text-dark-base text-2xl font-normal">{estrategia.prazoEstimado}</h1>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-[30px] bg-white rounded border border-[#F0F1F3] p-[16px]">
            <div className="mb-[16px]">
              <h1 className="text-dark-base text-3xl font-medium">Nenhuma estratégia encontrada</h1>
            </div>
          </div>
        )
      )}
    </>
  )
}