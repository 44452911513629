import { api } from "../services/api";

type TNameId = {
  id: number;
  nome: string;
}

type TSec = {
  id: number;
  nome: string;
  sigla: string;
  nucleo: {
    nome:string;
  }
}

export type TProjetoContent = {
  id: number;
  nome: string;
  eixo: TNameId;
  ultimaAcaoRealizada: string;
  dataRealizacao: string;
  proximaAcao: string;
  prazoProximaAcao: string;
  tipoProjetoEnum: string;
  objeto: string;
  regiao: TNameId;
  etapa: TNameId;
  responsavel: string;
  secretaria: TSec;
  status: TNameId;
  registro: TNameId;
  orgao: TNameId;
  prazoFinal: string;
  valorGlobal: number;
  valorFinanceiro: number;
  percentualExecutado: number;
  acao: string;
  prazo: string;
  atuacao: string;
  monitoramento: true,
  custoPrevisto: number;
  recursoNecessario: string;
  pautaEnum: string;
  convenio: string;
  empresa: string;
  ano: number;
  origem: string;
  valorFinanceiroExecutar: number;
  qtdDiasPrazo: number;
  favorito: boolean;
  favoritoEstado: boolean;
}

export type TProjeto = {
  content: TProjetoContent;
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}

export type TBodyProjeto = {
  nome: string;
  eixoId: string;
  ultimaAcaoRealizada: string;
  dataRealizacao: string;
  proximaAcao: string;
  prazoProximaAcao: string;
  tipoProjetoEnum: string;
  objeto: string;
  regiaoId: number;
  etapaId: string;
  responsavel: string;
  secretariaId: string;
  statusId: string;
  registroId: string;
  orgaoId: string;
  prazoFinal: string;
  valorGlobal: number;
  valorFinanceiro: number;
  valorFinanceiroExecutado: number;
  percentualExecutado: number;
  acao: string;
  prazo: string;
  atuacao: string;
  monitoramento: true,
  custoPrevisto: number;
  recursoNecessario: string;
  pautaEnum: string;
  convenio: string;
  empresa: string;
  ano: number;
  origem: string;
}

export async function allProjeto(page: number, submitSearch: any = {}): Promise<TProjeto> {

  const params: any = {
    pagina: page - 1,
    tamanho: 6,
  }

  if (!!submitSearch.nome) {
    params.nome = submitSearch.nome;
  }

  try {
    const { data } = await api.get(`/projetos`, {
      params
    });
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoSecretaria(secretariaId: number): Promise<any> {

  const params: any = {
    pagina: 0,
    tamanho: 99,
    secretariaId: secretariaId
  }


  try {
    const { data } = await api.get(`/projetos`, {
      params
    });
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoExecucao(page: number, submitSearch: any = {}): Promise<TProjeto> {

  const params: any = {
    pagina: page - 1,
    tamanho: 6,
  }

  if (!!submitSearch.nome) {
    params.nome = submitSearch.nome;
  }

  try {
    const { data } = await api.get(`/projetos/execucao`, {
      params
    });
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoEstrategico(page: number, submitSearch: any = {}): Promise<TProjeto> {

  const params: any = {
    pagina: page - 1,
    tamanho: 6,
  }

  if (!!submitSearch.nome) {
    params.nome = submitSearch.nome;
  }

  try {
    const { data } = await api.get(`/projetos/estrategicos`, {
      params
    });
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoCaptacaoRecurso(page: number, submitSearch: any = {}): Promise<TProjeto> {

  const params: any = {
    pagina: page - 1,
    tamanho: 6,
  }

  if (!!submitSearch.nome) {
    params.nome = submitSearch.nome;
  }

  try {
    const { data } = await api.get(`/projetos/captacao-recursos`, {
      params
    });
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoFavoritoPage(page: number, submitSearch: any = {}, secretariaId: number): Promise<TProjeto> {

  const params: any = {
    pagina: page - 1,
    tamanho: 6,
    secretariaId
  }

  if (!!submitSearch.nome) {
    params.nome = submitSearch.nome;
  }

  try {
    const { data } = await api.get(`/projetos/favoritos`, {
      params
    });
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoFavoritoEstadoPage(page: number, submitSearch: any = {}): Promise<TProjeto> {

  const params: any = {
    pagina: page - 1,
    tamanho: 6,
  }

  if (!!submitSearch.nome) {
    params.nome = submitSearch.nome;
  }

  try {
    const { data } = await api.get(`/projetos/favoritos-estado`, {
      params
    });
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoFavorito(secretariaId: number): Promise<TBodyProjeto[]> {
  try {
    const { data } = await api.get(`/projetos/favoritos?secretariaId=${secretariaId}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoEstrategicoFavoritos(): Promise<TBodyProjeto[]> {
  try {
    const { data } = await api.get(`/projetos/estrategicos-favoritos`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoNoPage(size: number): Promise<TProjeto> {
  try {
    const { data } = await api.get(`/projetos?tamanho=${size}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoStatus(): Promise<TNameId[]> {
  try {
    const { data } = await api.get('/projetos/status');
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoRegistros(): Promise<TNameId[]> {
  try {
    const { data } = await api.get('/projetos/registros');
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allProjetoOrgaos(): Promise<TNameId[]> {
  try {
    const { data } = await api.get('/projetos/orgaos');
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function createProjeto(body: TBodyProjeto): Promise<TProjeto> {
  try {
    const { data } = await api.post('/projetos', body);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function updateProjeto(id: number, body: TBodyProjeto): Promise<TProjeto> {
  try {
    const { data } = await api.put(`/projetos/${id}`, body);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export type TProjetoPainelKpi = {
  qtdProjetosEstrategicos: number;
  projetoExecucaoPainelKPI: {
    quantidade: number;
    valorTotal: number;  
  };
  valorTotalCaptacao: number;
}

export async function allProjetoPainelKpi(): Promise<TProjetoPainelKpi> {
  try {
    const { data } = await api.get('/projetos/painel/kpi');
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function findProjeto(id: number): Promise<TProjeto> {
  try {
    const { data } = await api.get(`/projetos/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function patchFavoritarEstado(id: number) {
  try {
    const { data } = await api.patch(`/projetos/${id}/favoritar-estado`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function patchFavoritar(id: number) {
  try {
    const { data } = await api.patch(`/projetos/${id}/favoritar`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function findProjetoSituacoes(id: number): Promise<{id: number, nome: string}[]> {
  try {
    const { data } = await api.get(`/projetos/${id}/situacoes`);
    return data.data;
  } catch(err) {
    throw err;
  }
}