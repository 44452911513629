import { Typography } from "@material-tailwind/react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { TImpacto, removeImpacto } from "../../providers/impacto";
import { useState } from "react";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { DialogFormImpacto } from "../DialogFormImpacto";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import TextTruncate from "react-text-truncate";

const TABLE_HEAD = ["Impacto", "Quantidade", "Unidade", "Especificação", "Status", "Ações"];

type TProps = {
  data: TImpacto[];
}

export function TableImpacto({ data }: TProps) {
  const [dialogRemove, setDialogRemove] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [dialogForm, setDialogForm] = useState(false);
  const [impacto, setImpacto] = useState<TImpacto>({} as TImpacto);
  const queryClient = useQueryClient();

  function handleDialogRemove() {
    setDialogRemove(prev => !prev)
  }

  function handleDialogForm() {
    setDialogForm(prev => !prev)
  }

  async function handleRemove() {
    try {

      setLoadingRemove(true)
      await removeImpacto(impacto.id);
      
      toast('Impacto excluído com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleDialogRemove()

      queryClient.resetQueries([impacto.situacaoId, 'find-impacto-by-situcao-id']);
      queryClient.invalidateQueries([impacto.situacaoId, 'find-impacto-by-situcao-id']);

    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir impacto', {type: 'error'});
    }
  }
  
  function handleEdit(imp: TImpacto) {
    setImpacto(imp);
    handleDialogForm();
  }

  return (
    <div className="border border-[#E0E2E7] rounded-[4px]">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr className="border-b border-b-[#F0F1F3] bg-[#F5F8FA]">
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="p-[12px] text-[#4A4C56] font-light text-sm"
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((i, index) => {
            const isLast = index === data.length - 1;
            const classes = isLast ? "p-[12px]" : "p-[12px] border-b border-blue-gray-50";
            return (
              <tr key={i.id}>
                <td className={`${classes} text-dark-base w-[400px]`}>
                  <TextTruncate
                    line={2}
                    element="p"
                    truncateText="…"
                    text={i.nome}
                    // textTruncateChild={<a href="#">Read on</a>}
                  />
                </td>
                <td className={`${classes} text-dark-base`}>
                  {i.quantidade}
                </td>
                <td className={`${classes} text-dark-base`}>
                  {i.unidade}
                </td>
                <td className={`${classes} text-dark-base w-[400px]`}>
                 <TextTruncate
                    line={2}
                    element="p"
                    truncateText="…"
                    text={i.especificacao}
                    // textTruncateChild={<a href="#">Read on</a>}
                  />
                  
                </td>
                <td className={`${classes} text-dark-base`}>
                  {i.status?.nome}
                </td>
                <td className={`${classes} text-dark-base`}>
                  <div className="flex flex-row items-center">
                    <button onClick={() => {
                      setImpacto(i);
                      handleDialogRemove();
                    }} >
                      <HiOutlineTrash color="#0062FF" size={24} />
                    </button>
                    <div className="w-[16px]"></div>
                    <button onClick={() => handleEdit(i)}>
                      <HiOutlinePencil color="#0062FF" size={24}/>
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <DialogRemoveItem handleConfirm={handleRemove} handleOpen={handleDialogRemove} open={dialogRemove} loadingConfirm={loadingRemove} />
      <DialogFormImpacto handleOpen={handleDialogForm} idSituacao={impacto.situacaoId} open={dialogForm} impacto={impacto} />
    </div>
  )
}