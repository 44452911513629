import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useImpactoStatus } from "../../hooks/query/useImpactoQuery";
import { TImpacto, createImpacto, updateImpacto } from "../../providers/impacto";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  impacto?: TImpacto;
  idSituacao: number;
}

type TFormData = {
  nome: string;
  unidade: string;
  especificacao: string;
  quantidade: number;
  statusId: string;
  situacaoId: number;
}

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  unidade: yup.string().required('Campo obrigatório'),
  quantidade: yup.number().required('Campo obrigatório'),
  statusId: yup.string().required('Campo obrigatório'),
})

export function DialogFormImpacto({handleOpen, open, idSituacao, impacto = {} as TImpacto}: IProps) {
  const [isSave, setIsSave] = useState(false);

  const impactoStatusQuery = useImpactoStatus();
  const impactoStatus = impactoStatusQuery.data || [];
  const queryClient = useQueryClient();

  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  function handleDismiss() {
    methods.reset();
    handleOpen();
  }

  useEffect(() => {
    if (!!impacto.nome) {
      methods.setValue('nome', impacto.nome)
    }

    if (!!impacto.unidade) {
      methods.setValue('unidade', impacto.unidade)
    }

    if (!!impacto.especificacao) {
      methods.setValue('especificacao', impacto.especificacao)
    }

    if (!!impacto.quantidade) {
      methods.setValue('quantidade', impacto.quantidade)
    }

    if (!!impacto.statusId) {
      methods.setValue('statusId', String(impacto.statusId));
    }

  }, [impacto])

  const onSubmit: SubmitHandler<TFormData> = async (params) => {
    try {
      setIsSave(true);

      if (!!impacto.id) {
        await updateImpacto(impacto.id, {
          ...params,
          situacaoId: idSituacao,
          statusId: Number(params.statusId)
        })
        toast('Impacto atualizado com sucesso', {type: 'success'});
      } else {
        await createImpacto({
          ...params,
          situacaoId: idSituacao,
          statusId: Number(params.statusId)
        });
        toast('Impacto criado com sucesso', {type: 'success'});
      }


      setIsSave(false)
      handleDismiss();
      
      
      queryClient.resetQueries([idSituacao, 'find-impacto-by-situcao-id']);
      queryClient.invalidateQueries([idSituacao, 'find-impacto-by-situcao-id']);

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar impacto', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {

  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleDismiss}
      dismiss={{
        outsidePress: (ev) => {
          methods.reset();
          return true;
        }
      }}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{!!impacto.id ? 'Editar' : 'Novo'} impacto</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="mt-[16px]">
              <Controller
                name="nome"
                render={({field, fieldState}) => (
                  <Input label="Impacto" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="quantidade"
                render={({field, fieldState}) => (
                  <Input label="Quantidade" type="number" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              <div className="w-[16px]"></div>
              <Controller
                name="unidade"
                render={({field, fieldState}) => (
                  <Input label="Unidade" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="especificacao"
                render={({field, fieldState}) => (
                  <Input label="Especificação" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              <div className="w-[16px]"></div>
              <Controller
                name="statusId"
                render={({field, fieldState}) => (
                  <Select label="Status" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    {!impactoStatusQuery.isLoading && impactoStatusQuery.isSuccess && impactoStatus.map(is => (
                      <Option value={String(is.id)} key={is.id}>{is.nome}</Option>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div className="mt-[24px] flex flex-row justify-end">
              <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
              <div className="w-[8px]"></div>
              <Button 
                className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                variant="outlined" 
                disabled={isSave}
                onClick={methods.handleSubmit(onSubmit, onError)}
              >
                {isSave ? <Spinner color="blue" /> : `${!!impacto.id ? 'Editar' : 'Adicionar'} impacto`}
              </Button>
            </div>
          </FormProvider>
        </div>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}