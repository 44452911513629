import { api } from "../services/api";

export type TEtapa = {
  id: number;
  nome: string;
}

export async function allEtapa(): Promise<TEtapa[]> {
  try {
    const { data } = await api.get(`/etapas`);
    return data.data;
  } catch (err) {
    throw err;
  }
}