import { Button, Spinner } from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
import { useAllUsers } from "../../hooks/query/useUsers";
import { BoxError } from "../../components/BoxError";
import { EmptyState } from "../../components/EmptyState";
import { HiDotsVertical, HiOutlinePencil } from "react-icons/hi";
import { DialogFormUser } from "../../components/DialogFormUser";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TUser } from "../../providers/users";



export function Usuarios() {
  const [userEdit, setUserEdit] = useState<TUser>({} as TUser);

  const usersQuery = useAllUsers();
  const users = usersQuery.data || [];

  const [dialogForm, setDialogForm] = useState(false);

  function handleDialogForm(user: any = {}) {
    if (!!user?.id) {
      setUserEdit(user)
    } else {
      setUserEdit({} as TUser)
    }
    setDialogForm(prev => !prev);
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-[24px]">
        <div>
          <h1 className="text-[42px] text-dark-base font-medium">Usuários</h1>
        </div>
        <div className="flex flex-row">
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button border-solid" variant="outlined" onClick={handleDialogForm}>Novo usuário</Button>
        </div>
      </div>
      <div className="mt-[24px]">
        <div className="flex flex-row">
          <div className="relative flex-1">
            <button className="absolute left-0 top-1/2 -translate-y-1/2 mx-[16px]">
              <FiSearch size={24} />
            </button>

            <input
              type="text"
              placeholder="Buscar..."
              // onChange={(e) => setTextSearch(e.target.value)}
              className="w-full bg-transparent pl-12 pr-4 focus:outline-none border py-[8px] rounded-[8px] border-[#E4E4E4] bg-white"
            />
          </div>
          {/* <div className="ml-[16px]">
            <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleSearch}>Pesquisar</Button>
          </div> */}
        </div>
      </div>
      {usersQuery.isLoading ? (
        <div className="flex items-center justify-center mt-[40px]">
          <Spinner />
        </div>
      ) : usersQuery.isSuccess ? (
          <>
            {users.length > 0 ? (
              <div className="mt-[40px]">
                <div className="flex flex-row justify-between mb-[20px]">
                  <div className="w-[40%]">
                    <p className="font-semibold text-[16px]">Nome</p>
                  </div>
                  {/* <div className="w-[30%]">
                    <p className="font-semibold text-[16px]">CPF</p>
                  </div> */}
                  <div className="w-[40%]">
                    <p className="font-semibold text-[16px]">E-mail</p>
                  </div>
                  <div className="w-[20%]">
                    <p className="font-semibold text-[16px]">Ações</p>
                  </div>
                </div>
                {users.map(i => (
                  <div className="flex flex-row justify-between py-[16px] border-b-[1px] border-b-[#E4E4E4] border-solid" key={i.id}>
                    <div className="w-[40%]">
                      <p className="font-normal text-[16px]">{i.nome} {i.sobrenome}</p>
                    </div>
                    {/* <div className="w-[30%]">
                      <p className="font-normal text-[16px]">CPF</p>
                    </div> */}
                    <div className="w-[40%]">
                      <p className="font-normal text-[16px]">{i.email}</p>
                    </div>
                    <div className="w-[20%]">
                      <button onClick={() => handleDialogForm(i)}>
                        <HiOutlinePencil size={24} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ): (
              <div className="mt-[40px]">
                <EmptyState text="Nenhum usuário cadastrado" />
              </div>
            )}
          </>

      ) : <>
        <div className="w-full mt-[40px]">
          <BoxError text="Erro ao carregar as informações" />
        </div>
      </> }
      
      <DialogFormUser handleOpen={handleDialogForm} open={dialogForm} usuario={userEdit} />

    </div>
  )
}