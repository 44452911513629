export function formatTipoCrise(tipo: string) {
  switch (tipo) {
    case 'SAUDE':
      return 'Saúde';
    case 'INFRAESTRUTURA':
      return 'Infraestrutura';
    case 'CIDADANIA':
      return 'Cidadania';
    case 'MEIO_AMBIENTE':
      return 'Meio ambiente';
    case 'SEGURANCA_PUBLICA':
      return 'Segurança pública';
    case 'EDUCACAO':
      return 'Educação';
    case 'POLITICA':
      return 'Política';
    case 'OUTRO':
      return 'Outro';
    default:
      return 'Outro';
  }
}