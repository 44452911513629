import { Button, Checkbox } from "@material-tailwind/react";
import { HiChevronDown, HiChevronUp, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { TPlanoAcao, removePlanoAcao, updatePlanoAcao } from "../../providers/plano-acao";
import { format, parseISO } from "date-fns";
import { DialogFormPlanoAcao } from "../DialogFormPlanoAcao";
import { useEffect, useState } from "react";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { setValueMasks } from "../../utils/number.utils";
import TextTruncate from 'react-text-truncate';
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

type Props = {
  data: TPlanoAcao[];
  isSecretaria?: boolean;
}

export function TablePlanoAcao({ data, isSecretaria = false }: Props) {
  const [dialogForm, setDialogForm] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [currentPlanoAcao, setCurrentPlanoAcao] = useState<TPlanoAcao>({} as TPlanoAcao);
  const [ixActive, setIxActive] = useState<number>(-1);

  const [planos, setPlanos] = useState<TPlanoAcao[]>([]);
  const [textSearch, setTextSearch] = useState('');

  useEffect(() => {
    if (!!textSearch.length) {
      const result = data.filter(d => d.nome.toLowerCase().includes(textSearch.toLowerCase()))
      setPlanos(result);
    } else {
      setPlanos(data);
    }
  }, [textSearch])


  const queryClient = useQueryClient()

  function handleDialogForm() {
    setDialogForm((prev) => !prev);
  }

  function handleDialogRemove() {
    setDialogRemove((prev) => !prev);
  }

  function handleDropdown(index: number) {
    setIxActive(prev => prev === index ? -1 : index)
  }

  const navigate = useNavigate();

  async function handleRemove() {
    try {

      setLoadingRemove(true)
      await removePlanoAcao(currentPlanoAcao.id);
      
      toast('Plano de ação excluído com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleDialogRemove()

      if (!!currentPlanoAcao.situacaoId) {
        queryClient.resetQueries([currentPlanoAcao.situacaoId, 'find-plano-acao-by-situcao-id']);
        queryClient.invalidateQueries([currentPlanoAcao.situacaoId, 'find-plano-acao-by-situcao-id']);
      }

      if (!!currentPlanoAcao.projetoId) {
        queryClient.resetQueries([currentPlanoAcao.projetoId, 'find-plano-acao-by-projeto-id']);
        queryClient.invalidateQueries([currentPlanoAcao.projetoId, 'find-plano-acao-by-projeto-id']);
      }
      
      queryClient.resetQueries(['plano-acao']);
      queryClient.invalidateQueries(['plano-acao']);

    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir plano de ação', {type: 'error'});
    }
  }

  function formatDate(date: string) {
    const dt = new Date(date);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

    try {
      return format(dtDateOnly, 'dd/MM/yyyy');
    } catch(err) {
      return ''
    }
  }

  function handleEdit(plano: TPlanoAcao) {
    setCurrentPlanoAcao(plano);
    handleDialogForm();
  }

  async function handleMonitorar(plano: TPlanoAcao) {
    try {
      await updatePlanoAcao(plano.id, {
        ...plano,
        monitorar: !plano.monitorar
      })
      toast('Plano de ação atualizado com sucesso', {type: 'success'});
      
      queryClient.resetQueries([plano.situacaoId, 'find-plano-acao-by-situcao-id']);
      queryClient.invalidateQueries([plano.situacaoId, 'find-plano-acao-by-situcao-id']);

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar plano de ação', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
    }
  }

  function handleNavigateOrigem(id?: number, isSituacao = false, isProjeto = false) {
    if (isSituacao && !!id) {
      navigate(`/internal/sala-situacao/${id}`)
      return;
    }
    
    if (isProjeto && !!id) {
      navigate(`/internal/projeto/${id}`)
      return;
    }

  }

  return (
    <div className="pt-[8px]">
      <h1 className="m-[16px]">Lista de ações</h1>
      <div className="flex flex-row mx-[16px]">
        <div className="relative flex-1">
          <button className="absolute left-0 top-1/2 -translate-y-1/2 mx-[16px]">
            <FiSearch size={24} />
          </button>

          <input
            type="text"
            placeholder="Buscar..."
            onChange={(e) => setTextSearch(e.target.value)}
            className="w-full bg-transparent pl-12 pr-4 focus:outline-none border py-[8px] rounded-[8px] border-[#E4E4E4] bg-white"
          />
        </div>
        <div className="ml-[16px]">
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={() => {}}>Pesquisar</Button>
        </div>
      </div>
      <table className="w-full min-w-max table-auto text-left">
        <tbody>
          {planos.map((i, index) => {
            const isLast = index === planos.length - 1;
            const classes = isLast ? "px-[16px] py-[24px] items-center justify-center" : "px-[16px] py-[24px] items-center justify-center";
            return (
              <>
                <tr key={i.id} className={`group cursor-pointer ${ixActive !== index && 'border-b border-blue-gray-50'} ${isSecretaria && !!i.situacaoId && 'border-l border-l-[5px] border-l-[#BC8802]'} ${ isSecretaria && !!i.projetoId && 'border-l border-l-[5px] border-l-[#6F5FB8]'}`}>
                  <td className={`${classes}`} onClick={() => handleDropdown(index)}>
                    {ixActive === index ? <HiChevronUp size={32} /> : <HiChevronDown size={32} />}
                    
                  </td>
                  <td className={`${classes} w-[250px]`} onClick={() => handleDropdown(index)}>
                    <div>
                      <label className="text-xs text-dark-tint">Ação</label>
                      <TextTruncate
                          line={2}
                          element="p"
                          truncateText="…"
                          text={i.nome}
                      />
                      {/* <p className="text-dark-tint">{i.nome}</p> */}
                    </div>
                  </td>
                  <td className={`${classes}`} onClick={() => handleDropdown(index)}>
                    <div>
                      <label className="text-xs text-dark-tint">Responsável</label>
                      <p className="text-dark-tint">{i.responsavel}</p>
                    </div>
                  </td>
                  <td className={`${classes}`} onClick={() => handleDropdown(index)}>
                    <div>
                      <label className="text-xs text-dark-tint">Prazo</label>
                      <p className="text-dark-tint">{formatDate(i.prazoAcao)}</p>
                    </div>
                  </td>
                  {isSecretaria && (
                    <td className={`${classes} w-[250px]`} onClick={() => handleDropdown(index)}>
                    <div>
                      <label className="text-xs text-dark-tint">Origem da demanda</label>
                      <p className={`text-[${i.projetoId ? '#6F5FB8' : '#BC8802'}] truncate w-[250px]`}>{i.origem}</p>
                    </div>
                  </td>
                  )}
                  <td className={`${classes} w-[150px]`} >
                    <div className="mt-[-24px]">
                      <label className="text-xs text-dark-tint">Status</label>
                      <span className="flex h-[24px] px-[16px] items-center justify-between border border-warning-card text-warning-card bg-warning-card-opacity rounded-full text-xs font-semibold absolute">
                        <h1>{i.status?.nome}</h1>
                      </span>
                    </div>
                  </td>
                  {isSecretaria ? (
                    <td className={`${classes}`}>
                      <div className="flex justify-end hidden group-hover:inline-block">
                        <button onClick={() => {
                          setCurrentPlanoAcao(i);
                          handleDialogRemove();
                        }} className="p-3">
                          <HiOutlineTrash color="#0062FF" size={24} />
                        </button>
                        <button onClick={() => {
                          setCurrentPlanoAcao(i);
                          handleDialogForm();
                        }} className="p-3">
                          <HiOutlinePencil color="#0062FF" size={24} />
                        </button>
                        <Button 
                          className="hidden group-hover:inline-block normal-case text-sm font-medium text-blue-button bg-white border-2 border-blue-button border-solid " 
                          variant="outlined" 
                          onClick={() => handleNavigateOrigem(i.projetoId || i.situacaoId, !!i.situacaoId, !!i.projetoId)}
                        >
                          Acessar origem
                        </Button>
                      </div>
                    </td>
                    
                  ): (
                    <>
                      <td className={`${classes}`} >
                        <div>
                          <label className="text-xs text-dark-tint">Secretaria</label>
                          <p className={`text-[${i.projetoId ? '#6F5FB8' : '#BC8802'}] truncate w-[250px]`}>{i.secretaria?.nome}</p>
                        </div>
                      </td>
                      <td className={`${classes}`}>
                        <div className="flex flex-row items-center">
                          <button onClick={() => {
                            setCurrentPlanoAcao(i);
                            handleDialogRemove();
                          }} >
                            <HiOutlineTrash color="#0062FF" size={24} />
                          </button>
                          <div className="w-[16px]"></div>
                          <button onClick={() => handleEdit(i)}>
                            <HiOutlinePencil color="#0062FF" size={24}/>
                          </button>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
                {ixActive === index && (
                  <tr key={i.id} className={`cursor-pointer ${ixActive === index && 'border-b border-blue-gray-50'}`} onClick={() => setIxActive(prev => prev === index ? -1 : index)}>
                    <td className={`${classes}`} onClick={() => handleDropdown(index)}>
                      
                    </td>
                    <td className={`${classes}`} onClick={() => handleDropdown(index)}>
                      <div>
                        <label className="text-xs text-dark-tint">Atuação</label>
                        <p className="text-dark-tint">{i.atuacao || '-'}</p>
                      </div>
                    </td>
                    <td className={`${classes}`} onClick={() => handleDropdown(index)}>
                      <div>
                        <label className="text-xs text-dark-tint">Custo previsto</label>
                        <p className="text-dark-tint">R$ {setValueMasks(i.custoPrevisto || 0)}</p>
                      </div>
                    </td>
                    <td className={`${classes}`} onClick={() => handleDropdown(index)}>
                      <div>
                        <label className="text-xs text-dark-tint">Recursos necessários</label>
                        <p className="text-dark-tint">{i.recursosNecessarios || '-'}</p>
                      </div>
                    </td>
                    {isSecretaria ? (
                      <td className={`${classes}`}>
                        
                      </td>
                    ) : (
                      <>
                        <td className={`${classes}`}>
                        
                        </td>
                        <td className={`${classes}`}>
                          
                        </td>
                        <td className={`${classes}`}>
                          
                        </td>
                      </>
                    )}
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </table>
      {isSecretaria && (
        <div className="flex flex-row items-center p-[16px]">
          <div className="flex flex-row items-center mr-[24px]">
            <div className="w-[16px] h-[16px] bg-[#6F5FB8] mr-[8px] rounded-full" />
            <p>Origem de Projeto</p>
          </div>
          <div className="flex flex-row items-center">
            <div className="w-[16px] h-[16px] bg-[#BC8802] rounded-full mr-[8px]" />
            <p>Origem da Situação</p>
          </div>
        </div>
      )}
      <DialogFormPlanoAcao handleOpen={handleDialogForm} open={dialogForm} idSituacao={currentPlanoAcao.situacaoId || 0} idProjeto={currentPlanoAcao.projetoId || 0} planoAcao={currentPlanoAcao} />
      <DialogRemoveItem handleConfirm={handleRemove} handleOpen={handleDialogRemove} open={dialogRemove} loadingConfirm={loadingRemove} />
    </div>
  )
}
