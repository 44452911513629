// import { api } from "@/services/api";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { api } from "../services/api";

interface SidebarProviderProps {
  children: ReactNode;
}

interface SidebarContextData {
  sidebarOpen: boolean;
  toggleSidebar: (open: boolean) => void;
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => void;
};

const SidebarContext = createContext({} as SidebarContextData);

export function SidebarProvider({children}: SidebarProviderProps) {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(false)

  function toggleSidebar(open: boolean) {
    setSidebarOpen(open)
  }

  function toggleDrawer(open: boolean) {
    setDrawerOpen(open)
  }

  return (
    <SidebarContext.Provider value={{sidebarOpen, toggleSidebar, drawerOpen, toggleDrawer}}>
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => useContext(SidebarContext);