import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { api } from "../../services/api";
import { SidebarProvider, useSidebar } from "../../hooks/SidebarContext";
import { useKeycloak } from "@react-keycloak/web";
import { useUser } from "../../hooks/UserContext";
import { MenuDrawer } from "../../components/MenuDrawer";
import { ToastContainer } from "react-toastify";

export function Internal() {
  const [finish, setFinish] = useState(false)
  const { createToken, setSecretarias } = useUser()

  const { keycloak } = useKeycloak();

  keycloak.onAuthSuccess = function() {
    createToken(keycloak.token, keycloak.refreshToken)
  }

  keycloak.onAuthRefreshSuccess = function() {
    createToken(keycloak.token, keycloak.refreshToken)
  }

  useEffect(() => {
    setFinish(false);
    const token = localStorage.getItem('ProcessoGov:token');
    if (!!token) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      setSecretarias(token)
      setFinish(true);
    }
  }, [])

  return (
    <SidebarProvider>
      <div className="flex h-screen overflow-hidden">
        <Sidebar />
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Header />
          <main className="bg-[#F8FAFC] p-[24px]">
            {finish && <Outlet />}
          </main>
        </div>
      </div>
      <MenuDrawer />
      <ToastContainer />
    </SidebarProvider>
  )
}