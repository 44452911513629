import { useFindImpactoBySituacaoId, useImpactoStatus } from "../../../hooks/query/useImpactoQuery";

type TProps = {
  situacaoId: number;
};

export function BoxImpacto({ situacaoId }: TProps) {

  const impactoQuery = useFindImpactoBySituacaoId(situacaoId);
  const impactos = impactoQuery.data || [];

  const statusQuery = useImpactoStatus;

  console.log("impactos", impactos);

  return (
    <>
      {!impactoQuery.isLoading && impactoQuery.isSuccess && (
        impactos.length > 0 ? (
          impactos.map((impacto, index) => (
            <div key={index} className="mt-[30px] bg-white rounded border border-[#F0F1F3] p-[16px]">
              <div className="mb-[16px]">
                <h1 className="text-dark-base text-3xl font-medium">{impacto.nome}</h1>
              </div>
              <div className="w-full h-[1px] bg-[#CBCBCB] mb-[16px]"></div>
              <div className="grid grid-cols-3">
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Quantidade</label>
                  <h1 className="text-dark-base text-2xl font-normal">{impacto.quantidade}</h1>
                </div>
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Unidade</label>
                  <h1 className="text-dark-base text-2xl font-normal">{impacto.unidade}</h1>
                </div>
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Status</label>
                  <h1 className="text-dark-base text-2xl font-normal">{impacto.status?.nome}</h1>
                </div>
              </div>
              <div className="grid grid-cols-3 mt-[16px]">
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Especificação</label>
                  <h1 className="text-dark-base text-2xl font-normal">{impacto.especificacao}</h1>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-[30px] bg-white rounded border border-[#F0F1F3] p-[16px]">
            <div className="mb-[16px]">
              <h1 className="text-dark-base text-3xl font-medium">Nenhum impacto encontrado</h1>
            </div>
          </div>
        )
      )}
    </>
  )
}