
import { FiSearch } from "react-icons/fi";
import { EmptyState } from "../../components/EmptyState";
import { Button, Spinner } from "@material-tailwind/react";
import { HiChevronDown } from "react-icons/hi";
import { useState } from "react";
import { DialogFormProjeto } from "../../components/DialogFormProjeto";
import { DialogFormDemanda } from "../../components/DialogFormDemanda";
import { DialogFormDemandaAtuacao } from "../../components/DialogFormDemandaAtuacao";
import { useAllDemanda, useAllDemandaAgendamento } from "../../hooks/query/useDemandaQuery";
import { BoxError } from "../../components/BoxError";
import { TableListaDemandas } from "../../components/TableListaDemandas";
import { CalendarApp } from "../../components/Calendar";
import { CalendarAgendamentos } from "../../components/CalendarDemanda";


export function DemandasJudiciais() {
  const [dialogForm, setDialogForm] = useState(false);

  const allDemandaQuery = useAllDemanda(11);
  const demandas = allDemandaQuery.data || [];

  const allDemandaAgendamentoQuery = useAllDemandaAgendamento(11);
  const agendamentos = allDemandaAgendamentoQuery.data || [];

  function handleDialogFormDemanda() {
    setDialogForm(prev => !prev);
  }

  return (
    <div className="min-h-[500px]">
      <div className="flex flex-row items-center justify-between mb-[24px]">
        <div>
          <h1 className="text-[42px] text-dark-base">Demandas judiciais</h1>
        </div>
        <div className="flex flex-row">
          <div className="w-[16px]" />
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleDialogFormDemanda}>Nova demanda</Button>
        </div>
      </div>

        {!allDemandaAgendamentoQuery.isLoading && allDemandaAgendamentoQuery.isSuccess && !allDemandaQuery.isLoading && allDemandaQuery.isSuccess && (
          <>
            <CalendarAgendamentos agendamentos={[...agendamentos, ...demandas]} />
          </>
        )}

      <div className="bg-white rounded-lg p-4 mt-[24px]">
        <h1 className="font-normal text-lg">Lista de demandas judiciais</h1>

        {allDemandaQuery.isLoading && (
          <div className="flex items-center justify-center mt-[24px]">
            <Spinner />
          </div>
        )}

        {!allDemandaQuery.isLoading && allDemandaQuery.isError && (
          <div className="w-full mt-[24px]">
            <BoxError text="Erro ao carregar as informações" />
          </div>
        )}

        {!allDemandaQuery.isLoading && allDemandaQuery.isSuccess && (
          <>
            {demandas.length > 0 ? 
              <TableListaDemandas demandas={demandas} />
            : (
            <div className="mt-[24px]">
              <EmptyState text="Nenhuma demanda encontrado" />
            </div>)}
          </>
        )}

       

        

      </div>

      {/* <EmptyState text="Em breve" /> */}

      <DialogFormDemanda handleOpen={handleDialogFormDemanda} open={dialogForm} />
      

    </div>
  )
}