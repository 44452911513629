import { api } from "../services/api";

export type TImpacto = {
  id: number;
  nome: string;
  unidade: string;
  especificacao: string;
  quantidade: number;
  statusId: number;
  situacaoId: number;
  status?: TImpactoStatus;
}

export type TImpactoStatus = {
  id: number;
  nome: string;
}

export async function findImpactoBySituacaoId(situacaoId: number): Promise<TImpacto[]> {
  try {
    const { data } = await api.get(`/impactos?situacaoId=${situacaoId}`);
    const impacto = data.data;

    
    if (impacto.length > 0) {
      const statusData = await allImpactoStatus();

      const result = impacto.map((i: TImpacto) => {
        return {
          ...i,
          status: statusData.find(sd => sd.id === i.statusId)
        }
      })

      console.log("findImpactoBySituacaoId", result)

      return result;

    }
    return [];
  } catch (err) {
    throw err;
  }
}

export async function findImpacto(id: number): Promise<TImpacto> {
  try {
    const { data } = await api.get(`/impactos/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function allImpactoStatus(): Promise<TImpactoStatus[]> {
  try {
    const { data } = await api.get(`/impactos/status`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function createImpacto(body: Omit<TImpacto, 'id'>): Promise<TImpacto> {
  try {
    const { data } = await api.post(`/impactos`, body);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateImpacto(id: number, body: Omit<TImpacto, 'id'>): Promise<TImpacto> {
  try {
    const { data } = await api.put(`/impactos/${id}`, body);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function removeImpacto(id: number): Promise<TImpacto> {
  try {
    const { data } = await api.delete(`/impactos/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

