import { api } from "../services/api";

export type TEstrategia = {
  id: number;
  nome: string;
  descricao: string;
  recursoEstimado: string;
  custoPrevisto: number;
  prazoEstimado: string;
  situacaoId: number;
}

export async function allEstrategia(): Promise<TEstrategia[]> {
  try {
    const { data } = await api.get(`/estrategias`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function findEstrategiaBySituacaoId(idSituacao: number): Promise<TEstrategia[]> {
  try {
    const { data } = await api.get(`/estrategias?situacaoId=${idSituacao}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function createEstrategia(params: Omit<TEstrategia, 'id'>) {
  try {
    const { data } = await api.post(`/estrategias`, params);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function findEstrategia(id: number): Promise<TEstrategia> {
  try {
    const { data } = await api.get(`/estrategias/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateEstrategia(id: number, params: Omit<TEstrategia, 'id'>) {
  try {
    const { data } = await api.put(`/estrategias/${id}`, params);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteEstrategia(id: number) {
  try {
    const { data } = await api.delete(`/estrategias/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}