import { Alert, Button, Spinner } from "@material-tailwind/react";
import { useState } from "react";
import { CardEstrategia } from "../CardEstrategia";
import { DialogFormEstrategia } from "../DialogFormEstrategia";
import { useFindEstrategiaBySituacaoId } from "../../hooks/query/useEstrategiaQuery";
import { EmptyState } from "../EmptyState";

type TProps = {
  idSituacao: number;
}

export function BoxEstrategia({ idSituacao }: TProps) {
  const [openForm, setOpenForm] = useState(false)

  const estrategiasQuery = useFindEstrategiaBySituacaoId(idSituacao);
  const estrategias = estrategiasQuery.data || [];

  function handleOpen() {
    setOpenForm((cur) => !cur)
  }
  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-[16px]">
        <h1 className="text-dark-base text-lg font-normal">Estratégias de combate e prevenção</h1>
        <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleOpen}>Adicionar</Button>
      </div>
      <div className="flex overflow-x-scroll pb-[16px]">
        {!estrategiasQuery.isLoading && estrategiasQuery.isSuccess && (
          <>
            {estrategias.length > 0 ? estrategias.map(c => (
              <CardEstrategia key={c.id} data={c} />
            )) : (
            <>
              <EmptyState text="Nenhuma estratégia cadastrada" />
            </>)}
          </>
        )}
      </div>
      {estrategiasQuery.isLoading && (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <DialogFormEstrategia open={openForm} handleOpen={handleOpen} idSituacao={idSituacao} />
    </div>
  )
}