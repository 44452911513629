import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import * as Pages from "../pages";

export function RoutesApp() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Pages.SignIn />} />
          <Route
            path="internal"
            element={
              <ProtectedRoute>
                <Pages.Internal />
              </ProtectedRoute>
            }
          >
            <Route path="sala-crise" element={<Pages.SalaCrise />} />
            <Route path="sala-situacao" element={<Pages.ListSituacao />} />
            <Route path="sala-situacao/:id" element={<Pages.SalaSituacao />} />
            <Route path="projeto/:id" element={<Pages.ProjetoDetail />} />
            <Route path="secretarias" element={<Pages.Secretarias />} />
            <Route path="projeto" element={<Pages.Projetos />} />
            <Route path="pessoas" element={<Pages.Pessoas />} />
            <Route path="demandas-judiciais" element={<Pages.DemandasJudiciais />} />
            <Route path="distribuicao-secretaria" element={<Pages.DistribuicaoSecretaria />} />
            <Route path="usuarios" element={<Pages.Usuarios />} />
          </Route>
        </Routes>
      </BrowserRouter>
    
  )
}