import { ReactComponent as ChartSvg } from "../../../assets/chart/chart.svg";

import { usePDF } from 'react-to-pdf';
import { Button, Typography } from "@material-tailwind/react";
import { useState } from "react";


const TABLE_HEAD = ["", "Eixo",  "", "Valor Global (R$)"];

const TABLE_HEAD2 = ["", "Objeto", "Valor Global (R$)", "Prioridade"];
 
const TABLE_ROWS = [
  {
    name: "1",
    job: "Manager",
    date: "23/04/18",
  },
  {
    name: "2",
    job: "Developer",
    date: "23/04/18",
  },
  {
    name: "3",
    job: "Executive",
    date: "19/09/17",
  },
];

type Props = {
  hiddenPdf: string;
  targetRef: any;
}


export function ReportPortfolio({targetRef,hiddenPdf}: Props) {
  return (
    <div>

      <div ref={targetRef}>
        <div className={`bg-[#F8FAFC] ${hiddenPdf}`}>
          <div className="bg-[#F8FAFC]">
            <div className='w-full h-[16px] bg-[#0062FF]'></div>
            <div className='p-[32px]'>
              <div className="mb-[60px]">
                <h1 className='text-6xl font-bold'>Governo do Amapá: Portfólio</h1>
              </div>
              <div className='grid grid-cols-2'>
                <div className='flex flex-row items-center'>
                  <ChartSvg width={300} height={300} />
                </div>
                <div>
                  <table className="w-full min-w-max table-auto text-left border border-blue-gray-200 rounded-full">
                    <thead className="">
                      <tr >
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              color="blue-gray"
                              className="font-normal leading-none opacity-70 text-xl"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {TABLE_ROWS.map(({ name, job, date }, index) => {
                        const isLast = index === TABLE_ROWS.length - 1;
                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
            
                        return (
                          <tr key={name}>
                            <td className={classes}>
                              <Typography
                                color="blue-gray"
                                className="font-normal text-xl"
                              >
                                1
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                color="blue-gray"
                                className="font-normal text-xl"
                              >
                                Saúde
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                color="blue-gray"
                                className="font-normal text-xl"
                              >
                                57%
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                color="blue-gray"
                                className="font-normal text-xl"
                              >
                                R$ 54.812,02
                              </Typography>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div className="mt-[40px] mb-[20px]">
                <div className="flex flex-row items-center justify-between">
                  <div>
                    <label className="text-[#4A4C56] text-xl font-light">Quantidade</label>
                    <h1 className="text-dark-base text-2xl font-normal">4</h1>
                  </div>
                  <div>
                    <label className="text-[#4A4C56] text-xl font-light">Total Geral</label>
                    <h1 className="text-dark-base text-2xl font-normal">R$ 108.514.436,06</h1>
                  </div>
                </div>
              </div>
              
              <table className="w-full min-w-max table-auto text-left border border-blue-gray-200 rounded-full">
                <thead className="">
                  <tr >
                    {TABLE_HEAD2.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          color="blue-gray"
                          className="font-normal leading-none opacity-70 text-xl"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {TABLE_ROWS.map(({ name, job, date }, index) => {
                    const isLast = index === TABLE_ROWS.length - 1;
                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
        
                    return (
                      <tr key={name}>
                        <td className={classes}>
                          <Typography
                            color="blue-gray"
                            className="font-normal text-xl"
                          >
                            1
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            color="blue-gray"
                            className="font-normal text-xl"
                          >
                            Construir o centro de Nefrologia de Laranjal do Jari (pgov)
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            color="blue-gray"
                            className="font-normal text-xl"
                          >
                            R$ 8.454.812,02
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            color="blue-gray"
                            className="font-normal text-xl"
                          >
                            Prioridade 1
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>  
            </div>

            
          </div>
        </div>
        
      </div>

    </div>
  )
}