import { useFindCenarioBySituacaoId } from "../../../hooks/query/useCenarioQuery";

type TProps = {
  id: number;
}

export function BoxCenario({ id }: TProps) {

  const cenarioQuery = useFindCenarioBySituacaoId(id);
  const cenarios = cenarioQuery.data || [];

  return (
    <>
      {!cenarioQuery.isLoading && cenarioQuery.isSuccess && (
        cenarios.length > 0 ? (
          cenarios.map((cenario, index) => (
            <div
              key={index}
              className={`
                mt-[30px]
                bg-white
                rounded
                border
                ${cenario.expectativaEnum === 'OTIMISTA' ? 'border-[#39B100]' : cenario.expectativaEnum === 'PESSIMISTA' ? 'border-[#F66]' : 'border-[#404040]'}
              `}>
              <div
                className={`
                  p-[16px]
                  ${cenario.expectativaEnum === 'OTIMISTA' ? 'bg-[#39B100]' : cenario.expectativaEnum === 'PESSIMISTA' ? 'bg-[#F66]' : 'bg-[#404040]'}
                `}>
                <h1 className="text-white text-3xl font-medium">
                  {cenario.expectativaEnum === 'OTIMISTA' ? 'Positivo' : cenario.expectativaEnum === 'PESSIMISTA' ? 'Negativo' : 'Neutro'}
                </h1>
              </div>
              <div className="p-[16px]">
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Evento/condição</label>
                  <h1 className="text-dark-base text-2xl font-normal">{cenario.evento}</h1>
                </div>
                <div className="w-full h-[1px] bg-[#CBCBCB] my-[16px]"></div>
                <div className="mb-[16px]">
                  <label className="text-[#4A4C56] text-xl font-light">Consequência</label>
                  <h1 className="text-dark-base text-2xl font-normal">{cenario.consequencia}</h1>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-[30px] bg-white rounded border border-[#F0F1F3] p-[16px]">
            <div className="mb-[16px]">
              <h1 className="text-dark-base text-3xl font-medium">Nenhum cenário encontrado</h1>
            </div>
          </div>
        )
      )}
    </>
  )
}