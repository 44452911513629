import { Button, Card, Dialog, Input, Option, Select, Spinner, Typography } from "@material-tailwind/react";
import { HiOutlinePencil, HiOutlineTrash, HiX } from "react-icons/hi";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useImpactoStatus } from "../../hooks/query/useImpactoQuery";
import { TImpacto, createImpacto, updateImpacto } from "../../providers/impacto";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import InputMask from 'react-input-mask';
import { NumericFormat } from "react-number-format";
import { TAgendamento, TAtuacao, TResponseAllDemanda, deleteDemandaAgendamento, deleteDemandasAtuacao, demandaById, demandasAgendamentoByIdDemanda, demandasAtuacaoByIdDemanda } from "../../providers/demandas";
import { format, parseISO } from "date-fns";
import { setValueMasks } from "../../utils/number.utils";
import { EmptyState } from "../EmptyState";
import { DialogFormDemandaAgendamento } from "../DialogFormDemandaAgendamento";
import { DialogFormDemandaAtuacao } from "../DialogFormDemandaAtuacao";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { formatDateString } from "../../utils/date.utils";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  id?: number;
}

export function DialogViewDemanda({handleOpen, open, id = 0}: IProps) {
  const [loadingInitial, setLoadingInitial] = useState(true);  
  const [demanda, setDemanda] = useState<TResponseAllDemanda>({} as TResponseAllDemanda)
  const [activeTab, setActiveTab] = useState("atuacao");
  const [atuacoesDemanda, setAtuacoesDemanda] = useState<TAtuacao[]>([])
  const [agendamentosDemanda, setAgendamentosDemanda] = useState<TAgendamento[]>([])
  const [dialogFormAgendamento, setDialogFormAgendamento] = useState(false);
  const [agendamentoId, setAgendamentoId] = useState<number>()
  const [atuacaoId, setAtuacaoId] = useState<number>()
  const [dialogFormAtuacao, setDialogFormAtuacao] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [dialogRemoveAtuacao, setDialogRemoveAtuacao] = useState(false);
  const [dialogRemoveAgendamento, setDialogRemoveAgendamento] = useState(false);

  const queryClient = useQueryClient();

  function handleDismiss() {
    handleOpen();
  }

  function handleDialogFormAgendamento() {
    setDialogFormAgendamento(prev => !prev)
  }
  

  useEffect(() => {
    async function init() {
      setLoadingInitial(true);
      const response = await demandaById(id);
      setDemanda(response);
      
      const responseAt = await demandasAtuacaoByIdDemanda(id);
      if (responseAt.length > 0) {
        setAtuacoesDemanda(responseAt)
      } else {
        setAtuacoesDemanda([])
      }

      const responseAg = await demandasAgendamentoByIdDemanda(id);


      if (responseAg.length > 0) {
        setAgendamentosDemanda(responseAg)
      } else {
        setAgendamentosDemanda([])
      }
      
      setLoadingInitial(false)
    }
 
    if (open && id > 0) {
      init()
    }
  }, [id, open])

  function formatStatusEnum(status = '') {
    if (status === 'A_INICIAR') {
      return 'A iniciar';
    }
    if (status === 'EM_EXECUCAO') {
      return 'Em execução';
    }
    if (status === 'CONCLUIDO') {
      return 'Concluído';
    }
    if (status === 'IMPEDIDO') {
      return 'Impedido';
    }
    if (status === 'PAUSADO') {
      return 'Pausado';
    }

    return '';
  }

  function formatRiscoEnum(status = '') {
    if (status === 'BAIXO') {
      return 'Baixo';
    }
    if (status === 'MEDIO') {
      return 'Médio';
    }
    if (status === 'ALTO') {
      return 'Alto';
    }

    return '';
  }

  function handleEditAtuacao(id: number) {
    setAtuacaoId(id)
    handleDialogFormDemandaAtuacao()
  }

  async function handleDialogFormDemandaAtuacao() { 
    setDialogFormAtuacao(prev => !prev);
    if (dialogFormAtuacao) {
      const responseAt = await demandasAtuacaoByIdDemanda(id);
      if (responseAt.length > 0) {
        setAtuacoesDemanda(responseAt)
      } else {
        setAtuacoesDemanda([])
      }
    }
  }

  function handleEditAgendamento(id: number) {
    setAgendamentoId(id)
    handleDialogFormAgendamento()
  }

  async function handleRemoveAtuacao() {
    try { 
      setLoadingRemove(true);
      await deleteDemandasAtuacao(atuacaoId!)
      const response = await demandasAtuacaoByIdDemanda(id);

      if (response.length > 0) {
        setAtuacoesDemanda(response)
      } else {
        setAtuacoesDemanda([])
      }
      toast('Atuação excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleRemoveDialogAtuacao()
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', {type: 'error'});
    }
  }

  async function handleRemoveDialogAtuacao() {
    setDialogRemoveAtuacao(prev => !prev);
  }

  async function handleRemoveDialogAgendamento() {
    setDialogRemoveAgendamento(prev => !prev);
  }

  async function handleRemoveAgendamento() {
    try { 
      setLoadingRemove(true);
      await deleteDemandaAgendamento(agendamentoId!)
      const response = await demandasAgendamentoByIdDemanda(id);

      if (response.length > 0) {
        setAgendamentosDemanda(response)
      } else {
        setAgendamentosDemanda([])
      }
      toast('Agendamento excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleRemoveDialogAgendamento()

      queryClient.resetQueries(['all-demanda-agendamento']);
      queryClient.invalidateQueries(['all-demanda-agendamento']);

    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', {type: 'error'});
    }
  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          {loadingInitial ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex flex-row items-center justify-between">
                <div>
                  <h1 className="text-dark-base text-3xl font-semibold">{demanda.nome}</h1>
                </div>
                <div>
                  <button onClick={handleOpen}>
                    <HiX size={24} color="#425466" />
                  </button>
                </div>
              </div>
              <div className="mt-[16px]">
                <div className="mt-[8px] flex flex-row">
                  <div className="w-[20%]">
                    <label className="font-light text-xs text-dark-tint">Risco</label>
                    <p className="font-normal text-sm text-dark-base">{formatRiscoEnum(demanda.riscoEnum)}</p>
                  </div>
                  <div className="w-[30%]">
                    <label className="font-light text-xs text-dark-tint">Comarca</label>
                    <p className="font-normal text-sm text-dark-base">{demanda.comarca}</p>
                  </div>
                  <div className="w-[40%]">
                    <label className="font-light text-xs text-dark-tint">Estimativa de julgamento e execução</label>
                    <p className="font-normal text-sm text-dark-base">{!!demanda.estimativa ? formatDateString(demanda.estimativa) : '-'}</p>
                  </div>
                </div>
                <div className="mt-[8px] flex flex-row">
                  <div className="w-[20%]">
                    <label className="font-light text-xs text-dark-tint">Valor</label>
                    <p className="font-normal text-sm text-dark-base">R$ {setValueMasks(demanda.valor || 0)}</p>
                  </div>
                  <div className="w-[30%]">
                    <label className="font-light text-xs text-dark-tint">Número/registro do processo</label>
                    <p className="font-normal text-sm text-dark-base">{demanda.registro}</p>
                  </div>
                  <div className="w-[20%]">
                    <label className="font-light text-xs text-dark-tint">Parte adversa</label>
                    <p className="font-normal text-sm text-dark-base">{demanda.parteAdversa}</p>
                  </div>
                  <div className="w-[20%]">
                    <label className="font-light text-xs text-dark-tint">Status</label>
                    <p className="font-normal text-sm text-dark-base">{formatStatusEnum(demanda.statusEnum)}</p>
                  </div>
                </div>
              </div>
              <div className="mt-[24px] w-full border-b border-b-[#E0E2E7] flex flex-row items-center">
                <div 
                  onClick={() => setActiveTab('atuacao')}
                  className={`pb-[10px] w-fit	cursor-pointer ${activeTab === 'atuacao' && `border-b-[2px] border-b-[#0062FF]`}`}
                >
                  <Typography className={`${activeTab === 'atuacao' ? `text-[#0062FF] font-semibold` : 'text-[#667085] font-normal'}`}>Atuação</Typography>
                </div>
                <div className="w-[16px]" />
                <div 
                  onClick={() => setActiveTab('agendamento')}
                  className={`pb-[10px] w-fit	cursor-pointer ${activeTab === 'agendamento' && `border-b-[2px] border-b-[#0062FF]`}`}
                >
                  <Typography className={`${activeTab === 'agendamento' ? `text-[#0062FF] font-semibold` : 'text-[#667085] font-normal'}`}>Agendamento</Typography>
                </div>
                <div className="w-[16px]" />
                {/* <div 
                  onClick={() => setActiveTab('arquivos')}
                  className={`pb-[10px] w-fit	cursor-pointer ${activeTab === 'arquivos' && `border-b-[2px] border-b-[#0062FF]`}`}
                >
                  <Typography className={`${activeTab === 'arquivos' ? `text-[#0062FF] font-semibold` : 'text-[#667085] font-normal'}`}>Arquivos</Typography>
                </div> */}
              </div>
              <div className="mt-[8px]">
                {activeTab === 'atuacao' && (
                  <div>
                    {atuacoesDemanda.length > 0 && atuacoesDemanda.map(ad => (
                      <div key={ad.id} className="w-full flex flex-row mb-[8px] group">
                        <div className="w-[50%]">
                          <label className="font-light text-xs text-dark-tint">Atuação</label>
                          <p className="font-normal text-sm text-dark-base">{ad.descricao}</p>
                        </div>
                        <div className="w-[20%]">
                          <label className="font-light text-xs text-dark-tint">Data</label>
                          <p className="font-normal text-sm text-dark-base">{formatDateString(ad.dataCriacao)}</p>
                        </div>
                        <div className="w-[20%] flex flex-row">
                          <div>
                            <label className="font-light text-xs text-dark-tint">Usuário</label>
                            <p className="font-normal text-sm text-dark-base">{ad.autor}</p>
                          </div>
                        </div>
                        <div className="w-[10%] flex flex-row items-center justify-center">
                          <div className="hidden group-hover:inline-block">
                            <button className="mr-[16px]" onClick={() => {
                            setAtuacaoId(ad.id);
                            handleRemoveDialogAtuacao()
                            }}>
                              <HiOutlineTrash color="#0062FF" size={24} />
                            </button>
                            <button>
                              <HiOutlinePencil color="#0062FF" size={24} onClick={() => handleEditAtuacao(ad.id)}/>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    {atuacoesDemanda.length === 0 && (
                      <EmptyState text="Nenhuma atuação cadastrada" />  
                    )}
                  </div>
                )}
                {activeTab === 'agendamento' && (
                  <div>
                    {agendamentosDemanda.length > 0 && agendamentosDemanda.map(ad => (
                      <div key={ad.id} className="w-full flex flex-row mb-[8px] group">
                        <div className="w-[30%]">
                          <label className="font-light text-xs text-dark-tint">Nome</label>
                          <p className="font-normal text-sm text-dark-base">{ad.nome}</p>
                        </div>
                        <div className="w-[30%]">
                          <label className="font-light text-xs text-dark-tint">Descrição</label>
                          <p className="font-normal text-sm text-dark-base">{ad.descricao}</p>
                        </div>
                        <div className="w-[20%] flex flex-row">
                          <div>
                            <label className="font-light text-xs text-dark-tint">Prazo</label>
                            <p className="font-normal text-sm text-dark-base">{!!ad.prazo ?formatDateString(ad.prazo) : '-'}</p>
                          </div>
                        </div>
                        <div className="w-[10%] flex flex-row items-center justify-center">
                          <div className="hidden group-hover:inline-block">
                            <button className="mr-[16px]" onClick={() => {
                            setAgendamentoId(ad.id);
                            handleRemoveDialogAgendamento()
                            }}>
                              <HiOutlineTrash color="#0062FF" size={24} />
                            </button>
                            <button>
                              <HiOutlinePencil color="#0062FF" size={24} onClick={() => handleEditAgendamento(ad.id)} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    {agendamentosDemanda.length === 0 && (
                      <EmptyState text="Nenhum agendamento cadastrada" />  
                    )}
                    <div className="mt-[16px]">
                      <Button className="w-full normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleDialogFormAgendamento}>Adicionar agendamento</Button>
                    </div>
                  </div>
                  )}
              </div>
            </>
          )}
          
        </div>
      </Card>
      <ToastContainer />

      <DialogFormDemandaAgendamento handleOpen={handleDialogFormAgendamento} open={dialogFormAgendamento} demandaId={id} agendamentoId={agendamentoId} />
      <DialogFormDemandaAtuacao handleOpen={handleDialogFormDemandaAtuacao} open={dialogFormAtuacao} demandaId={id} atuacaoId={atuacaoId} />
      <DialogRemoveItem handleOpen={handleRemoveDialogAtuacao} open={dialogRemoveAtuacao} handleConfirm={handleRemoveAtuacao} loadingConfirm={loadingRemove} />
      <DialogRemoveItem handleOpen={handleRemoveDialogAgendamento} open={dialogRemoveAgendamento} handleConfirm={handleRemoveAgendamento} loadingConfirm={loadingRemove} />

    </Dialog>
  )
}