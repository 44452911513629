import { useNavigate } from "react-router-dom";

type Props = {
  data: {
    id: number;
    nome: string;
  }
}

export function BoxProjetoSituacao({data}: Props) {
  const navigate = useNavigate();

  function handleSituacao() {
    navigate(`/internal/sala-situacao/${data.id}`)
  }

  return (
    <div className="flex flex-row items-center justify-between p-4 border-solid border-[1px] border-[#E4E4E4] rounded-lg">
      <div className="flex-[0.7]">
        <h1 className="font-normal text-sm">{data.nome}</h1>
      </div>
      <div className="flex-[0.4] text-end">
        <button className="font-normal text-xs text-[#3A57F2] " onClick={handleSituacao}>Ver situação {'  >'}</button>
      </div>
    </div>  
  )
}