import { useQuery } from "react-query"
import { allEtapa } from "../../providers/etapa";
import { allProjeto, allProjetoCaptacaoRecurso, allProjetoEstrategico, allProjetoEstrategicoFavoritos, allProjetoExecucao, allProjetoFavorito, allProjetoFavoritoEstadoPage, allProjetoFavoritoPage, allProjetoNoPage, allProjetoOrgaos, allProjetoPainelKpi, allProjetoRegistros, allProjetoSecretaria, allProjetoStatus, findProjeto, findProjetoSituacoes } from "../../providers/projeto";

export const useAllProjeto = (page = 1, size = 6, submitSearch = {}, type = "todos", secretariaSelected = 0) => {
  return useQuery(['all-projeto', page, size, JSON.stringify(submitSearch), type], async () => {

    console.log('typereeeee', type)

    if (type === 'todos') {
      const response = await allProjeto(page, submitSearch)
      return response;
    }

    if (type === 'execucao') {
      const response = await allProjetoExecucao(page, submitSearch)
      return response;
    }

    if (type === 'valor_execucao') {
      const response = await allProjetoExecucao(page, submitSearch)
      return response;
    }

    if (type === 'estratégico') {
      const response = await allProjetoEstrategico(page, submitSearch)
      return response;
    }

    if (type === 'captacao') {
      const response = await allProjetoCaptacaoRecurso(page, submitSearch)
      return response;
    }

    if (type === 'estrategico_button') {
      const response = await allProjetoEstrategico(page, submitSearch)
      return response;
    }
    
    if (type === 'favorito_secretaria') {
      const response = await allProjetoFavoritoPage(page, submitSearch, secretariaSelected)
      return response;
      
    }
    
    if (type === 'favorito_estado') {
      const response = await allProjetoFavoritoEstadoPage(page, submitSearch)
      return response;
    }

    const response = await allProjeto(page, submitSearch)
    return response;
  });
}

export const useAllProjetoNoPage = (size: number) => {
  return useQuery(['all-projeto-no-page'], async () => await allProjetoNoPage(size || 0));
}

export const useAllProjetoEstrategicoFavoritos = () => {
  return useQuery(['all-projeto-estrategico-favoritos'], async () => await allProjetoEstrategicoFavoritos());
}

export const useAllProjetoFavorito = (secretariaId: number) => {
  return useQuery(['all-projeto-favorito'], async () => await allProjetoFavorito(secretariaId), {
    enabled: !!secretariaId
  });
}

export const useFindProjeto = (id: number) => {
  return useQuery([id, 'find-projeto'], async () => await findProjeto(id));
}

export const useAllProjetoStatus = () => {
  return useQuery(['all-projeto-status'], async () => await allProjetoStatus());
}

export const useAllProjetoRegistros = () => {
  return useQuery(['all-projeto-registros'], async () => await allProjetoRegistros());
}

export const useAllProjetoOrgaos = () => {
  return useQuery(['all-projeto-orgaos'], async () => await allProjetoOrgaos());
}

export const useAllProjetoPainelKpi = () => {
  return useQuery(['all-projeto-painel-kpi'], async () => await allProjetoPainelKpi());
}

export const useAllProjetoSecretaria = (secretariaId: number) => {
  return useQuery([secretariaId, 'all-projeto-secretaria'], async () => await allProjetoSecretaria(secretariaId) , {
    enabled: !!secretariaId
  });
}

export const useProjetoSituacao = (id: number) => {
  return useQuery([id, 'projeto-situacoes'], async () => await findProjetoSituacoes(id), {
    refetchOnMount: false
  });
}
