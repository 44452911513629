import { Link } from "react-router-dom";

type Props = {
  isActive: boolean;
  route: string;
  text: string;
  icone: any;
};

export function CardMenu({ isActive, route, text, icone }: Props) {
  return (
    <Link
      to={route}
      className={`group relative flex items-center gap-2.5 rounded-[4px] py-2 px-4 font-medium duration-300 ease-in-out hover:bg-[#E0EEFC] dark:hover:bg-meta-4 ${
        isActive
          ? "bg-[#E0EEFC] dark:bg-meta-4 text-[#3A57F2]"
          : "text-[#425466]"
      }`}
    >
      {icone}
      {text}
    </Link>
  );
}
