import { Spinner, ThemeProvider } from "@material-tailwind/react";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";

import Keycloak from "keycloak-js";
import { RoutesApp } from "./routes";
import { UserProvider } from "./hooks/UserContext";
import { QueryClient, QueryClientProvider } from "react-query";

import { useCallback, useEffect, useState } from "react";
import { api } from "./services/api";
import axios from "axios";


const queryClient = new QueryClient();

function App() {
  const [loading, setLoading] = useState(true);

  const onKeycloakTokens = useCallback((tokens: any) => {
    if (!tokens.token) {
      // Remove entry from Storage
    } else {
      localStorage.setItem('ProcessoGov:token', tokens.token);
      api.defaults.headers.authorization = `Bearer ${tokens.token}`;
    }
  }, []);


  useEffect(() => {
    loadEnv();
  }, [])

  async function loadEnv() {
    setLoading(true);

    if (!localStorage.getItem('ProcessoGov:backendUrl') || 
      !localStorage.getItem('ProcessoGov:keycloak:url') || 
      !localStorage.getItem('ProcessoGov:keycloak:realm') || 
      localStorage.getItem('ProcessoGov:keycloak:clientId')
      ) {
        const { data } = await axios.get('/env.json');
    
        localStorage.setItem('ProcessoGov:backendUrl', data.backendUrl);
        localStorage.setItem('ProcessoGov:keycloak:url', data.keycloak.url);
        localStorage.setItem('ProcessoGov:keycloak:realm', data.keycloak.realm);
        localStorage.setItem('ProcessoGov:keycloak:clientId', data.keycloak.clientId);
    }


    api.defaults.baseURL = localStorage.getItem('ProcessoGov:backendUrl') || '';

    setLoading(false);


  }


  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        {!loading ? (
          <ReactKeycloakProvider 
              authClient={new Keycloak({
                url: localStorage.getItem('ProcessoGov:keycloak:url') || '',
                realm: localStorage.getItem('ProcessoGov:keycloak:realm') || '',
                clientId: localStorage.getItem('ProcessoGov:keycloak:clientId') || '',
              })} 
              onTokens={onKeycloakTokens} autoRefreshToken={true}>
            <UserProvider>
              <RoutesApp />
            </UserProvider>
          </ReactKeycloakProvider>
        ) : (
          <div className="flex w-full h-screen items-center justify-center">
            <Spinner />
          </div>
        )}
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
