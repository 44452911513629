import { Navigate } from "react-router-dom";
import { useUser } from "../../hooks/UserContext";

export const ProtectedRoute = ({ children }: any) => {
  const token = localStorage.getItem('ProcessoGov:token');
  if (!!token) {
    return children;
  }
  return <Navigate to="/" replace />;

};