import { useFindSolucaBySituacaoId } from "../../../hooks/query/useSolucaoQuery";

type TProps = {
  situacaoId: number;
}

export function BoxSolucao({ situacaoId }: TProps) {

  const solucaoQuery = useFindSolucaBySituacaoId(situacaoId);
  const solucoes = solucaoQuery.data || [];

  return (
    <>
      {!solucaoQuery.isLoading && solucaoQuery.isSuccess && (
        solucoes.length > 0 ? (
          solucoes.map((solucao, index) => (
            <div key={index} className="mt-[30px] bg-white rounded border border-[#F0F1F3] p-[16px]">
              <div className="mb-[16px]">
                <h1 className="text-dark-base text-3xl font-medium">{solucao.nome}</h1>
              </div>
              <div className="w-full h-[1px] bg-[#CBCBCB] mb-[16px]"></div>
              <div>
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Escopo</label>
                  <h1 className="text-dark-base text-2xl font-normal">{solucao.descricao  }</h1>
                </div>
              </div>
              <div className="grid grid-cols-2 mt-[16px]">
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Eixo</label>
                  <h1 className="text-dark-base text-2xl font-normal">{solucao.eixoId}</h1>
                </div>
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Responsável</label>
                  <h1 className="text-dark-base text-2xl font-normal">{solucao.responsavelId}</h1>
                </div>
              </div>
              <div className="grid grid-cols-2 mt-[16px]">
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Custo previsto</label>
                  <h1 className="text-dark-base text-2xl font-normal">{solucao.custoPrevisto}</h1>
                </div>
                <div>
                  <label className="text-[#4A4C56] text-xl font-light">Unidade</label>
                  <h1 className="text-dark-base text-2xl font-normal">{solucao.status?.nome}</h1>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-[30px] bg-white rounded border border-[#F0F1F3] p-[16px]">
            <div className="mb-[16px]">
              <h1 className="text-dark-base text-3xl font-medium">Nenhuma solução encontrada</h1>
            </div>
          </div>
        )
      )}
    </>
  )
}