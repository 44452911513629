import { api } from "../services/api";

export type TLocalizacao = {
  id?: number;
  latitude: number;
  longitude: number;
  endereco: string,
  cep: string,
  municipio: string,
  situacaoId?: number;
  projetoId?: number;
}

export async function findLocalizacaoBySituacaoId(situacaoId: number): Promise<TLocalizacao[]> {
  try {
    const { data } = await api.get(`/localizacoes?situacaoId=${situacaoId}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function findLocalizacaoByProjetoId(projetoId: number): Promise<TLocalizacao[]> {
  try {
    const { data } = await api.get(`/localizacoes?projetoId=${projetoId}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}


export async function createLocalizacao(body: Omit<TLocalizacao, 'id'>): Promise<TLocalizacao> {
  try {
    const { data } = await api.post(`/localizacoes`, body);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateLocalizacao(id: number, body: Omit<TLocalizacao, 'id'>): Promise<TLocalizacao> {
  try {
    const { data } = await api.put(`/localizacoes/${id}`, body);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteLocalizacao(id: number): Promise<TLocalizacao> {
  try {
    const { data } = await api.delete(`/localizacoes/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function findLocalizacaoProjetos(): Promise<TLocalizacao[]> {
  try {
    const { data } = await api.get(`/localizacoes/projetos`);
    return data.data;
  } catch (err) {
    throw err;
  }
}
