import { Button, Card, Dialog, Spinner, Typography } from "@material-tailwind/react";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  handleConfirm: () => Promise<void>;
  loadingConfirm?: boolean;
}

export function DialogRemoveItem({handleOpen, open, handleConfirm, loadingConfirm = false}: IProps) {

  async function handleRemove() {
    await handleConfirm();
  }

  return (
    <Dialog
      size="sm"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="flex p-[59px] items-center justify-center">
        <Typography className="text-[26px] text-center text-dark-base font-medium">
          Você tem certeza que deseja excluir este item?
        </Typography>
        <div className="mt-[32px] flex flex-row">
          <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleOpen}>Voltar</Button>
          <div className="w-[16px]"></div>
          <Button className="normal-case text-sm font-medium bg-[#EB5757] text-white border-[#EB5757]" variant="outlined" onClick={handleRemove}>
            {loadingConfirm ? <Spinner color="red" /> : 'Excluir'}
          </Button>
        </div>
      </Card>
    </Dialog>
  )
}