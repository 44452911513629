import { Button, Card, Dialog } from "@material-tailwind/react";
import { ToastContainer } from "react-toastify";
import './leaflet.css';
import { useNavigate } from "react-router-dom";


type IProps = {
  open: boolean;
  handleOpen: () => void;
  detail: any;
  // situacao?: TSituacao;
}

export function ModalEventDetail({handleOpen, open, detail}: IProps) {

  const navigate = useNavigate();

  function handleNavigateOrigem(id?: number, isSituacao = false, isProjeto = false) {
    handleOpen();
    if (isSituacao && !!id) {
      navigate(`/internal/sala-situacao/${id}`)
      return;
    }
    
    if (isProjeto && !!id) {
      navigate(`/internal/projeto/${id}`)
      return;
    }

  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <h1 className="text-3xl text-dark-base font-bold">{detail.title}</h1>
          <div className="flex flex-row justify-between items-center mt-[24px]">
            <div>
              <label className="text-xs text-dark-tint">Responsável</label>
              <p>{detail.responsavel}</p>
            </div>
            <div>
              <label className="text-xs text-dark-tint">Prazo</label>
              <p>{detail.responsavel}</p>
            </div>
            <div>
              <label className="text-xs text-dark-tint">Origem da demanda</label>
              <p className={`text-[${detail.projetoId ? '#6F5FB8' : '#BC8802'}] truncate w-[250px]`}>{detail.origem}</p>
            </div>
            <div>
              <label className="text-xs text-dark-tint">Status</label>
              <span className="flex min-h-[24px] px-[16px] items-center justify-between border border-warning-card text-warning-card bg-warning-card-opacity rounded-full text-xs font-semibold">
                <h1>{detail?.status?.nome}</h1>
              </span>
            </div>
            
          </div>
          <div className="flex flex-row justify-between items-center mt-[40px]">
            <Button 
              className="normal-case text-sm font-medium text-blue-button bg-white border-2 border-blue-button border-solid " 
              variant="outlined" 
                onClick={() => handleNavigateOrigem(detail.projetoId || detail.situacaoId, !!detail.situacaoId, !!detail.projetoId)}
            >
                Ir para o {detail.projetoId ? 'projeto' : 'situação'}
            </Button>
            <Button 
              className="normal-case text-sm font-medium text-blue-button bg-white border-2 border-blue-button border-solid " 
              variant="outlined" 
                onClick={handleOpen}
            >
                Fechar
            </Button>
          </div>
        </div>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}