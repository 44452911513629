import { Alert, Button, Spinner } from "@material-tailwind/react";
import { CardCenario } from "../CardCenario";
import { useState } from "react";
import { DialogFormCenario } from "../DialogFormCenario";
import { useAllCenario, useFindCenarioBySituacaoId } from "../../hooks/query/useCenarioQuery";
import { EmptyState } from "../EmptyState";
import { BoxError } from "../BoxError";

type TProps = {
  id: number;
}

export function BoxCenario({ id }: TProps) {
  const [openForm, setOpenForm] = useState(false);

  const allCenarioQuery = useFindCenarioBySituacaoId(id);
  const cenarios = allCenarioQuery.data || [];
  
  function handleOpen() {
    setOpenForm((cur) => !cur)
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-[16px]">
        <h1 className="text-dark-base text-lg font-normal">Cenário</h1>
        <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleOpen}>Adicionar</Button>
      </div>
      <div className="flex flex-row">
        {!allCenarioQuery.isLoading && allCenarioQuery.isSuccess && (
          <>
            {cenarios.length > 0 ? cenarios.map(c => (
              <CardCenario key={c.id} data={c} />
            )) : (
            <>
              <EmptyState text="Nenhum cenário cadastrado" />
            </>)}
          </>
        )}
      </div>
      {allCenarioQuery.isLoading && (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}
      {!allCenarioQuery.isLoading && allCenarioQuery.isError && (
        <div className="w-full">
          <BoxError text="Erro ao carregar as informações" />
        </div>
      )}
      <DialogFormCenario handleOpen={handleOpen} open={openForm} situacaoId={id} />
    </div>    
  )
}