import { FiSearch } from "react-icons/fi";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { CardProjeto } from "../../components/CardProjeto";
import { CardTotalizador } from "../../components/Projeto/CardTotalizador";
import { ReactComponent as ConstructionNegativeSvg } from "../../assets/icons/construction-negative.svg";
import { ReactComponent as ConstructionPositiveSvg } from "../../assets/icons/construction-positive.svg";
import { ReactComponent as CoinNegativeSvg } from "../../assets/icons/coin-negative.svg";
import { ReactComponent as CoinPositiveSvg } from "../../assets/icons/coin-positive.svg";
import './leaflet.css'
import { Button, Typography } from "@material-tailwind/react";
import { DialogFormProjeto } from "../../components/DialogFormProjeto";
import { useEffect, useMemo, useState } from "react";
import { useAllProjeto, useAllProjetoPainelKpi } from "../../hooks/query/useProjetoQuery";
import { useLocation, useNavigate, useNavigation, useSearchParams } from "react-router-dom";
import { useFindLocalizacaoProjetos } from "../../hooks/query/useLocalizacaoQuery";
import { ReportProjeto } from "../../components/Report/ReportProjeto";
import { TProjeto, allProjetoNoPage, allProjetoPainelKpi } from "../../providers/projeto";
import { EmptyState } from "../../components/EmptyState";
import Pagination from "../../components/Pagination";
import { usePDF } from "react-to-pdf";
import { ReportListProjeto } from "../../components/Report/ReportListProjeto";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import { ToastContainer } from "react-toastify";
import { RiKey2Fill } from "react-icons/ri";
import { FaBuilding } from "react-icons/fa";
import { IoMdBriefcase } from "react-icons/io";
import { useUser } from "../../hooks/UserContext";


export function Projetos() {

  let [searchParams, setSearchParams] = useSearchParams();

  const { secretariaSelected } = useUser()

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);
  
  const [dialogForm, setDialogForm] = useState(false);

  const [activeTab, setActiveTab] = useState("visao_geral");
  const [textSearch, setTextSearch] = useState('')
  const [submitSearch, setSubmitSearch] = useState<any>({})
  const [typeProjeto, setTypeProjeto] = useState('todos')

  const projetoQuery = useAllProjeto(currentPage, pageSize, submitSearch, typeProjeto, secretariaSelected.id);
  const projeto = projetoQuery.data || [];
  const totalPages = projetoQuery.data?.totalPages || 0;

  useEffect(() => {
    if (searchParams.get('tab')) {
      setActiveTab('lista')
    }
  }, [])

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  }

  const projetoPainelKpiQuery = useAllProjetoPainelKpi();
  const projetoPainelKpi = projetoPainelKpiQuery.data;

  const localizacaoQuery = useFindLocalizacaoProjetos();
  const localizacoes = localizacaoQuery.data || [];

  const bounds = useMemo(() => {
    const positions: any[] = [];
    if (localizacoes.length > 0) {
      localizacoes.forEach(l => {
        positions.push([l.latitude, l.longitude])
      })
    }

    return positions
  }, [localizacoes])

  function handleDialogFormProject() {
    setDialogForm(prev => !prev);
  }


  function handleSearch() {
    setCurrentPage(1);
    setSubmitSearch({
      nome: textSearch
    })
  }

  function handleTypeProject(type: string) {
    try {
      if (type === typeProjeto) {
        setTypeProjeto('todos')
      } else {
        setTypeProjeto(type)
      }

    } catch (err) {

    }
  }

  return (
    <div>
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-[24px]">
        <div>
          <h1 className="text-[24px] lg:text-[42px] text-dark-base">Painel de Projetos</h1>
        </div>
        <div className="flex flex-row mt-[16px] lg:mt-0">
          {/* <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button w-full lg:w-auto" variant="outlined" onClick={handleReport} disabled={loadingReport}>
            {loadingReport ? 'Carregando...' : 'Gerar relatório' }
          </Button> */}
          <div className="w-[16px]" />
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button w-full lg:w-auto" variant="outlined" onClick={handleDialogFormProject}>Novo projeto</Button>
        </div>
      </div>
      <div className="mt-[24px] w-full border-b border-b-[#E0E2E7] flex flex-row items-center">
        <div 
          onClick={() => setActiveTab('visao_geral')}
          className={`pb-[10px] w-fit	cursor-pointer ${activeTab === 'visao_geral' && `border-b-[2px] border-b-[#0062FF]`}`}
        >
          <Typography className={`${activeTab === 'visao_geral' ? `text-[#0062FF] font-semibold` : 'text-[#667085] font-normal'}`}>Visão geral</Typography>
        </div>
        <div className="w-[16px]" />
        <div 
          onClick={() => setActiveTab('lista')}
          className={`pb-[10px] w-fit	cursor-pointer ${activeTab === 'lista' && `border-b-[2px] border-b-[#0062FF]`}`}
        >
          <Typography className={`${activeTab === 'lista' ? `text-[#0062FF] font-semibold` : 'text-[#667085] font-normal'}`}>Listagem de projetos</Typography>
        </div>
        <div className="w-[16px]" />
      </div>

      
     

      {activeTab === 'lista' && (
        <>
          
          {!localizacaoQuery.isLoading && localizacaoQuery.isSuccess && (
            <div className="mt-[24px]">
              
              {localizacoes.length > 0 && (
                <MapContainer bounds={bounds} zoom={13} scrollWheelZoom={false} >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {localizacoes.map(i => (
                    <Marker position={[i.latitude, i.longitude]} key={i.id}>
                      <Popup>
                        {i.endereco}
                      </Popup>
                    </Marker>
                  ))}
                  {/* <LocationMarker /> */}
                </MapContainer>
              )}
            </div>
          )}
          <div className="mt-[24px]">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
              <CardTotalizador icon={<ConstructionNegativeSvg />} title="Quantidade de projetos em execução" value={projetoPainelKpi?.projetoExecucaoPainelKPI?.quantidade || 0} setType={() => handleTypeProject('execucao')} active={typeProjeto === 'execucao'} />
              <CardTotalizador icon={<CoinNegativeSvg  /> } title="Valor total dos  projetos em execução" value={projetoPainelKpi?.projetoExecucaoPainelKPI?.valorTotal || 0} isMoney setType={() => handleTypeProject('valor_execucao')} active={typeProjeto === 'valor_execucao'} />
              <CardTotalizador icon={<ConstructionPositiveSvg />} title="Quantidade de projetos estratégicos" value={projetoPainelKpi?.qtdProjetosEstrategicos || 0} setType={() => handleTypeProject('estratégico')} active={typeProjeto === 'estratégico'}/>
              <CardTotalizador icon={<CoinPositiveSvg />} title="Valor total para captação" value={projetoPainelKpi?.valorTotalCaptacao || 0} isMoney setType={() => handleTypeProject('captacao')} active={typeProjeto === 'captacao'} />
            </div>
          </div>
          <div className="mt-[40px]">

            <div className="">
              <div className="flex flex-row">
                <div className="relative flex-1">
                  <button className="absolute left-0 top-1/2 -translate-y-1/2 mx-[16px]">
                    <FiSearch size={24} />
                  </button>

                  <input
                    type="text"
                    placeholder="Buscar..."
                    onChange={(e) => setTextSearch(e.target.value)}
                    className="w-full bg-transparent pl-12 pr-4 focus:outline-none border py-[8px] rounded-[8px] border-[#E4E4E4] bg-white"
                  />
                </div>
                <div className="ml-[16px]">
                  <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleSearch}>Pesquisar</Button>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-4 mt-[24px]">
              <div className={`flex flex-row min-h-[40px] border-solid border-[1px] border-[#0062FF] rounded-full px-[16px] py-[8px] w-full justify-center cursor-pointer ${typeProjeto === 'estrategico_button' ? 'bg-[#0062FF] text-white' : 'bg-[#EAF2FF] text-black' }`} onClick={() => handleTypeProject('estrategico_button')}>
                <RiKey2Fill color={typeProjeto === 'estrategico_button' ? '#fff' : "#0062FF"} size={24}/>
                <h6 className="text-sm font-normal mt-[2px] ml-[8px]">Projeto estratégico</h6>
              </div>
              <div className={`flex flex-row min-h-[40px] border-solid border-[1px] border-[#66CB9F] rounded-full px-[16px] py-[8px] w-full justify-center cursor-pointer ${typeProjeto === 'favorito_estado' ? 'bg-[#66CB9F] text-white' : 'bg-[#EFFFF8] text-black' }`} onClick={() => handleTypeProject('favorito_estado')}>
                <IoMdBriefcase color={typeProjeto === 'favorito_estado' ? '#fff' : '#66CB9F' } size={24}/>
                <h6 className="text-sm font-normal mt-[2px] ml-[8px]">Favorito do Estado</h6>
              </div>
              <div className={`flex flex-row min-h-[40px]  border-solid border-[1px] border-[#FFA24C] rounded-full px-[16px] py-[8px] w-full justify-center cursor-pointer ${typeProjeto === 'favorito_secretaria' ? 'bg-[#FFA24C] text-white' : 'bg-[#FFF4EA] text-black' }`} onClick={() => handleTypeProject('favorito_secretaria')}>
                <FaBuilding color={typeProjeto === 'favorito_secretaria' ? `#fff` : '#FFA24C'} size={24}/>
                <h6 className="text-sm font-normal mt-[2px] ml-[8px]">Favorito da Secretaria</h6>
              </div>
            </div>
          </div>
          <div className="mt-[24px]">
            {!projetoQuery.isLoading && projetoQuery.isSuccess && (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <>
                  {projeto.content.length > 0 && projeto.content.map((i, index) => (
                    <CardProjeto key={index} data={i}/>
                  ))}
                </>
              </div>
            )}
          </div>
          <div className="mt-[24px]">
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </div>
          
          
        </>
      )}

      <div className={`w-full h-[180vh] mt-[24px] ${activeTab === 'visao_geral' ? 'flex' : 'hidden'}`}>
        <iframe
          title="PowerBI Report"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiMTZmNWQ0MjYtN2RlNC00Y2M0LTk5ODctMWQyNzRmMDNkNzY4IiwidCI6ImJhNmIwMzJmLTU2YmUtNGRkNC1hZmJhLWQ1MTgzN2I1ZjNiNCJ9"
          allow="fullscreen"
        />
      </div>
      <DialogFormProjeto handleOpen={handleDialogFormProject} open={dialogForm} />
      <ToastContainer />
    </div>
  )
}