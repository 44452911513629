import { Button, Checkbox, Spinner } from "@material-tailwind/react";
import { HiChevronDown, HiChevronUp, HiOutlinePencil, HiOutlineTrash, HiPlusCircle } from "react-icons/hi";
import { TPlanoAcao, atuacaoByPlanoAcaoId, removeAtuacaoPlanoAcao, removePlanoAcao, updatePlanoAcao } from "../../providers/plano-acao";
import { format, parseISO } from "date-fns";
import { DialogFormPlanoAcao } from "../DialogFormPlanoAcao";
import { useState } from "react";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { setValueMasks } from "../../utils/number.utils";
import TextTruncate from 'react-text-truncate';
import { useNavigate } from "react-router-dom";
import { EmptyState } from "../EmptyState";
import { DialogFormAcaoAtuacao } from "../DialogFormAcaoAtuacao";
import { useAllSecretaria } from "../../hooks/query/useSecretariaQuery";

type Props = {
  data: TPlanoAcao[];
  isSecretaria?: boolean;
}

export function GridPlanoAcao({ data, isSecretaria = false }: Props) {
  const [dialogForm, setDialogForm] = useState(false);
  const [dialogFormAtuacao, setDialogFormAtuacao] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [currentPlanoAcao, setCurrentPlanoAcao] = useState<TPlanoAcao>({} as TPlanoAcao);
  const [ixActive, setIxActive] = useState<number>(-1);
  const [loadingAccordion, setLoadingAccordion] = useState(false);
  const [atuacoes, setAtuacoes] = useState<any[]>([1]);
  const [dialogRemoveAtuacao, setDialogRemoveAtuacao] = useState(false);
  const [atuacaoId, setAtuacaoId] = useState<number>()

  const allSecretariaQuery = useAllSecretaria();
  const secretarias = allSecretariaQuery.data || [];

  function handleSecretariaName(id: number = 0) {
    try {
      if (secretarias.length > 0) {
        const name = secretarias.find(s => s.id === id)?.nome;
        return name || id;
      }

      return id;
    } catch (err) {
      return id
    }
  }

  const [openAccordion, setOpenAccordion] = useState<number>()

  const queryClient = useQueryClient()

  function handleDialogForm() {
    setDialogForm((prev) => !prev);
  
  }
  async function handleDialogFormAtuacao() {
    setDialogFormAtuacao((prev) => !prev);
    if (openAccordion === currentPlanoAcao.id && dialogFormAtuacao) {
      setLoadingAccordion(true);
      setOpenAccordion(currentPlanoAcao.id);
      const response = await atuacaoByPlanoAcaoId(currentPlanoAcao.id);

      if (response.length > 0) {
        setAtuacoes(response)
      } else {
        setAtuacoes([])
      }

      setLoadingAccordion(false);
    }
  }

  function handleDialogRemove() {
    setDialogRemove((prev) => !prev);
  }

  function handleDropdown(index: number) {
    setIxActive(prev => prev === index ? -1 : index)
  }

  const navigate = useNavigate();

  async function handleRemove() {
    try {

      setLoadingRemove(true)
      await removePlanoAcao(currentPlanoAcao.id);
      
      toast('Impacto excluído com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleDialogRemove()

      if (!!currentPlanoAcao.situacaoId) {
        queryClient.resetQueries([currentPlanoAcao.situacaoId, 'find-plano-acao-by-situcao-id']);
        queryClient.invalidateQueries([currentPlanoAcao.situacaoId, 'find-plano-acao-by-situcao-id']);
      }

      if (!!currentPlanoAcao.projetoId) {
        queryClient.resetQueries([currentPlanoAcao.projetoId, 'find-plano-acao-by-projeto-id']);
        queryClient.invalidateQueries([currentPlanoAcao.projetoId, 'find-plano-acao-by-projeto-id']);
      }

    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir plano de ação', {type: 'error'});
    }
  }

  function formatDate(date: string) {
    const dt = new Date(date);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

    try {
      return format(dtDateOnly, 'dd/MM/yyyy');
    } catch(err) {
      return ''
    }
  }

  function handleEdit(plano: TPlanoAcao) {
    setCurrentPlanoAcao(plano);
    handleDialogForm();
  }

  async function handleMonitorar(plano: TPlanoAcao) {
    try {
      await updatePlanoAcao(plano.id, {
        ...plano,
        monitorar: !plano.monitorar
      })
      toast('Plano de ação atualizado com sucesso', {type: 'success'});
      
      queryClient.resetQueries([plano.situacaoId, 'find-plano-acao-by-situcao-id']);
      queryClient.invalidateQueries([plano.situacaoId, 'find-plano-acao-by-situcao-id']);

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar plano de ação', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
    }
  }

  function handleNavigateOrigem(id?: number, isSituacao = false, isProjeto = false) {
    if (isSituacao && !!id) {
      navigate(`/internal/sala-situacao/${id}`)
      return;
    }
    
    if (isProjeto && !!id) {
      navigate(`/internal/projeto/${id}`)
      return;
    }

  }

  function formatStatusEnum(status = '') {
    if (status === 'A_INICIAR') {
      return 'A iniciar';
    }
    if (status === 'EM_EXECUCAO') {
      return 'Em execução';
    }
    if (status === 'CONCLUIDO') {
      return 'Concluído';
    }
    if (status === 'IMPEDIDO') {
      return 'Impedido';
    }
    if (status === 'PAUSADO') {
      return 'Pausado';
    }

    return '';
  }

  async function handleToggleAccordion(ix: number) {

    if (openAccordion === ix) {
      setOpenAccordion(undefined)
      setAtuacoes([])
    } else {
      setLoadingAccordion(true);
      setOpenAccordion(ix);
      const response = await atuacaoByPlanoAcaoId(ix);

      if (response.length > 0) {
        setAtuacoes(response)
      } else {
        setAtuacoes([])
      }

      setLoadingAccordion(false);
    }

  }

  async function handleRemoveDialogAtuacao() {
    setDialogRemoveAtuacao(prev => !prev);
  }

  async function handleRemoveAtuacao() {
    try { 
      setLoadingRemove(true);
      await removeAtuacaoPlanoAcao(atuacaoId!)
      const response = await atuacaoByPlanoAcaoId(currentPlanoAcao.id);

      if (response.length > 0) {
        setAtuacoes(response)
      } else {
        setAtuacoes([])
      }
      toast('Atuação excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleRemoveDialogAtuacao()
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', {type: 'error'});
    }
  }

  return (
    <div>
      {data.length > 0 && data.map(d => (
        <div key={d.id} className="group">
          <div className={`flex flex-row items-center pt-[16px] ${openAccordion === d.id ? 'bg-[#F5F8FA]' : 'bg-white'}`}>
            <div className="pr-4" onClick={() => handleToggleAccordion(d.id)}>
              <HiChevronDown size={32} />
            </div>
            <div className="flex-1 cursor" onClick={() => handleToggleAccordion(d.id)}>
              <div className="flex flex-row cursor-pointer">
                <div className="w-[20%]">
                  <label className="font-light text-xs text-dark-tint">Ação</label>
                  <p className="font-normal text-sm text-dark-base">{d.nome}</p>
                </div>
                <div className="w-[30%]">
                  <label className="font-light text-xs text-dark-tint">Secretaria</label>
                  <p className="font-normal text-sm text-dark-base">{(d.secretaria && !!d.secretaria.nome) ? d.secretaria.nome : '-' }</p>
                </div>
                <div className="w-[20%]">
                  <label className="font-light text-xs text-dark-tint">Responsável</label>
                  <p className="font-normal text-sm text-dark-base">{d.responsavel}</p>
                </div>
                {/* <div className="w-[20%]">
                  <label className="font-light text-xs text-dark-tint">Monitorar</label>
                  <div>
                    <Checkbox color="blue" checked={d.monitorar} crossOrigin={'*'} onClick={() => handleMonitorar(d)} />
                  </div>
                </div> */}
              </div>
              <div className="mt-[8px] flex flex-row">
                <div className="w-[20%]">
                  <label className="font-light text-xs text-dark-tint">Prazo</label>
                  <p className="font-normal text-sm text-dark-base">{formatDate(d.prazoAcao)}</p>
                </div>
                <div className="w-[30%]">
                  <label className="font-light text-xs text-dark-tint">Custo previsto</label>
                  <p className="font-normal text-sm text-dark-base">R$ {setValueMasks(d.custoPrevisto || 0)}</p>
                </div>
                <div className="w-[20%]">
                  <label className="font-light text-xs text-dark-tint">Recursos necessários</label>
                  <p className="font-normal text-sm text-dark-base">{d.recursosNecessarios}</p>
                </div>
                <div className="w-[20%]">
                  <label className="font-light text-xs text-dark-tint">Status</label>
                  <span className="flex h-[24px] px-[16px] items-center justify-between border border-warning-card text-warning-card bg-warning-card-opacity rounded-full text-xs font-semibold absolute">
                    <h1>{d.status?.nome}</h1>
                  </span>
                </div>
              </div>
            </div>
            
              <div className="hidden group-hover:flex flex-row items-center justify-center gap-4">
                <button onClick={() => {
                  setCurrentPlanoAcao(d);
                  handleDialogRemove();
                }}>
                  <HiOutlineTrash color="#0062FF" size={24} />
                </button>
                <button onClick={() => {
                  setCurrentPlanoAcao(d);
                  handleDialogForm();
                }}>
                  <HiOutlinePencil color="#0062FF" size={24} />
                </button>
                <button onClick={() => {
                  setCurrentPlanoAcao(d);
                  setAtuacaoId(undefined)
                  handleDialogFormAtuacao()
                }}>
                  <HiPlusCircle color="#0062FF" size={24} />
                </button>
              </div>
          </div>
          {openAccordion === d.id && (
            <div className="p-[16px] bg-[#F5F8FA]">
              <div className="p-[16px] bg-white rounded-lg">

                {loadingAccordion ? (
                  <div className="flex items-center justify-center mt-[16px]">
                    <Spinner />
                  </div>
                ) : atuacoes.length > 0 ? (
                  <>
                    {atuacoes.map(ad => (
                      <div key={ad.id} className="w-full flex flex-row mb-[8px] group">
                        <div className="w-[30%]">
                          <label className="font-light text-xs text-dark-tint">Atuação</label>
                          <p className="font-normal text-sm text-dark-base">{ad.descricao}</p>
                        </div>
                        <div className="w-[20%]">
                          <label className="font-light text-xs text-dark-tint">Data</label>
                          <p className="font-normal text-sm text-dark-base">{!!ad.dataCriacao ? formatDate(ad.dataCriacao) : '-'}</p>
                        </div>
                        <div className="w-[20%] flex flex-row">
                          <div>
                            <label className="font-light text-xs text-dark-tint">Usuário</label>
                            <p className="font-normal text-sm text-dark-base">{ad.autor}</p>
                          </div>
                        </div>
                        <div className="w-[20%] flex flex-row">
                          <div>
                            {/* <label className="font-light text-xs text-dark-tint">Custo</label> */}
                            {/* <p className="font-normal text-sm text-dark-base">{ad.autor}</p> */}
                          </div>
                        </div>
                        <div className="w-[10%] flex flex-row items-center justify-center">
                          <div className="hidden group-hover:inline-block">
                            <button className="mr-[16px]" onClick={() => {
                              setAtuacaoId(ad.id);
                              handleRemoveDialogAtuacao()
                            }}>
                              <HiOutlineTrash color="#0062FF" size={24} />
                            </button>
                            <button onClick={() => {
                              setCurrentPlanoAcao(d);
                              setAtuacaoId(ad.id);
                              handleDialogFormAtuacao()
                            }}>
                              <HiOutlinePencil color="#0062FF" size={24} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <EmptyState text="Nenhuma atuação cadastrada" />  
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
      
      <DialogFormPlanoAcao handleOpen={handleDialogForm} open={dialogForm} idSituacao={currentPlanoAcao.situacaoId || 0} idProjeto={currentPlanoAcao.projetoId || 0} planoAcao={currentPlanoAcao} />
      <DialogRemoveItem handleConfirm={handleRemove} handleOpen={handleDialogRemove} open={dialogRemove} loadingConfirm={loadingRemove} />
      <DialogFormAcaoAtuacao handleOpen={handleDialogFormAtuacao} open={dialogFormAtuacao} planoAcaoId={currentPlanoAcao.id} atuacaoId={atuacaoId} />
      <DialogRemoveItem handleOpen={handleRemoveDialogAtuacao} open={dialogRemoveAtuacao} handleConfirm={handleRemoveAtuacao} loadingConfirm={loadingRemove} />
    </div>
  )
}
