import { useEffect, useState } from "react"

import { useAllPlanoAcaoAgenda }  from "../../hooks/query/usePlanoAcaoQuery"

import { useCalendarApp, ScheduleXCalendar } from '@schedule-x/react'
import {
  viewWeek,
  viewDay,
  viewMonthGrid,
} from '@schedule-x/calendar'
import EventoMesCustom from "./EventoMesCustom"
import { createEventModalPlugin } from '@schedule-x/event-modal'
import '@schedule-x/theme-default/dist/index.css'
import './style.css'
import { ModalEventDetail } from "./ModalEventDetail"
import { useNavigate } from "react-router-dom"

type TProps = {
  planoAcaoAgenda: any
}

export function CalendarApp({planoAcaoAgenda}: TProps) {
  const [modalDetail, setModalDetail] = useState(false);
  const [calendarDetail, setCalendarDetail] = useState<any>({});
  const navigate = useNavigate();
  
  function handleModalDetail() {
    setModalDetail(prev => !prev);
  }

  useEffect(() => {
      setTimeout(() => {
        const a = document.getElementsByClassName("sx__today-button");

        if (a.length > 0) {
          a[0].innerHTML = 'Hoje'
        }
      }, 100);
  }, [planoAcaoAgenda])


  const events = planoAcaoAgenda?.map((plano: any) => {
    let start = '';

    if (!!plano.prazoAcao) {
      start = plano.prazoAcao.split('T')[0]; 
    }

    if (!!plano.prazo) {
      start = plano.prazo.split('T')[0]; 
    }

    return {
      id: plano.id,
      title: plano.nome,
      start: start,
      end: start,
      calendarId: 'leisure',
      ...plano
    }
  })

  const calendar = useCalendarApp({
    defaultView: viewMonthGrid.name,
    views: [viewDay, viewWeek, viewMonthGrid],
    locale: 'pt-BR',
    events,
    callbacks: {
      onEventClick(calendarEvent) {
        console.log('onClickEvent', calendarEvent)

        if (!!calendarEvent.prazo) {
          navigate(`/internal/projeto/${calendarEvent.id}`)
        } else {
          setCalendarDetail(calendarEvent);
          handleModalDetail();
        }

      },
      onClickDate(date) {
        console.log('onClickDate', date)
      },
    },
    calendars: {
      leisure: {
        colorName: 'leisure',
        lightColors: {
          main: 'red',
          container: 'red',
          onContainer: 'red',
        },
        darkColors: {
          main: '#ffffff',
          onContainer: '#ffffff',
          container: '#ffffff',
        },
      },
    }
    // plugins: [createEventModalPlugin()],
    
  })
 
  return (
      <>
        <ScheduleXCalendar calendarApp={calendar} customComponents={{ monthGridEvent: EventoMesCustom }} />
        <ModalEventDetail handleOpen={handleModalDetail} open={modalDetail} detail={calendarDetail}/>
      </>
  )
}