import { useState } from "react";
import { TEstrategia } from "../../providers/estrategia"
import { DialogViewEstrategia } from "../DialogViewEstrategia";
import TextTruncate from "react-text-truncate";

type TProps = {
  data: TEstrategia;
}

export function CardEstrategia({ data }: TProps) {
  const [dialogView, setDialogView] = useState(false);

  function handleDialogView() {
    setDialogView(prev => !prev);
  }

  return (
    <div className="flex flex-col justify-between p-[16px] bg-[#F5F8FA] mr-[16px] min-w-[270px] max-w-[270px] h-[380px] mb-[8px]">
      <div>
        <div>
          <TextTruncate
              line={2}
              element="h1"
              truncateText="…"
              text={data.nome}
              containerClassName="text-dark-tint text-lg font-semibold"
              // textTruncateChild={<a href="#">Read on</a>}
          />
          {/* <h1 className="text-dark-tint text-lg font-semibold">{data.nome}</h1> */}
        </div>
        <div className="my-[8px]">
          <p className="text-base font-normal text-dark-tint overflow-hidden">{data.descricao}</p>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end">
        <div className="flex flex-row items-center cursor-pointer">
          <a className="font-light text-[#3A57F2] text-sm" onClick={handleDialogView}>Visualizar {'>'}</a>
        </div>
      </div>
      <DialogViewEstrategia data={data} handleOpen={handleDialogView} open={dialogView} />
    </div>
  )
}