import { Button, Card, Dialog, Input, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { TEstrategia, deleteEstrategia } from "../../providers/estrategia";
import { format, parseISO } from "date-fns";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { DialogFormEstrategia } from "../DialogFormEstrategia";
import { setValueMasks } from "../../utils/number.utils";
import { TPlanoAcao, atuacaoByPlanoAcaoId } from "../../providers/plano-acao";
import { useAllSecretaria } from "../../hooks/query/useSecretariaQuery";
import { useAllSituacao } from "../../hooks/query/useSituacaoQuery";
import { FaCheck } from "react-icons/fa";
import { associarSituacaoProjeto, deleteSituacaoProjeto } from "../../providers/situacao";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  data?: any[];
  projetoId: number;
}

export function DialogAssociarSituacoes({handleOpen, open, data = [], projetoId}: IProps) {

  const queryClient = useQueryClient();
  
  const [situacaoSelected, setSituacaoSelected] = useState<number[]>(data);
  const allSituacao = useAllSituacao();
  const situacoes = allSituacao.data || [];

  function handleSelect(id: number) {
    const newArr = [...situacaoSelected];
    const ix = situacaoSelected.findIndex(ss => ss === id)

    if (ix > -1) {
      newArr.splice(ix, 1);
    } else {
      newArr.push(id)
    }

    setSituacaoSelected(newArr);
  }

  function handleIsSelected(id: number) {
    return !!situacaoSelected.find(ss => ss === id)
  }

  async function handleVincular() {
    try {
      if (situacaoSelected.length > 0) {
        for (let i = 0; i < situacaoSelected.length; i++) {
          try {
            const exists = data.find(d => d.id === situacaoSelected[i]);

            if (!exists) {
              await associarSituacaoProjeto(situacaoSelected[i], projetoId);
            }

          } catch {
            continue
          }
        }
      }

      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          try {
            const exists = situacaoSelected.find(d => d === data[i].id);

            if (!exists) {
              await deleteSituacaoProjeto(data[i].id, projetoId);
            }

          } catch {
            continue
          }
        }
      }

      await queryClient.resetQueries([projetoId, 'projeto-situacoes']);
      await queryClient.invalidateQueries([projetoId, 'projeto-situacoes']);

      handleOpen()

    } catch(err) {

    }
  }

  useEffect(() => {
    if (open && projetoId && data.length > 0) {
      const sit = data.map(d => {
        return d.id;
      })
      setSituacaoSelected(sit)
    } else {
      setSituacaoSelected([])
    }
  }, [open, projetoId])

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between pb-[24px] border-b border-b-[#E4E4E4] mb-[24px]">
            <div className="flex-[0.8]">
              <h1 className="text-dark-base text-3xl font-semibold">Gerenciar situações</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <div className="max-h-[42rem] overflow-y-scroll">
            
            {allSituacao.isLoading && (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            )}
            {!allSituacao.isLoading && allSituacao.isSuccess && situacoes.length > 0 && (
              <div className="grid grid-cols-2 gap-4">
                {situacoes.map(i => (
                  <div 
                    className={
                      `flex flex-row items-center p-4 border-solid border-[1px] border-[#E4E4E4] rounded-lg gap-4 cursor-pointer`
                    } 
                    onClick={() => handleSelect(i.id)}
                  >
                    <div 
                      className={`border-solid border-[2px] border-[#0E66FF] rounded w-6 h-6 ${handleIsSelected(i.id) ? 'bg-[#0E66FF] pt-[3px] pl-[3px]' : ''}`}
                    >
                      {handleIsSelected(i.id) && (
                        <div>
                          <FaCheck size={14} color="white" />
                        </div>
                      )}  
                    </div>
                    <h1 className="font-normal text-sm">{i.nome}</h1>
                  </div>  
                ))}
              </div>
            )}

            <div className="mt-[24px] flex flex-row justify-between items-center">
              <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={() => {
                
              }}>Cancelar</Button>
              <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button border-solid" variant="outlined" onClick={() => {
                handleVincular()
              }}>Salvar</Button>
            </div>
            

          </div>
        </div>
      </Card>
    </Dialog>
  )
}