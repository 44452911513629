type props = {
  calendarEvent: {
    id: string | number
    title: string
    start: string
    end: string
  }
}

export default function EventoMesCustom({ calendarEvent }: props) {
  return (
    <div
    style={{
      backgroundColor: 'rgb(224 238 252 / 1)',
      color: 'blue',
      width: '100%',
      fontSize: 12,
      fontWeight: 600,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '4px 8px'
    }}
    >
      {calendarEvent.title}
    </div>
  )
}