import { FaChevronRight } from "react-icons/fa";

type TProps = {
  scrollToAcao: () => void;
  scrollToImpacto: () => void;
  scrollToMedida: () => void;
  scrollToCenario: () => void;
  scrollToEstrategia: () => void;
  scrollToProjeto: () => void;
  scrollToResumo: () => void;
  boxActive: string;
}

export function Breadcrumb({scrollToAcao, boxActive, scrollToImpacto, scrollToCenario, scrollToEstrategia, scrollToMedida, scrollToProjeto, scrollToResumo}: TProps) {
  return (
    <header className=" flex flex-row items-center mb-[16px] bg-blue-gray-50 rounded-full px-[8px]">
      <a className={`${boxActive === 'Resumo' ? 'font-semibold text-blue-card' : 'font-medium cursor-pointer'}`} onClick={scrollToResumo}>Resumo</a>
      <FaChevronRight className="mx-[8px]" />
      <a className={`${boxActive === 'Impacto' ? 'font-semibold text-blue-card' : 'font-medium cursor-pointer'}`} onClick={scrollToImpacto}>Impacto</a>
      <FaChevronRight className="mx-[8px]" />
      <a className={`${boxActive === 'Medidas iniciais' ? 'font-semibold text-blue-card' : 'font-medium cursor-pointer'}`} onClick={scrollToMedida}>Medidas iniciais</a>
      <FaChevronRight className="mx-[8px]" />
      <a className={`${boxActive === 'Cenários' ? 'font-semibold text-blue-card' : 'font-medium cursor-pointer'}`} onClick={scrollToCenario}>Cenários</a>
      <FaChevronRight className="mx-[8px]" />
      <a className={`${boxActive === 'Estratégias' ? 'font-semibold text-blue-card' : 'font-medium cursor-pointer'}`} onClick={scrollToEstrategia}>Estratégias</a>
      <FaChevronRight className="mx-[8px]" />
      <a className={`${boxActive === 'Projetos' ? 'font-semibold text-blue-card' : 'font-medium cursor-pointer'}`} onClick={scrollToProjeto}>Projetos</a>
      <FaChevronRight className="mx-[8px]" />
      <a className={`${boxActive === 'Ações' ? 'font-semibold text-blue-card' : 'font-medium cursor-pointer'}`} onClick={scrollToAcao}>Ações</a>
    </header>
  )
}