import { Alert, Button, Spinner } from "@material-tailwind/react";
import { CardSolucao } from "../../CardSolucao";
import { useState } from "react";
import { DialogFormSolucao } from "../../DialogFormSolucao";
import { useFindSolucaBySituacaoId } from "../../../hooks/query/useSolucaoQuery";
import { FaSearch } from "react-icons/fa";
import { EmptyState } from "../../EmptyState";
import { BoxError } from "../../BoxError";

type TProps = {
  situacaoId: number;
}

export function BoxSolucao({ situacaoId }: TProps) {
  const [openForm, setOpenForm] = useState(false)

  const solucaoQuery = useFindSolucaBySituacaoId(situacaoId);
  const solucoes = solucaoQuery.data || [];

  function handleOpen() {
    setOpenForm((cur) => !cur)
  }
  
  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-[16px]">
        <h1 className="text-dark-base text-lg font-normal">Soluções e medidas iniciais</h1>
        <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleOpen}>Adicionar</Button>
      </div>
      <div className="flex overflow-x-scroll pb-[16px]">
        {!solucaoQuery.isLoading && solucaoQuery.isSuccess && (
          <>
            {solucoes.length > 0 ? solucoes.map(c => (
              <CardSolucao key={c.id} data={c} />
            )) : (<>
              <EmptyState text="Nenhuma solução encontrada" />
            </>)}
          </>
        )}
      </div>
      {solucaoQuery.isLoading &&  (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!solucaoQuery.isLoading && solucaoQuery.isError && (
        <div className="w-full">
          <BoxError text="Erro ao carregar as informações" />
        </div>
      )}

      <DialogFormSolucao open={openForm} handleOpen={handleOpen} idSituacao={situacaoId} />
    </div>
  )
}