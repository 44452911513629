import { useQuery } from "react-query"
import { findSituacao, findSituacaoProjeto, getSituacao } from "../../providers/situacao"

export const useAllSituacao = () => {
  return useQuery(['all-situacao'], async () => await getSituacao());
}

export const useSituacao = (id: number) => {
  return useQuery([id, 'situacao'], async () => await findSituacao(id));
}

export const useSituacaoProjeto = (id: number) => {
  return useQuery([id, 'situacao-projeto'], async () => await findSituacaoProjeto(id));
}