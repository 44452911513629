import { EmptyState } from "../../components/EmptyState";

export function DistribuicaoSecretaria() {
  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-[24px]">
        <div>
          <h1 className="text-[42px] text-dark-base">Distribuição por secretaria</h1>
        </div>
        {/* <div>
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleOpenModalFormSituacao}>Nova situação</Button>
        </div> */}
      </div>

      <div className="w-full h-[89vh]">
        <iframe
          title="PowerBI Report"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiMjE4ZDQ1MTktMDBlOS00N2RiLWI3NzEtOTIxZWY0MmMyN2E4IiwidCI6ImJhNmIwMzJmLTU2YmUtNGRkNC1hZmJhLWQ1MTgzN2I1ZjNiNCJ9"
          allow="fullscreen"
        />
      </div>

      {/* <EmptyState text="Em breve" /> */}
    </div>
  )
}