import { useFindPlanoAcaoBySituacaoId } from "../../../hooks/query/usePlanoAcaoQuery";
import './style.css';

type TProps = {
  situacaoId: number;
}

export function BoxPlanoAcao({ situacaoId }: TProps) {

  const planoAcaoQuery = useFindPlanoAcaoBySituacaoId(situacaoId);
  const planoAcao = planoAcaoQuery.data || [];

  function statusStyle(status: number) {
    switch (status) {
      case 4:
        return 'iniciar';
      case 5:
        return 'execucao';
      case 6:
        return 'concluido';
      case 7:
        return 'impedido';
      case 8:
        return 'pausado';
      default:
        return 'iniciar';
    }
  }

  return (
    <>
      {!planoAcaoQuery.isLoading && planoAcaoQuery.isSuccess && (
        planoAcao.length > 0 ? (
          planoAcao.map((plano, index) => (
            <>
              <div
                key={index}
                className={`pb-[16px] border rounded-full self-start w-[100px] ${statusStyle(6)}`}>
                <h1 className="text-lg font-medium text-warning-card text-center">{plano.status?.nome}</h1>
              </div>
              <div className="mt-[30px] bg-white rounded border p-[16px]">
                <div className="pb-[16px]">
                  <h1 className="text-dark-base text-3xl font-medium">{plano.nome}</h1>
                </div>
                <div className="w-full h-[1px] bg-[#CBCBCB] mb-[16px]"></div>
                <div className="grid grid-cols-2 mt-[16px]">
                  <div>
                    <label className="text-[#4A4C56] text-xl font-light">Responsável</label>
                    <h1 className="text-dark-base text-2xl font-normal">{plano.responsavel}</h1>
                  </div>
                  <div>
                    <label className="text-[#4A4C56] text-xl font-light">Prazo</label>
                    <h1 className="text-dark-base text-2xl font-normal">{plano.prazoAcao}</h1>
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-[16px]">
                  <div>
                    <label className="text-[#4A4C56] text-xl font-light">Atuação</label>
                    <h1 className="text-dark-base text-2xl font-normal">{plano.atuacao}</h1>
                  </div>
                  <div>
                    <label className="text-[#4A4C56] text-xl font-light">Custo previsto</label>
                    <h1 className="text-dark-base text-2xl font-normal">{plano.custoPrevisto}</h1>
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-[16px]">
                  <div>
                    <label className="text-[#4A4C56] text-xl font-light">Recursos necessários</label>
                    <h1 className="text-dark-base text-2xl font-normal">{plano.recursosNecessarios}</h1>
                  </div>
                </div>
              </div>
              <div className="h-[30px] w-full"></div>
            </>
          ))
        ) : (
          <div className="mt-[30px] bg-white rounded border border-[#F0F1F3] p-[16px]">
            <div className="mb-[16px]">
              <h1 className="text-dark-base text-3xl font-medium">Nenhum plano de ação encontrado</h1>
            </div>
          </div>
        )
      )}
    </>
  )
}