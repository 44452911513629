import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useImpactoStatus } from "../../hooks/query/useImpactoQuery";
import { TImpacto, createImpacto, updateImpacto } from "../../providers/impacto";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import InputMask from 'react-input-mask';
import { NumericFormat } from "react-number-format";
import { format, parse, parseISO } from "date-fns";
import { TResponseAllDemanda, allDemandas, createDemanda, createDemandaAgendamento, demandaAgendamentoById, updateDemandaAgendamento } from "../../providers/demandas";
import { formatDateStrToDateBr } from "../../utils/date.utils";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  demandaId?: number;
  agendamentoId?: number;
}

type TFormData = {
  nome: string;
  descricao: string;
  prazo: string;
  demandaId: number;
}

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  // unidade: yup.string().required('Campo obrigatório'),
  // quantidade: yup.number().required('Campo obrigatório'),
  // statusId: yup.string().required('Campo obrigatório'),
})

export function DialogFormDemandaAgendamento({handleOpen, open, demandaId = 0, agendamentoId = 0}: IProps) {
  const [isSave, setIsSave] = useState(false);
  const [demandas, setDemandas] = useState<TResponseAllDemanda[]>([])

  const queryClient = useQueryClient();

  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  function handleDismiss() {
    methods.reset();
    handleOpen();
  }

  useEffect(() => {
    async function init() {
      const response = await demandaAgendamentoById(agendamentoId);

      console.log(response)

      methods.setValue('nome', response.nome || '');
      methods.setValue('descricao', response.descricao || '');

      if (!!response.prazo) {
        const dt = formatDateStrToDateBr(response.prazo)
        methods.setValue('prazo', dt);
      }
    }

    if (agendamentoId && open) {
      init();
    }
  }, [agendamentoId, open])  

  useEffect(() => {
    async function init() {
      const response = await allDemandas(11);
      setDemandas(response || []);
    }

    if (open && !demandaId) {
      init()
    }
  }, [open, demandaId])

  const onSubmit: SubmitHandler<TFormData> = async (params) => {
    try {
      setIsSave(true)
      let prazo = '';

      if (!!params.prazo) {
        const prazoEstimadoDate = parse(params.prazo, 'dd/MM/yyyy', new Date());
        prazo = prazoEstimadoDate.toISOString();
      }

      const req = {
        ...params,
        prazo,
        demandaId: demandaId || params.demandaId
      }

      if (agendamentoId) {
        await updateDemandaAgendamento(agendamentoId, req);
        toast('Agendamento atualizado com sucesso', {type: 'success'});
      } else {
        await createDemandaAgendamento(req);
        toast('Agendamento criado com sucesso', {type: 'success'});
      }
      

      setIsSave(false)
      handleDismiss();

      queryClient.resetQueries(['all-demanda-agendamento']);
      queryClient.invalidateQueries(['all-demanda-agendamento']);
    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar demanda', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {

  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{agendamentoId ? 'Editar' : 'Novo'} agendamento</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="nome"
                render={({field, fieldState}) => (
                  <Input label="Nome do agendamento"  crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="descricao"
                render={({field, fieldState}) => (
                  <Input label="Descrição" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <div className="flex-[.5]">
                <Controller
                  name="prazo"
                  render={({field, fieldState}) => (
                    <InputMask mask="99/99/9999" value={field.value} onChange={field.onChange} >
                      {((inputProps: any) => {
                        return <Input {...inputProps} label="Prazo" type="tel" error={!!fieldState.error} />
                      }) as any}
                    </InputMask>
                  )}
                />
              </div>
              {demandas.length > 0 && (
                <>
                  <div className="w-[16px]" />
                  <Controller
                    name="demandaId"
                    render={({field, fieldState}) => (
                      <Select label="Demanda" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                        {demandas.map(i => (
                          <Option key={i.id} value={String(i.id)}>{i.nome}</Option>
                        ))}
                      </Select>
                    )}
                  />
                </>
              )}
            </div>
            <div className="mt-[24px] flex flex-row justify-between">
              <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
              <Button 
                className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                variant="outlined" 
                disabled={isSave}
                onClick={methods.handleSubmit(onSubmit, onError)}
              >
                {agendamentoId ? (
                  <>
                    {isSave ? <Spinner color="blue" /> : `Atualizar`}
                  </>
                ) : (
                  <>
                    {isSave ? <Spinner color="blue" /> : `Adicionar`}
                  </>
                )}
              </Button>
            </div>
          </FormProvider>
        </div>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}