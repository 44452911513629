import { Card, Dialog } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './leaflet.css';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { useFindLocalizacaoByProjetoId } from "../../../hooks/query/useLocalizacaoQuery";
import { useMemo, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { findCityInAddressComponents, findPostalCodeInAddressComponents } from "../../../providers/geocode";
import { TLocalizacao, createLocalizacao, deleteLocalizacao, updateLocalizacao } from "../../../providers/localizacao";
import { useQueryClient } from "react-query";


type IProps = {
  open: boolean;
  handleOpen: () => void;
  projetoId: number;
}

export function DialogLocalizacao({handleOpen, open, projetoId}: IProps) {
  const localizacaoQuery = useFindLocalizacaoByProjetoId(projetoId);
  const localizacoes = localizacaoQuery.data || [];
  const queryClient = useQueryClient();

  const [positions, setPositions] = useState<TLocalizacao>({} as TLocalizacao);


  const bounds = useMemo(() => {
    const positions: any[] = [];
    if (localizacoes.length > 0) {
      localizacoes.forEach(l => {
        positions.push([l.latitude, l.longitude])
      })
    }

    return positions
  }, [localizacoes])

  function LocationMarker() {
    const map = useMapEvents({
      click(e) {
        // setPosition(e.latlng)
        // geocodeByLatLng({lat: e.latlng.lat, lng: e.latlng.lng}).then(results => {
        //   console.log(results)
        // })
        
      },
    })

    if (positions.latitude && positions.longitude) {
      map.flyToBounds([[positions.latitude, positions.longitude]]);
    }

    if (bounds.length)  {
      map.flyToBounds(bounds);
    }

    return <></>

    // map.locate()
  
    // return !position.lat || !position.lng  ? null : (
    //   <Marker position={position}>
    //     <Popup>You are here</Popup>
    //   </Marker>
    // )
  }

  return (
    <>
      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="p-[40px]">
          <div>
            <div className="flex flex-row items-center justify-between pb-[24px] border-b border-b-[#E4E4E4]">
              <div>
                <h1 className="text-dark-base text-3xl font-semibold">Mapa da Situação</h1>
              </div>
              <div>
                <button onClick={handleOpen}>
                  <HiX size={24} color="#425466" />
                </button>
              </div>
            </div>
            <div className="mt-[24px]">
              <GooglePlacesAutocomplete
                apiKey="AIzaSyBfpn-oks9BVxtQNyAg9JvT-ZxWgu7x-7Y" 
                apiOptions={{language: 'pt-br', region: 'pt-br'}}
                selectProps={{
                  loadingMessage: () => 'Carregando...',
                  noOptionsMessage: () => 'Nenhuma localidade encontrada',
                  placeholder: <div>Selecione uma localidade</div>,
                  onChange(newValue, actionMeta)
                  {

                    if (actionMeta.action === 'select-option') {
                      geocodeByPlaceId(newValue?.value.place_id).then((result) => {

                        if (result.length > 0) {

                          const city = findCityInAddressComponents(result[0].address_components);
                          const postalCode = findPostalCodeInAddressComponents(result[0].address_components);
                          const lat: number = result[0].geometry.location.lat();
                          const lng: number = result[0].geometry.location.lng();

                          const localizacao: Omit<TLocalizacao, 'id'> = {
                            cep: postalCode || 72000000,
                            endereco: result[0].formatted_address,
                            latitude: lat,
                            longitude: lng,
                            municipio: city,
                            projetoId: 1
                          }

                          if (localizacoes.length > 0 && !!localizacoes[0].id) {
                            deleteLocalizacao(localizacoes[0].id).then(result => {
                              createLocalizacao({
                                ...localizacao,
                                projetoId: projetoId
                              }).then(result => {
                                toast('Localização atualizada com sucesso', {type: 'success'});
                                queryClient.invalidateQueries([projetoId, 'find-localizacao-by-projeto-id'])
                                queryClient.resetQueries([projetoId, 'find-localizacao-by-projeto-id'])
                                setPositions(localizacao)
                              });
                            });
                          } else {
                            createLocalizacao({
                              ...localizacao,
                              projetoId: projetoId
                            }).then(result => {
                              toast('Localização criada com sucesso', {type: 'success'});
                              queryClient.invalidateQueries([projetoId, 'find-localizacao-by-projeto-id'])
                              queryClient.resetQueries([projetoId, 'find-localizacao-by-projeto-id'])
                              setPositions(localizacao)
                            });
                          }
                        }
                      })
                    }
                  },
                  // isMulti: true as any
                }}
              />
            </div>
            <div className="mt-[24px]">
              <MapContainer bounds={bounds.length > 0 ? bounds : [[0.035574, -51.070534]]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {!localizacaoQuery.isLoading && localizacaoQuery.isSuccess && localizacoes.length > 0 && localizacoes.map(i => (
                  <Marker position={[i.latitude, i.longitude]} key={`${i.latitude}${i.longitude}`}>
                    <Popup>
                      {i.endereco}
                    </Popup>
                  </Marker>
                ))}
                <LocationMarker />
              </MapContainer>
            </div>
            <div className="mt-[24px]">
              <h4 className="text-dark-tint font-light text-sm">Locais afetados</h4>
              {!localizacaoQuery.isLoading && localizacaoQuery.isSuccess && localizacoes.length && localizacoes.map(i => (
                <p className="text-dark-base font-normal text-base">{i.endereco}</p>
              ))}
            </div>
          </div>
        </Card>
        <ToastContainer />
      </Dialog>
    </>
  )
}