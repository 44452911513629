import { useQuery } from "react-query";
import { allSecretaria } from "../../providers/secretaria";

export const useAllSecretaria = () => {
  return useQuery(['all-secretaria'], async () => {
    const response = await allSecretaria();

    let sorteArray = response.sort((a: any ,b: any) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))

    return sorteArray;
  });
}