// src/components/Pagination/index.tsx
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export default function Pagination({ currentPage, totalPages, onPageChange }: PaginationProps) {
  const maxPageNumbersToShow = 5;

  const getPaginationNumbers = () => {
    const pages = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    // Ajusta o início e o fim se estiver perto do início ou do fim
    if (currentPage <= 3) {
      endPage = 1 + (maxPageNumbersToShow - 1);
    }
    if (currentPage > totalPages - 3) {
      startPage = totalPages - (maxPageNumbersToShow - 1);
    }

    // Garante que o array não ultrapasse o total de páginas
    startPage = Math.max(1, startPage);
    endPage = Math.min(totalPages, endPage);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return { startPage, endPage, pages };
  };

  const { startPage, endPage, pages } = getPaginationNumbers();

  return (
    <div className="flex items-center justify-end gap-2 text-xs font-semibold">
      <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className="border border-gray-300 rounded-lg p-1.5 px-2.5">
        <FaCaretLeft />
      </button>

      {startPage > 1 && (
        <>
          <button onClick={() => onPageChange(1)} className="border border-gray-300 rounded-lg p-1.5 px-2.5">1</button>
          {startPage > 2 && <span>...</span>}
        </>
      )}

      {pages.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`border border-gray-300 rounded-lg p-1.5 px-2.5 ${currentPage === page ? 'bg-blue-button text-white' : ''}`}
        >
          {page}
        </button>
      ))}

      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && <span>...</span>}
          <button onClick={() => onPageChange(totalPages)} className="border border-gray-300 rounded-lg p-1.5 px-2.5">{totalPages}</button>
        </>
      )}

      <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} className="border border-gray-300 rounded-lg p-1.5">
        <FaCaretRight />
      </button>
    </div>
  );
}
