import { api } from "../services/api";

export type TEixo = {
  id: number;
  nome: string;
}

export async function allEixo(): Promise<TEixo[]> {
  try {
    const { data } = await api.get(`/eixos`);
    return data.data;
  } catch (err) {
    throw err;
  }
}