import { Button, Spinner } from "@material-tailwind/react";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import { BoxError } from "../../components/BoxError";
import { CardCrise } from "../../components/CardCrise";
import { CardProjeto } from "../../components/CardProjeto";
import { DialogFormSituacao } from "../../components/DialogFormSituacao";
import { EmptyState } from "../../components/EmptyState";
import { ReportPortfolio } from "../../components/Report/ReportPortolio";
import { useAllProjeto, useAllProjetoEstrategicoFavoritos } from "../../hooks/query/useProjetoQuery";
import { useAllSituacao } from "../../hooks/query/useSituacaoQuery";
import "./teste.css";


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsive2 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export function SalaCrise() {
  const navigate = useNavigate();
  const [openModalFormSituacao, setOpenModalFormSituacao] = useState(false);

  const allSituacaoQuery = useAllSituacao();
  const situacoes = allSituacaoQuery.data || [];

  const allProjetoQuery = useAllProjetoEstrategicoFavoritos();
  const projetos: any = allProjetoQuery.data || [];

  function handleOpenModalFormSituacao() {
    setOpenModalFormSituacao((prev) => !prev);
  }

  function handleGoProjeto() {
    navigate('/internal/projeto')
  }

  const { toPDF, targetRef } = usePDF({filename: 'portfolio.pdf'});

  const [hiddenPdf, setHiddenPdf] = useState('hidden')

  function handleToPdf() {
    setHiddenPdf('flex flex-col');

    setTimeout(() => {
      toPDF();
      setHiddenPdf('hidden')
    }, 500)
  }

  return (
    <div>
      <div className="flex w-full flex-row items-center justify-between mb-[24px]">
        <div>
          <p className="text-dark-base text-[42px]">Painel geral</p>
        </div>
        {/* <div className="flex flex-row">
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleToPdf}>Gerar relatório de portfólio</Button>
        </div> */}
      </div>

      <div className="w-full p-[24px] bg-white mb-[24px] border border-[#EBEBED] rounded-[4px]">
        <div className="flex flex-row items-center justify-between">
          <p className="text-[#27272E] text-lg">Sala de situação</p>
          <Button
            className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid"
            variant="outlined"
            onClick={handleOpenModalFormSituacao}
          >
            Nova situação
          </Button>
        </div>
        <div className="mt-[24px]">
          {!allSituacaoQuery.isLoading && allSituacaoQuery.isSuccess && (
            <>
              {situacoes.length > 0 ? (
                <Carousel
                  responsive={responsive2}
                  autoPlaySpeed={5000}
                  autoPlay
                  infinite
                  arrows={false}
                >
                  {situacoes.map((i) => (
                    <CardCrise key={String(i.id)} data={i} />
                  ))}
                </Carousel>
              ) : (
                <div className="w-full">
                  <EmptyState text="Nenhuma situação cadastrada" />
                </div>
              )}
            </>
          )}
          {allSituacaoQuery.isLoading && (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          )}
          {!allSituacaoQuery.isLoading && allSituacaoQuery.isError && (
            <div className="w-full">
              <BoxError text="Erro ao carregar as informações" />
            </div>
          )}
        </div>
      </div>

      <div className="w-full p-[24px] bg-white mb-[24px] border border-[#EBEBED] rounded-[4px]">
        <div className="flex flex-row items-center justify-between">
          <p className="text-[#27272E] text-lg">Projetos priorizados</p>
          <Button
            className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid"
            variant="outlined"
            onClick={handleGoProjeto}
          >
            Pesquisar
          </Button>
        </div>
        <div className="mt-[24px]">
          {!allProjetoQuery.isLoading && allProjetoQuery.isSuccess && (
            <>
              {projetos.length ? (
                <Carousel
                  responsive={responsive}
                  autoPlaySpeed={5000}
                  autoPlay
                  infinite
                  arrows={false}
                >
                  {projetos.map((i: any) => (
                      <CardProjeto key={String(i.id)} data={i} />
                  ))}
                </Carousel>
              ) : (
                <div className="w-full">
                  <EmptyState text="Nenhum projeto cadastrado" />
                </div>
              )}
            </>
          )}
          {allProjetoQuery.isLoading && (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          )}
          {!allProjetoQuery.isLoading && allProjetoQuery.isError && (
            <div className="w-full">
              <BoxError text="Erro ao carregar as informações" />
            </div>
          )}
        </div>
      </div>

      <div className="w-full p-[24px] bg-white mb-[24px] border border-[#EBEBED] rounded-[4px]">
        <div className="w-full h-[89vh]">
          <iframe
            title="PowerBI Report"
            width="100%"
            height="100%"
            src="https://app.powerbi.com/view?r=eyJrIjoiNTVjMmI4ODEtMDcxYS00MWRmLThiYjAtNDhjYTJjZWEzMTgyIiwidCI6ImJhNmIwMzJmLTU2YmUtNGRkNC1hZmJhLWQ1MTgzN2I1ZjNiNCJ9"
            allow="fullscreen"
          />
        </div>
      </div>

      <ReportPortfolio hiddenPdf={hiddenPdf} targetRef={targetRef}  />

      <DialogFormSituacao
        handleOpen={handleOpenModalFormSituacao}
        open={openModalFormSituacao}
      />
    </div>
  );
}
