import { useQuery } from "react-query";
import { findLocalizacaoByProjetoId, findLocalizacaoBySituacaoId, findLocalizacaoProjetos } from "../../providers/localizacao";


export const useFindLocalizacaoBySituacaoId = (idSituacao: number) => {
  return useQuery([idSituacao, 'find-localizacao-by-situcao-id'], async () => {
    const response = await findLocalizacaoBySituacaoId(idSituacao)
    return response
  });
}

export const useFindLocalizacaoByProjetoId = (idProjeto: number) => {
  return useQuery([idProjeto, 'find-localizacao-by-projeto-id'], async () => {
    const response = await findLocalizacaoByProjetoId(idProjeto)
    return response
  });
}

export const useFindLocalizacaoProjetos = () => {
  return useQuery(['find-localizacao-by-projetos'], async () => {
    const response = await findLocalizacaoProjetos()
    return response
  });
}
