import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { HiPlus, HiX } from "react-icons/hi";
import { useEffect, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useAllSecretaria } from "../../hooks/query/useSecretariaQuery";
import { useKeycloakGroups } from "../../hooks/query/useUsers";
import { EmptyState } from "../EmptyState";
import { IoIosCloseCircle } from "react-icons/io";
import { TUser, createUsuario, getKeycloakUsuarios, getUsuarioSecretaria, removeUsuarioSecretaria, updateUsuario, updateUsuarioSecretaria } from "../../providers/users";
import { useQueryClient } from "react-query";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from "../../hooks/UserContext";

type TBody = {
  email: string;
  nome: string;
  sobrenome: string;
}

type IProps = {
  open: boolean;
  handleOpen: () => void;
  usuario?: TUser;
}

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  email: yup.string().required('Campo obrigatório'),
  nome: yup.string().required('Campo obrigatório'),
  sobrenome: yup.string().required('Campo obrigatório')
})

type TGroupAndSecretaria = {
  secretariaId: number;
  grupo: string;
  grupoId: string;
  secretariaName: string;
  id?: number;
}

export function DialogFormUser({handleOpen, open, usuario = {} as TUser}: IProps) {
  const [isSave, setIsSave] = useState(false);
  const [keycloakId, setKeycloakId] = useState('');
  const [groupsAndSecretarias, setGroupsAndSecretarias] = useState<TGroupAndSecretaria[]>([]);
  const [groupVal, setGroupVal] = useState('');
  const [secretariaVal, setSecretariaVal] = useState('');
  const [secretariaRemove, setSecretariaRemove] = useState<number[]>([])
  const { user } = useUser();
 
  const secretariaQuery = useAllSecretaria();
  const secretarias = secretariaQuery.data || [];

  const keycloakGroupsQuery = useKeycloakGroups();
  const groups = keycloakGroupsQuery.data || [];

  const { ...methods } = useForm<TBody>({
    resolver: yupResolver(formSchema)
  });

  useEffect(() => {
    console.log(usuario)
    if (!!usuario.email) {
      methods.setValue('email', usuario.email)
    }

    if (!!usuario.nome) {
      methods.setValue('nome', usuario.nome)
    }

    if (!!usuario.sobrenome) {
      methods.setValue('sobrenome', usuario.sobrenome)
    }

    if (!!usuario.id) {
      initUsuarioSecretaria()
    }

  }, [usuario])

  async function initUsuarioSecretaria() {
    const response = await getUsuarioSecretaria(usuario.id);

    if (response.length > 0) {
      const us = [];
      for (let i = 0; i < response.length; i++) {
        us.push({
          id: response[i].id,
          grupo: response[i].grupo,
          grupoId: response[i].grupoId,
          secretariaName: response[i].secretaria?.nome,
          secretariaId: response[i].secretaria?.id,
        })
      }
      setGroupsAndSecretarias(us);
    }
  }

  const queryClient = useQueryClient();

  function handleDismiss() {
    methods.reset()
    setGroupVal('');
    setSecretariaVal('');
    setGroupsAndSecretarias([]);
    setSecretariaRemove([])
    handleOpen();
  }
  

  const onSubmit: SubmitHandler<TBody> = async (params) => {
    try {
      if (!groupsAndSecretarias.length) {
        toast('Informe pelo menos uma secretaria e um perfil', {type: 'error'});  
        return;
      }
      setIsSave(true)

      const body: any = params;

      if (!!keycloakId) {
        body.keycloakId = keycloakId;
      }

      if (usuario.keycloakId) {
        body.keycloakId = usuario.keycloakId;
      }

      // console.log(body)

      if (!!usuario.id) {
        // await updateUsuario(usuario.id, body);
        await handleSaveGroupSecretaria(usuario.id);
        toast('Usuário atualizado com sucesso', {type: 'success'});
      } else {
        const responseCreateUser = await createUsuario(body);
        await handleSaveGroupSecretaria(responseCreateUser.id);
        toast('Usuário criado com sucesso', {type: 'success'});
      }


      setIsSave(false)


      handleDismiss();

      queryClient.resetQueries(['all-users']);
      queryClient.invalidateQueries(['all-users']);

      if (user.email === params.email) {
        window.location.reload();
      }


    } catch (err) {
      toast('Erro ao criar usuário', {type: 'error'});
      setIsSave(false)
    }
  }

  const onError = async () => {

  }

  async function handleSaveGroupSecretaria(userId: number) {

    if (secretariaRemove.length > 0) {
      for (let i = 0; i < secretariaRemove.length; i++) {
        try {
          await removeUsuarioSecretaria(userId, secretariaRemove[i]);
        } catch (err) {
          continue;
        }
      }
    }

    for (let i = 0; i < groupsAndSecretarias.length; i++) {
      try {
        if (!groupsAndSecretarias[i].id) {
          await updateUsuarioSecretaria(userId, groupsAndSecretarias[i]);
        }
      } catch (err) {
        continue;
      }
    }
  }


  

  function handleAddGroupSecretaria() {
    if (!!groupVal && !!secretariaVal) {
      const gs = groupsAndSecretarias.find(item => item.grupoId === groupVal && Number(item.secretariaId) === Number(secretariaVal));
      
      if (gs) {
        toast('Já existe uma secretaria e perfil vinculado com esses valores escolhidos', {type: 'error'});
        return;
      } else {
        const nameGroup = groups.find(g => String(g.id) === groupVal);
        const nameSecretaria = secretarias.find(g => String(g.id) === secretariaVal);
  
        setGroupsAndSecretarias(prevState => [...prevState, {
          grupo: nameGroup?.nome || '',
          secretariaName: nameSecretaria?.nome || '',
          grupoId: groupVal,
          secretariaId: Number(secretariaVal)
        }])
  
        setGroupVal('')
        setSecretariaVal('')
      }

    }
  }

  function removeSecretariaGrupo(index: number) {
    if (groupsAndSecretarias[index] && !!groupsAndSecretarias[index].id && !!groupsAndSecretarias[index].secretariaId) {
      setSecretariaRemove(prev => [...prev, groupsAndSecretarias[index].secretariaId!])
    }

    const newArr = [...groupsAndSecretarias];
    newArr.splice(index, 1);
    setGroupsAndSecretarias(newArr);
  }

  async function handleSearchUserByEmail(val: string) {
    try {
      if (!!val) {
        const response = await getKeycloakUsuarios(val);

        if (!!response?.id) {
          methods.setValue('nome', response.nome)
          methods.setValue('sobrenome', response.sobrenome)
          setKeycloakId(response.id)
        } else {
          methods.setValue('nome', '')
          methods.setValue('sobrenome', '')
          setKeycloakId('')
        }

      } 
    } catch (err) {
      methods.setValue('nome', '')
      methods.setValue('sobrenome', '')
      setKeycloakId('')
    }
  }


 
  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleDismiss}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        {/* <DialogBody className="h-[42rem] overflow-scroll"> */}
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{!!usuario.id ? `Editar` : 'Novo'} usuário</h1>
            </div>
            <div>
              <button onClick={handleDismiss}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="flex flex-row items-center justify-between mt-[24px] gap-2">
              <Controller
                name="email"
                render={({field, fieldState}) => (
                  <Input label="E-mail" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} onBlur={() => handleSearchUserByEmail(field.value)}/>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px] gap-2">
              <Controller
                name="nome"
                render={({field, fieldState}) => (
                  <Input label="Nome" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              <Controller
                name="sobrenome"
                render={({field, fieldState}) => (
                  <Input label="Sobrenome" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="h-[1px] w-full mt-[24px] mb-[24px] bg-[#E4E4E4]"></div>
            <div>
              <h1 className="text-dark-base text-2xl font-semibold">Selecione secretaria e perfil</h1>
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px] gap-2">  
              {!secretariaQuery.isLoading && secretariaQuery.isSuccess && secretarias.length > 0 && (                
                <Select label="Secretaria" size="lg" className="border-solid" onChange={(ev) => setSecretariaVal(ev || '')} value={secretariaVal}>
                  {secretarias.map(e => (
                    <Option value={String(e.id)}>{e.nome}</Option>
                  ))}
                </Select>
              )}
              {!keycloakGroupsQuery.isLoading && keycloakGroupsQuery.isSuccess && groups.length > 0 && (                
                <Select label="Perfil" size="lg" className="border-solid" onChange={(ev) => setGroupVal(ev || '')} value={groupVal}>
                  {groups.map(e => (
                    <Option value={String(e.id)}>{e.nome}</Option>
                  ))}
                </Select>
              )}
              <div className="w-[10%]">

              <Button className=" text-white border-blue-button border-solid bg-blue-button items-center justify-center" onClick={handleAddGroupSecretaria}>
               <HiPlus />
              </Button>
              </div>
            </div>

            {groupsAndSecretarias.length > 0 ? (
              <div className="mt-[24px]">
                <div className="flex flex-row justify-between mb-[20px]">
                  <div className="w-[40%]">
                    <p className="font-semibold text-[16px]">Nome da instituição</p>
                  </div>
                  {/* <div className="w-[30%]">
                    <p className="font-semibold text-[16px]">CPF</p>
                  </div> */}
                  <div className="w-[40%]">
                    <p className="font-semibold text-[16px]">Perfil</p>
                  </div>
                  <div className="w-[20%]">
                    <p className="font-semibold text-[16px]">Opções</p>
                  </div>
                </div>
                {groupsAndSecretarias.map((i, ix) => (
                  <div className="flex flex-row justify-between py-[16px] border-b-[1px] border-b-[#E4E4E4] border-solid" key={i.secretariaId+i.grupoId}>
                    <div className="w-[40%]">
                      <p className="font-normal text-[16px]">{i.secretariaName}</p>
                    </div>
                    {/* <div className="w-[30%]">
                      <p className="font-normal text-[16px]">CPF</p>
                    </div> */}
                    <div className="w-[40%]">
                      <p className="font-normal text-[16px]">{i.grupo}</p>
                    </div>
                    <div className="w-[20%]">
                      <button onClick={() => removeSecretariaGrupo(ix)}>
                        <IoIosCloseCircle size={24} color="#B92A2D" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ): (
              <div className="mt-[24px]">
                <EmptyState text="Nenhum secretaria/perfil associado" />
              </div>
            )}

            

            <div className="mt-[24px] flex flex-row justify-end gap-2">            
              <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
              <Button 
                className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                variant="outlined" 
                disabled={isSave}
                onClick={methods.handleSubmit(onSubmit, onError)}>
                  {isSave ? <Spinner color="blue" /> : `${!!usuario.id ? `Editar` : 'Atualizar'} usuário`}
                </Button>
            </div>
          </FormProvider>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}