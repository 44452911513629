import { format, parseISO } from "date-fns";

export function formatDateStrToDateBr(date: string) {
  try {
    return format(parseISO(date), 'dd/MM/yyyy');
  } catch(err) {
    console.log(err)
    return ''
  }
}

export function formatDateString(date: string) {
  try {
    const splitDate = date.split('T');
    const splitDate2 = splitDate[0].split('-');
    return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
  } catch(err) {
    return ''
  }
}