import { Alert, Button, Spinner } from "@material-tailwind/react";
import { TablePlanoAcao } from "../../TablePlanoAcao";
import { DialogFormPlanoAcao } from "../../DialogFormPlanoAcao";
import { useState } from "react";
import { useFindPlanoAcaoBySituacaoId } from "../../../hooks/query/usePlanoAcaoQuery";
import { EmptyState } from "../../EmptyState";
import { BoxError } from "../../BoxError";
import { PlanoAcaoMobile } from "../../TablePlanoAcao/PlanoAcaoMobile";

type TProps = {
  situacaoId: number;
}

export function BoxPlanoAcao({ situacaoId }: TProps) {
  const [dialogForm, setDialogForm] = useState(false);

  const planoAcaoQuery = useFindPlanoAcaoBySituacaoId(situacaoId);
  const planoAcao = planoAcaoQuery.data || [];

  function handleDialogForm() {
    setDialogForm((prev) => !prev);
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-[16px]">
        <h1 className="text-dark-base text-lg font-normal">Plano ação</h1>
        <div className="flex flex-row items-center">
          <span className="mr-3">{(!planoAcaoQuery.isLoading && planoAcaoQuery.isSuccess && planoAcao.length)} planos de ação</ span>
          <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleDialogForm}>Adicionar</Button>
        </div>
      </div>
      {/* <TablePlanoAcao /> */}
      {!planoAcaoQuery.isLoading && planoAcaoQuery.isSuccess && (
        <>
          {planoAcao.length > 0 ? 
            (
              <div>
                <div className="hidden lg:block">
                  <TablePlanoAcao data={planoAcao} />
                </div>
                <div className="flex overflow-x-scroll pb-[16px] lg:hidden">
                  {planoAcao.map(imp => (
                    <PlanoAcaoMobile data={imp} />
                  ))}
                </div>
              </div>
            )
           : (<>
            <EmptyState text="Nenhum plano encontrado" />
          </>)}
        </>
      )}
      {planoAcaoQuery.isLoading && (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!planoAcaoQuery.isLoading && planoAcaoQuery.isError && (
        <div className="w-full">
          <BoxError text="Erro ao carregar as informações" />
        </div>
      )}

      <DialogFormPlanoAcao handleOpen={handleDialogForm} idSituacao={situacaoId} open={dialogForm} />
    </div>
  )
}