import { useQuery } from "react-query"
import { allPlanoAcao, allPlanoAcaoStatus, findPlanoAcaoByProjetoId, findPlanoAcaoBySituacaoId, allPlanoAcaoAgenda } from "../../providers/plano-acao";
import { findProjeto } from "../../providers/projeto";
import { findSituacao } from "../../providers/situacao";

export const useAllPlanoAcao = (page: number, term: string, secretariaId: number) => {
  return useQuery(['plano-acao'], async () => {
    const planos = await allPlanoAcao(page, term, secretariaId);

    if (planos?.content?.length > 0) {
      const status = await allPlanoAcaoStatus();

      const format: any[] = []
      for (let i = 0; i < planos.content.length; i++) {
        let origem = '';

        if (!!planos.content[i].projetoId) {
          console.log(9999999)
          const projeto: any = await findProjeto(planos.content[i].projetoId);
          origem = projeto.nome
        }

        if (!!planos.content[i].situacaoId) {
          const situacao: any = await findSituacao(planos.content[i].situacaoId);
          origem = situacao.nome
        }
        format.push({
          ...planos.content[i],
          status: status.find(s => s.id === planos.content[i].statusId),
          origem
        })
      }

      // const format = planos.content.map(async (i: any) => {

      //   let origem = '';

      //   if (!!i.projetoId) {
      //     console.log(9999999)
      //     const projeto = await findProjeto(i.projetoId);
      //     // origem = projeto.
      //   }

      //   return {
      //     ...i,
      //     status: status.find(s => s.id === i.statusId)
      //   }
      // })

      return format;
    }



    return [];
  }, {
    enabled: !!secretariaId
  });
}

export const useFindPlanoAcaoBySituacaoId = (idSituacao: number) => {
  return useQuery([idSituacao, 'find-plano-acao-by-situcao-id'], async () => await findPlanoAcaoBySituacaoId(idSituacao));
}

export const useFindPlanoAcaoByProjetoId = (idProjeto: number) => {
  return useQuery([idProjeto, 'find-plano-acao-by-projeto-id'], async () => await findPlanoAcaoByProjetoId(idProjeto));
}

export const useAllPlanoAcaoStatus = () => {
  return useQuery(['plano-acao-status'], async () => await allPlanoAcaoStatus());
}

export const useAllPlanoAcaoAgenda = (secretariaId: number) => {
  return useQuery(['plano-acao-agenda'], async () => {
    const planos = await allPlanoAcaoAgenda(secretariaId) as any;

    if (planos?.length > 0) {
      const format: any[] = []
      for (let i = 0; i < planos.length; i++) {
        let origem = '';

        if (!!planos[i].projetoId) {
          const projeto: any = await findProjeto(planos[i].projetoId);
          origem = projeto.nome
        }

        if (!!planos[i].situacaoId) {
          const situacao: any = await findSituacao(planos[i].situacaoId);
          origem = situacao.nome
        }
        format.push({
          ...planos[i],
          origem
        })
      }

      // const format = planos.map(async (i: any) => {

      //   let origem = '';

      //   if (!!i.projetoId) {
      //     console.log(9999999)
      //     const projeto = await findProjeto(i.projetoId);
      //     // origem = projeto.
      //   }

      //   return {
      //     ...i,
      //     status: status.find(s => s.id === i.statusId)
      //   }
      // })

      return format;
    }

  }, {
    enabled: !!secretariaId
  });
}