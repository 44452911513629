import { TPlanoAcao, atuacaoByPlanoAcaoId, removeAtuacaoPlanoAcao, removePlanoAcao, updatePlanoAcao } from "../../providers/plano-acao";
import { format } from "date-fns";
import { DialogFormPlanoAcao } from "../DialogFormPlanoAcao";
import { useState } from "react";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { DialogFormAcaoAtuacao } from "../DialogFormAcaoAtuacao";
import { useAllSecretaria } from "../../hooks/query/useSecretariaQuery";
import { DialogViewAcoesMobile } from "../DialogViewAcoesMobile";

type Props = {
  data: TPlanoAcao[];
  isSecretaria?: boolean;
}

export function GridPlanoAcaoMobile({ data, isSecretaria = false }: Props) {
  const [dialogForm, setDialogForm] = useState(false);
  const [dialogViewAcoesMobile, setDialogViewAcoesMobile] = useState(false);
  const [dialogFormAtuacao, setDialogFormAtuacao] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [currentPlanoAcao, setCurrentPlanoAcao] = useState<TPlanoAcao>({} as TPlanoAcao);
  const [ixActive, setIxActive] = useState<number>(-1);
  const [loadingAccordion, setLoadingAccordion] = useState(false);
  const [atuacoes, setAtuacoes] = useState<any[]>([1]);
  const [dialogRemoveAtuacao, setDialogRemoveAtuacao] = useState(false);
  const [atuacaoId, setAtuacaoId] = useState<number>()
  const [currentPlano, setCurrentPlano] = useState<TPlanoAcao>({} as TPlanoAcao);

  const allSecretariaQuery = useAllSecretaria();
  const secretarias = allSecretariaQuery.data || [];

  function handleSecretariaName(id: number = 0) {
    try {
      if (secretarias.length > 0) {
        const name = secretarias.find(s => s.id === id)?.nome;
        return name || id;
      }

      return id;
    } catch (err) {
      return id
    }
  }

  const [openAccordion, setOpenAccordion] = useState<number>()

  const queryClient = useQueryClient()

  function handleDialogForm() {
    setDialogForm((prev) => !prev);
  
  }
  async function handleDialogFormAtuacao() {
    setDialogFormAtuacao((prev) => !prev);
    if (openAccordion === currentPlanoAcao.id && dialogFormAtuacao) {
      setLoadingAccordion(true);
      setOpenAccordion(currentPlanoAcao.id);
      const response = await atuacaoByPlanoAcaoId(currentPlanoAcao.id);

      if (response.length > 0) {
        setAtuacoes(response)
      } else {
        setAtuacoes([])
      }

      setLoadingAccordion(false);
    }
  }

  async function handleDialogViewAcoesMobile() {
    setDialogViewAcoesMobile((prev) => !prev);
  }

  function handleDialogRemove() {
    setDialogRemove((prev) => !prev);
  }

  function handleDropdown(index: number) {
    setIxActive(prev => prev === index ? -1 : index)
  }

  const navigate = useNavigate();

  async function handleRemove() {
    try {

      setLoadingRemove(true)
      await removePlanoAcao(currentPlanoAcao.id);
      
      toast('Impacto excluído com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleDialogRemove()

      if (!!currentPlanoAcao.situacaoId) {
        queryClient.resetQueries([currentPlanoAcao.situacaoId, 'find-plano-acao-by-situcao-id']);
        queryClient.invalidateQueries([currentPlanoAcao.situacaoId, 'find-plano-acao-by-situcao-id']);
      }

      if (!!currentPlanoAcao.projetoId) {
        queryClient.resetQueries([currentPlanoAcao.projetoId, 'find-plano-acao-by-projeto-id']);
        queryClient.invalidateQueries([currentPlanoAcao.projetoId, 'find-plano-acao-by-projeto-id']);
      }

    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir plano de ação', {type: 'error'});
    }
  }

  function formatDate(date: string) {
    const dt = new Date(date);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

    try {
      return format(dtDateOnly, 'dd/MM/yyyy');
    } catch(err) {
      return ''
    }
  }

  function handleEdit(plano: TPlanoAcao) {
    setCurrentPlanoAcao(plano);
    handleDialogForm();
  }

  async function handleMonitorar(plano: TPlanoAcao) {
    try {
      await updatePlanoAcao(plano.id, {
        ...plano,
        monitorar: !plano.monitorar
      })
      toast('Plano de ação atualizado com sucesso', {type: 'success'});
      
      queryClient.resetQueries([plano.situacaoId, 'find-plano-acao-by-situcao-id']);
      queryClient.invalidateQueries([plano.situacaoId, 'find-plano-acao-by-situcao-id']);

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar plano de ação', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
    }
  }

  function handleNavigateOrigem(id?: number, isSituacao = false, isProjeto = false) {
    if (isSituacao && !!id) {
      navigate(`/internal/sala-situacao/${id}`)
      return;
    }
    
    if (isProjeto && !!id) {
      navigate(`/internal/projeto/${id}`)
      return;
    }

  }

  function formatStatusEnum(status = '') {
    if (status === 'A_INICIAR') {
      return 'A iniciar';
    }
    if (status === 'EM_EXECUCAO') {
      return 'Em execução';
    }
    if (status === 'CONCLUIDO') {
      return 'Concluído';
    }
    if (status === 'IMPEDIDO') {
      return 'Impedido';
    }
    if (status === 'PAUSADO') {
      return 'Pausado';
    }

    return '';
  }

  async function handleToggleAccordion(ix: number) {

    if (openAccordion === ix) {
      setOpenAccordion(undefined)
      setAtuacoes([])
    } else {
      setLoadingAccordion(true);
      setOpenAccordion(ix);
      const response = await atuacaoByPlanoAcaoId(ix);

      if (response.length > 0) {
        setAtuacoes(response)
      } else {
        setAtuacoes([])
      }

      setLoadingAccordion(false);
    }

  }

  async function handleRemoveDialogAtuacao() {
    setDialogRemoveAtuacao(prev => !prev);
  }

  async function handleRemoveAtuacao() {
    try { 
      setLoadingRemove(true);
      await removeAtuacaoPlanoAcao(atuacaoId!)
      const response = await atuacaoByPlanoAcaoId(currentPlanoAcao.id);

      if (response.length > 0) {
        setAtuacoes(response)
      } else {
        setAtuacoes([])
      }
      toast('Atuação excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleRemoveDialogAtuacao()
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', {type: 'error'});
    }
  }

  return (
    <div className="gap-2 flex flex-col">
      {data.length > 0 && data.map(d => (
        <div key={d.id} className="bg-[#F5F8FA] rounded-md px-4 py-6 flex flex-row" onClick={() => {
          setCurrentPlano(d)
          handleDialogViewAcoesMobile();
        }}>
          <div className="w-[70%]">
            <label className="text-sm font-light text-[#4A4C56]">Ações</label>
            <p className="text-dark-base font-normal text-base">{d.nome}</p>
          </div>
          <div className="w-[30%]">
            <label className="text-sm font-light text-[#4A4C56]">Prazo</label>
            <p className="text-dark-base font-normal text-base">{formatDate(d.prazoAcao)}</p>
          </div>
        </div>
      ))}
      
      <DialogFormPlanoAcao handleOpen={handleDialogForm} open={dialogForm} idSituacao={currentPlanoAcao.situacaoId || 0} idProjeto={currentPlanoAcao.projetoId || 0} planoAcao={currentPlanoAcao} />
      <DialogRemoveItem handleConfirm={handleRemove} handleOpen={handleDialogRemove} open={dialogRemove} loadingConfirm={loadingRemove} />
      <DialogFormAcaoAtuacao handleOpen={handleDialogFormAtuacao} open={dialogFormAtuacao} planoAcaoId={currentPlanoAcao.id} atuacaoId={atuacaoId} />
      <DialogViewAcoesMobile handleOpen={handleDialogViewAcoesMobile} open={dialogViewAcoesMobile} data={currentPlano} />
      <DialogRemoveItem handleOpen={handleRemoveDialogAtuacao} open={dialogRemoveAtuacao} handleConfirm={handleRemoveAtuacao} loadingConfirm={loadingRemove} />
    </div>
  )
}
