import { Button } from "@material-tailwind/react";
import { BoxImpacto } from "../../components/BoxImpacto";
import { BoxEstrategia } from "../../components/BoxEstrategia";
import { BoxProjeto } from "../../components/BoxProjeto";
import { BoxResumoSalaSituacao } from "../../components/BoxResumoSalaSituacao";
import { BoxCenario } from "../../components/BoxCenario";
import { useNavigate, useParams } from "react-router-dom";
import { BoxPlanoAcao } from "../../components/SalaSituacao/BoxPlanoAcao";
import { BoxSolucao } from "../../components/SalaSituacao/BoxSolucao";
import { DialogFormSituacao } from "../../components/DialogFormSituacao";
import { useRef, useState } from "react";
import { ReportSituacao } from "../../components/Report/ReportSituacao";
import { usePDF } from "react-to-pdf";
import { FaChevronRight } from "react-icons/fa";
import { Breadcrumb } from "../../components/SalaSituacao/Breadcrumb";
import { HiChevronLeft } from "react-icons/hi";
import { pdf } from "@react-pdf/renderer";
import { ReportListProjeto } from "../../components/Report/ReportListProjeto";
import { saveAs } from 'file-saver';
import { ReportSalaSituacao } from "../../components/Report/ReportSalaSituacao";
import { findSituacao } from "../../providers/situacao";
import { findImpactoBySituacaoId } from "../../providers/impacto";
import { findSolucaoBySituacaoId, findSolucaoBySituacaoIdReport } from "../../providers/solucao-inicial";
import { findCenariosBySituacaoId } from "../../providers/cenario";
import { findEstrategiaBySituacaoId } from "../../providers/estrategia";
import { findPlanoAcaoBySituacaoId } from "../../providers/plano-acao";
import { findLocalizacaoBySituacaoId } from "../../providers/localizacao";

export function SalaSituacao() {
  const {id} = useParams<any>();
  const [openModalFormSituacao, setOpenModalFormSituacao] = useState(false);

  const navigate = useNavigate();

  const { toPDF, targetRef } = usePDF({filename: 'situacao.pdf'});

  const [hiddenPdf, setHiddenPdf] = useState('hidden')

  function handleToPdf() {
    setHiddenPdf('flex flex-col');
    
    setTimeout(() => {
      toPDF();
      setHiddenPdf('hidden')
    }, 500)
  }

  function handleOpenModalFormSituacao() {
    setOpenModalFormSituacao((prev) => !prev);
  }

  const acaoRef = useRef<any>(null);

  function scrollToAcao() {
    acaoRef.current?.scrollIntoView()   
  }

  const impactoRef = useRef<any>(null);

  function scrollToImpacto() {
    impactoRef.current?.scrollIntoView()   
  }

  const medidaRef = useRef<any>(null);

  function scrollToMedida() {
    medidaRef.current?.scrollIntoView()   
  }

  const cenarioRef = useRef<any>(null);

  function scrollToCenario() {
    cenarioRef.current?.scrollIntoView()   
  }

  const estrategiaRef = useRef<any>(null);

  function scrollToEstrategia() {
    estrategiaRef.current?.scrollIntoView()   
  }

  const projetoRef = useRef<any>(null);

  function scrollToProjeto() {
    projetoRef.current?.scrollIntoView()   
  }

  const resumoRef = useRef<any>(null);

  function scrollToResumo() {
    resumoRef.current?.scrollIntoView()   
  }

  const [loadingReport, setLoadingReport] = useState(false)

  async function handleReport() {
    setLoadingReport(true);

    const situacaoReport =  await findSituacao(Number(id)) as any;

    const impactoReport = await findImpactoBySituacaoId(Number(id))

    const solucaoReport = await findSolucaoBySituacaoIdReport(Number(id));

    const cenarioReport = await findCenariosBySituacaoId(Number(id));

    const estrategiasReport = await findEstrategiaBySituacaoId(Number(id))
    
    const planosAcaoReport = await findPlanoAcaoBySituacaoId(Number(id))

    const localizacaoReport = await findLocalizacaoBySituacaoId(Number(id))

    console.log(localizacaoReport);

    const nomeLocalizacao = (localizacaoReport && localizacaoReport.length > 0) ? localizacaoReport[0].municipio : '-'

    const blob = await pdf(<ReportSalaSituacao situacao={situacaoReport} impactos={impactoReport} solucoes={solucaoReport} cenarios={cenarioReport} estrategias={estrategiasReport} planos={planosAcaoReport} nomeLocalizacao={nomeLocalizacao} />).toBlob()
    saveAs(blob, 'sala-situacao.pdf')

    setLoadingReport(false)
  }

  return (
    <div>
      <div className="flex flex-row items-center mb-[24px] cursor-pointer" onClick={() => navigate('/internal/sala-situacao')}>
        <HiChevronLeft size={22} color="#3A57F2" />
        <h1 className="font-normal text-base text-[#3A57F2]">Voltar</h1>
      </div>
      <div className="flex-row items-center justify-between mb-[24px] hidden lg:flex">
        <div>
          <h1 className="text-[42px] text-dark-base">Sala de Situação</h1>
        </div>
        <div className="flex flex-row hidden lg:flex">
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleReport} disabled={loadingReport}>
            {loadingReport ? 'Carregando...' : 'Gerar relatório' }
          </Button>
          <div className="w-[16px]" />
          <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleOpenModalFormSituacao}>Nova situação</Button>
        </div>
      </div>
      <div className="w-full p-[24px] bg-white rounded-[8px]">
        <div ref={resumoRef}>
          <div className="hidden lg:block">
            <Breadcrumb 
              scrollToAcao={scrollToAcao} 
              scrollToImpacto={scrollToImpacto} 
              scrollToMedida={scrollToMedida} 
              scrollToCenario={scrollToCenario} 
              scrollToEstrategia={scrollToEstrategia} 
              scrollToProjeto={scrollToProjeto}
              scrollToResumo={scrollToResumo}
              boxActive="Resumo" 
            />
          </div>
          <BoxResumoSalaSituacao id={Number(id)} />
        </div>
        <div ref={impactoRef}>
          <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[32px]"></div>
          <div className="hidden lg:block">
            <Breadcrumb 
              scrollToAcao={scrollToAcao} 
              scrollToImpacto={scrollToImpacto} 
              scrollToMedida={scrollToMedida} 
              scrollToCenario={scrollToCenario} 
              scrollToEstrategia={scrollToEstrategia} 
              scrollToProjeto={scrollToProjeto}
              scrollToResumo={scrollToResumo}
              boxActive="Impacto" 
            />
          </div>
          <BoxImpacto situacaoId={Number(id)} />
        </div>
        <div ref={medidaRef}>
          <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[32px]"></div>
          <div className="hidden lg:block">
            <Breadcrumb 
              scrollToAcao={scrollToAcao} 
              scrollToImpacto={scrollToImpacto} 
              scrollToMedida={scrollToMedida} 
              scrollToCenario={scrollToCenario} 
              scrollToEstrategia={scrollToEstrategia} 
              scrollToProjeto={scrollToProjeto}
              scrollToResumo={scrollToResumo}
              boxActive="Medidas iniciais" 
            />
          </div>
          <BoxSolucao situacaoId={Number(id)} />
        </div>
        <div ref={cenarioRef}>
          <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[32px]"></div>
          <div className="hidden lg:block">
            <Breadcrumb 
              scrollToAcao={scrollToAcao} 
              scrollToImpacto={scrollToImpacto} 
              scrollToMedida={scrollToMedida} 
              scrollToCenario={scrollToCenario} 
              scrollToEstrategia={scrollToEstrategia} 
              scrollToProjeto={scrollToProjeto}
              scrollToResumo={scrollToResumo}
              boxActive="Cenários" 
            />
          </div>
          <BoxCenario id={Number(id)} />
        </div>
        <div ref={estrategiaRef}>
          <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[32px]"></div>
          <div className="hidden lg:block">
            <Breadcrumb 
              scrollToAcao={scrollToAcao} 
              scrollToImpacto={scrollToImpacto} 
              scrollToMedida={scrollToMedida} 
              scrollToCenario={scrollToCenario} 
              scrollToEstrategia={scrollToEstrategia} 
              scrollToProjeto={scrollToProjeto}
              scrollToResumo={scrollToResumo}
              boxActive="Estratégias" 
            />
          </div>
          <BoxEstrategia idSituacao={Number(id)} />
        </div>
        <div ref={projetoRef}>
          <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[32px]"></div>
          <div className="hidden lg:block">
            <Breadcrumb 
              scrollToAcao={scrollToAcao} 
              scrollToImpacto={scrollToImpacto} 
              scrollToMedida={scrollToMedida} 
              scrollToCenario={scrollToCenario} 
              scrollToEstrategia={scrollToEstrategia} 
              scrollToProjeto={scrollToProjeto}
              scrollToResumo={scrollToResumo}
              boxActive="Projetos" 
            />
          </div>
          <BoxProjeto id={Number(id)}/>
        </div>
        <div ref={acaoRef}>
          <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[32px]"></div>
          <div className="hidden lg:block">
            <Breadcrumb 
              scrollToAcao={scrollToAcao} 
              scrollToImpacto={scrollToImpacto} 
              scrollToMedida={scrollToMedida} 
              scrollToCenario={scrollToCenario} 
              scrollToEstrategia={scrollToEstrategia} 
              scrollToProjeto={scrollToProjeto}
              scrollToResumo={scrollToResumo}
              boxActive="Ações" 
            />
          </div>
          <BoxPlanoAcao situacaoId={Number(id)} />
        </div>
      </div>

      <ReportSituacao situacaoId={Number(id)} hiddenPdf={hiddenPdf} targetRef={targetRef} />

      <DialogFormSituacao
        handleOpen={handleOpenModalFormSituacao}
        open={openModalFormSituacao}
      />
    </div>
  )
}