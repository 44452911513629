import { useEffect, useState } from "react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { DialogFormCenario } from "../DialogFormCenario";
import { TCenario, removeCenario } from "../../providers/cenario";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";

type TProps = {
  data: TCenario;
}

export function CardCenario({ data }: TProps) {
  const [openDialogRemove, setOpenDialogRemove] = useState(false);
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [cenarioEdit, setCenarioEdit] = useState<TCenario>({} as TCenario);
  const queryClient = useQueryClient();

  function handleOpenDialogRemove() {
    setOpenDialogRemove(prev => !prev)
  }

  function handleOpenDialogForm() {
    setOpenDialogForm(prev => !prev)
  }

  function handleEditForm() {
    setCenarioEdit(data);
    setOpenDialogForm(prev => !prev)
  }

  async function handleRemove() {
    try { 
      setLoadingRemove(true);
      await removeCenario(data.id)
      await queryClient.resetQueries([data.situacaoId, 'find-cenario-by-situacao-id']);
      await queryClient.invalidateQueries([data.situacaoId, 'find-cenario-by-situacao-id']);
      toast('Cenário excluído com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleOpenDialogForm()
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', {type: 'error'});
    }
  }

  useEffect(() => {
    if (!openDialogForm) {
      setCenarioEdit({} as TCenario);
    }
  }, [openDialogForm])

  return (
    <>
      <div className="p-[16px] border border-[#DFDFDF] w-[300px] relative pt-[56px] mr-[16px]">
        <div className="absolute top-[16px]">
          {data.expectativaEnum === 'OTIMISTA' && (
            <div className="flex h-[24px] px-[16px] items-center justify-between border border-green-card text-green-card bg-green-card-opacity rounded-full text-xs font-semibold">
              <h1>Otimista</h1>
            </div>
          )}
          {data.expectativaEnum === 'PESSIMISTA' && (
            <div className="flex h-[24px] px-[16px] items-center justify-between border border-red-card text-red-card bg-red-card-opacity rounded-full text-xs font-semibold">
              <h1>Pessimista</h1>
            </div>
          )}
          {data.expectativaEnum === 'NEUTRO' && (
            <div className="flex h-[24px] px-[16px] items-center justify-between border border-neutral-card text-neutral-card bg-neutral-card-opacity rounded-full text-xs font-semibold">
              <h1>Neutro</h1>
            </div>
          )}
        </div>
        <div className="mb-[4px]">
          <h1 className="text-dark-tint text-xs font-semibold">Evento/condição</h1>
        </div>
        <div>
          <p className="text-xs font-normal text-dark-tint">{data.evento}</p>
        </div>
        <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[16px]"></div>
        <div className="mb-[4px]">
          <h1 className="text-dark-tint text-xs font-semibold">Consequência</h1>
        </div>
        <div>
          <p className="text-xs font-normal text-dark-tint">{data.consequencia}</p>
        </div>
        <div className="flex flex-row items-center mt-[14px]">
          <button className="mr-[16px]" onClick={handleOpenDialogRemove}>
            <HiOutlineTrash color="#0062FF" size={24} />
          </button>
          <button>
            <HiOutlinePencil color="#0062FF" size={24} onClick={handleEditForm}/>
          </button>
          </div>
      </div>
      <DialogRemoveItem handleOpen={handleOpenDialogRemove} open={openDialogRemove} handleConfirm={handleRemove} loadingConfirm={loadingRemove} />
      <DialogFormCenario handleOpen={handleOpenDialogForm} open={openDialogForm} cenario={cenarioEdit} situacaoId={data.situacaoId} />
    </>
  )
}