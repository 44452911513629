import { ReactComponent as SpeedometerSvg } from "../../../assets/icons/speedometer.svg";
import { ReactComponent as ThermometerSvg } from "../../../assets/icons/thermometer.svg";
import { ReactComponent as PinangleSvg } from "../../../assets/icons/pinangle.svg";

import { usePDF } from 'react-to-pdf';
import { Button } from "@material-tailwind/react";
import { BoxImpacto } from "../BoxImpacto";
import { BoxSolucao } from "../BoxSolucao";
import { BoxCenario } from "../BoxCenario";
import { BoxEstrategia } from "../BoxEstrategia";
import { BoxPlanoAcao } from "../BoxPlanoAcao";
import { useState } from "react";
import { useSituacao } from "../../../hooks/query/useSituacaoQuery";
import { useFindImpactoBySituacaoId } from "../../../hooks/query/useImpactoQuery";
import { useFindSolucaBySituacaoId } from "../../../hooks/query/useSolucaoQuery";
import { useFindCenarioBySituacaoId } from "../../../hooks/query/useCenarioQuery";
import { useFindEstrategiaBySituacaoId } from "../../../hooks/query/useEstrategiaQuery";

type Props = {
  hiddenPdf: string;
  targetRef: any;
  situacaoId: number
}

export function ReportSituacao({targetRef,hiddenPdf,situacaoId}: Props) {

  const situacaoQuery = useSituacao(situacaoId);
  const situacao = situacaoQuery.data;

  const impactoQuery = useFindImpactoBySituacaoId(situacaoId);
  const impactos = impactoQuery.data || [];

  const solucaoQuery = useFindSolucaBySituacaoId(situacaoId);
  const solucoes = solucaoQuery.data || [];

  const cenarioQuery = useFindCenarioBySituacaoId(situacaoId);
  const cenarios = cenarioQuery.data || [];

  const estrategiaQuery = useFindEstrategiaBySituacaoId(situacaoId);
  const estrategias = estrategiaQuery.data || [];

  return (
    <div>      
      {/* <div>
        <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={handleToPdf}>Gerar relatório</Button>
      </div> */}

      <div ref={targetRef}>
        <div className={`bg-[#F8FAFC] ${hiddenPdf}`}>
          <div className='w-full h-[16px] bg-[#0062FF]'></div>
          <div className='p-[32px]'>
            <div className="mb-[60px]">
              <h1 className='text-6xl font-bold'>Informações Gerais</h1>
            </div>
            <div className='grid grid-cols-2'>
              <div className='flex flex-row items-center'>
                <SpeedometerSvg width={100} height={100} />
                <div className="ml-[16px]">
                  <label className="text-dark-tint text-xl font-light">Frequência da ocorrência</label>
                  <h1 className="text-dark-base text-3xl font-semibold">
                    {situacao?.frequenciaOcorrenciaEnum.charAt(0).toUpperCase() + situacao?.frequenciaOcorrenciaEnum.slice(1).toLowerCase() || '-'}
                  </h1>
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <ThermometerSvg width={100} height={100} />
                <div className="ml-[16px]">
                  <label className="text-dark-tint text-xl font-light">Gravidade da ocorrência</label>
                  <h1 className="text-dark-base text-3xl font-semibold">
                    {situacao?.gravidadeOcorrenciaEnum.charAt(0).toUpperCase() + situacao?.gravidadeOcorrenciaEnum.slice(1).toLowerCase() || '-'}
                  </h1>
                </div>
              </div>
            </div>
            <div className='mt-[16px] grid grid-cols-2'>
              <div className='flex flex-row items-center'>
                <SpeedometerSvg width={100} height={100} />
                <div className="ml-[16px]">
                  <label className="text-dark-tint text-xl font-light">Tipo de crise</label>
                  <h1 className="text-dark-base text-3xl font-semibold">
                    {situacao?.tipoCriseEnum.charAt(0).toUpperCase() + situacao?.tipoCriseEnum.slice(1).toLowerCase() || '-'}
                  </h1>
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <PinangleSvg width={100} height={100} />
                <div className="ml-[16px]">
                  <label className="text-dark-tint text-xl font-light">Região</label>
                  <h1 className="text-dark-base text-3xl font-semibold">-</h1>
                </div>
              </div>
            </div>
            <div className="mt-[40px] mb-[20px]">
              <div className="flex flex-row items-center justify-between">
                <h1 className="text-dark-base text-4xl font-semibold">Impactos</h1>
                <div className="w-[80px] h-[80px] rounded-full  bg-[#DDEAFA]">
                  <h1 className="text-[#485FF7] font-semibold text-3xl mt-[12px] ml-[30px]">
                    {impactos.length}
                  </h1>
                </div>
              </div>
            </div>
            
            <BoxImpacto situacaoId={situacaoId} />
            
            <div className="h-[173px]" />

            
            
            
          </div>

          <div>
            <div className='w-full h-[16px] bg-[#0062FF]'></div>
            <div className="p-[32px]">
              <div className="flex flex-row items-center justify-between mb-[20px]">
                <h1 className="text-dark-base text-4xl font-semibold">Soluções e medidas iniciais</h1>
                <div className="w-[80px] h-[80px] rounded-full  bg-[#DDEAFA]">
                  <h1 className="text-[#485FF7] font-semibold text-3xl mt-[12px] ml-[30px]">{solucoes.length}</h1>
                </div>
              </div>
              <BoxSolucao situacaoId={situacaoId} />
            </div>

            <div className="h-[385px]" />

          </div>

          <div>
            <div className='w-full h-[16px] bg-[#0062FF]'></div>
              <div className="p-[32px]">
                <div className="flex flex-row items-center justify-between mb-[20px]">
                  <h1 className="text-dark-base text-4xl font-semibold">Cenários</h1>
                  <div className="w-[80px] h-[80px] rounded-full  bg-[#DDEAFA]">
                    <h1 className="text-[#485FF7] font-semibold text-3xl mt-[12px] ml-[30px]">{cenarios.length}</h1>
                  </div>
                </div>

              <BoxCenario id={situacaoId} />

              <div className="h-[410px]" />

            </div>
          </div>
          <div>
            <div className='w-full h-[16px] bg-[#0062FF]'></div>
              <div className="p-[32px]">
                <div className="flex flex-row items-center justify-between mb-[20px]">
                  <h1 className="text-dark-base text-4xl font-semibold">Estratégias de combate e prevenção</h1>
                  <div className="w-[80px] h-[80px] rounded-full  bg-[#DDEAFA]">
                    <h1 className="text-[#485FF7] font-semibold text-3xl mt-[12px] ml-[30px]">{estrategias.length}</h1>
                  </div>
                </div>

                
                <BoxEstrategia idSituacao={situacaoId} />
                
                <div className="h-[173px]" />

              </div>
            </div>

          <div>
            <div className='w-full h-[16px] bg-[#0062FF]'></div>
              <div className="p-[32px]">
                <div className="flex flex-row items-center justify-between mb-[40px]">
                  <h1 className="text-dark-base text-4xl font-semibold">Plano de ação</h1>
                </div>
                

                <BoxPlanoAcao situacaoId={situacaoId} />

              </div>
            </div>
          </div>
      </div>

    </div>
  )
}