import { Link } from "react-router-dom";
import DropdownUser from "./DropdownUser";
import logo from "../../images/logo.svg";
import { HiMenu } from "react-icons/hi";
import { useSidebar } from "../../hooks/SidebarContext";
import { Button, Drawer, IconButton, Typography } from "@material-tailwind/react";
import { useState } from "react";

const Header = () => {
  const {sidebarOpen, toggleSidebar} = useSidebar();

  const {toggleDrawer} = useSidebar();

  return (
    <header className="sticky top-0 z-999 flex w-full bg-white z-50">
      <div className="flex flex-grow items-center justify-between px-4 py-4 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          <button className="border border-dark-tint rounded p-[4px] mr-[4px]" onClick={() => toggleDrawer(true)}>
            <HiMenu size={26} />
          </button>

          <Link className="block flex-shrink-0 lg:hidden" to="/">
            <img
              src={logo}
              alt="Logo"
            />
          </Link>
        </div>

        <div className="items-center gap-2 sm:gap-4 hidden lg:flex">
          {!sidebarOpen && (
            <>
              <button className="border border-dark-tint rounded p-[4px] mr-[4px]" onClick={() => toggleSidebar(true)}>
                <HiMenu size={26} />
              </button>

              <Link className="hidden lg:block lg:flex-shrink-0" to="/">
                <img
                  src={logo}
                  alt="Logo"
                />
              </Link> 
            </>
          )}
        </div>

        <div className="hidden sm:block flex-[0.8]">
          {/* <form action="https://formbold.com/s/unique_form_id" method="POST">
            <div className="relative">
              <button className="absolute left-0 top-1/2 -translate-y-1/2 mx-[16px]">
                <FiSearch size={24} />
              </button>

              <input
                type="text"
                placeholder="Buscar..."
                className="w-full bg-transparent pl-12 pr-4 focus:outline-none border py-[8px] rounded-[8px] border-[#E4E4E4]"
              />
            </div>
          </form> */}
        </div>

        <div className="flex items-center gap-3 2xsm:gap-7">
          {/* <!-- User Area --> */}
          <DropdownUser />
          {/* <!-- User Area --> */}
        </div>
      </div>


    </header>
  );
};

export default Header;
