import { Button, Menu, MenuHandler, MenuItem, MenuList, Spinner, Typography } from "@material-tailwind/react";
import { FiChevronDown, FiPenTool, FiSearch } from "react-icons/fi";
import { HiChevronDown, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { DialogFormDemandaAtuacao } from "../DialogFormDemandaAtuacao";
import { useEffect, useState } from "react";
import { TAgendamento, TAtuacao, TResponseAllDemanda, deleteDemanda, deleteDemandaAgendamento, deleteDemandasAtuacao, demandasAgendamentoByIdDemanda, demandasAtuacaoByIdDemanda } from "../../providers/demandas";
import { format, parseISO } from "date-fns";
import { setValueMasks } from "../../utils/number.utils";
import { DialogViewDemanda } from "../DialoViewDemanda";
import { EmptyState } from "../EmptyState";
import { DialogFormDemandaAgendamento } from "../DialogFormDemandaAgendamento";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { DialogFormDemanda } from "../DialogFormDemanda";

type TProps = {
  demandas: TResponseAllDemanda[]
}

export function TableListaDemandas({demandas}: TProps) {
  const [dialogViewDemanda, setDialogViewDemanda] = useState(false);
  const [loadingAccordion, setLoadingAccordion] = useState(false);
  const [activeTab, setActiveTab] = useState("atuacao");
  const [dialogFormAtuacao, setDialogFormAtuacao] = useState(false);
  const [dialogFormAgendamento, setDialogFormAgendamento] = useState(false);
  const [selectIdDemanda, setSelectIdDemanda] = useState<number>(0)
  const [openAccordion, setOpenAccordion] = useState<number>()
  const [dialogFormEdit, setDialogFormEdit] = useState(false);

  const [dialogRemoveAtuacao, setDialogRemoveAtuacao] = useState(false);
  const [dialogRemoveAgendamento, setDialogRemoveAgendamento] = useState(false);
  const [dialogRemoveDemanda, setDialogRemoveDemanda] = useState(false);
  
  const [searchText, setTextSearch] = useState('');

  const [atuacoesDemanda, setAtuacoesDemanda] = useState<TAtuacao[]>([])
  const [agendamentosDemanda, setAgendamentosDemanda] = useState<TAgendamento[]>([])

  const [agendamentoId, setAgendamentoId] = useState<number>()
  const [atuacaoId, setAtuacaoId] = useState<number>()

  const [demandasSearch, setDemandasSearch] = useState<TResponseAllDemanda[]>(demandas);
  const [currentDemanda, setCurrentDemanda] = useState<TResponseAllDemanda>({} as TResponseAllDemanda);

  function handleDialogFormDemanda() {
    setDialogFormEdit(prev => !prev);
  }

  function handleDialogFormDemandaDelete() {
    setDialogRemoveDemanda(prev => !prev);
  }

  function formatDate(date: string) {
    try {
      const splitDate = date.split('T');
      const splitDate2 = splitDate[0].split('-');
      return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
    } catch(err) {
      return ''
    }
  }


  useEffect(() => {
    if (!!searchText) {
      const result = demandas.filter(
        (item) =>
          item.nome?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.comarca?.toLowerCase().includes(searchText.toLowerCase())
      );
      setDemandasSearch(result)
    } else {
      setDemandasSearch(demandas)
    }
  }, [searchText])

  function formatStatusEnum(status = '') {
    if (status === 'A_INICIAR') {
      return 'A iniciar';
    }
    if (status === 'EM_EXECUCAO') {
      return 'Em execução';
    }
    if (status === 'CONCLUIDO') {
      return 'Concluído';
    }
    if (status === 'IMPEDIDO') {
      return 'Impedido';
    }
    if (status === 'PAUSADO') {
      return 'Pausado';
    }

    return '';
  }

  function formatRiscoEnum(status = '') {
    if (status === 'BAIXO') {
      return 'Baixo';
    }
    if (status === 'MEDIO') {
      return 'Médio';
    }
    if (status === 'ALTO') {
      return 'Alto';
    }

    return '';
  }

  function handleDialogViewDemanda() {
    setDialogViewDemanda(prev => !prev);
  }
  async function handleDialogFormDemandaAtuacao() { 
    setDialogFormAtuacao(prev => !prev);
    if (openAccordion === selectIdDemanda && dialogFormAtuacao) {
      setLoadingAccordion(true);
      setOpenAccordion(selectIdDemanda);
      const response = await demandasAtuacaoByIdDemanda(selectIdDemanda);

      if (response.length > 0) {
        setAtuacoesDemanda(response)
      } else {
        setAtuacoesDemanda([])
      }

      setLoadingAccordion(false);
    }
  }

  async function handleDialogFormAgendamento() { 
    setDialogFormAgendamento(prev => !prev);
    if (openAccordion === selectIdDemanda && dialogFormAgendamento) {
      setLoadingAccordion(true);
      setOpenAccordion(selectIdDemanda);
      const response = await demandasAgendamentoByIdDemanda(selectIdDemanda);


      if (response.length > 0) {
        setAgendamentosDemanda(response)
      } else {
        setAgendamentosDemanda([])
      }

      setLoadingAccordion(false);
    }
  }


  async function handleToggleAccordion(ix: number) {

    setActiveTab('atuacao')

    if (openAccordion === ix) {
      setOpenAccordion(undefined)
      setAtuacoesDemanda([])
    } else {
      setLoadingAccordion(true);
      setOpenAccordion(ix);
      const response = await demandasAtuacaoByIdDemanda(ix);
      const responseAgendamento = await demandasAgendamentoByIdDemanda(ix);

      if (response.length > 0) {
        setAtuacoesDemanda(response)
      } else {
        setAtuacoesDemanda([])
      }

      if (responseAgendamento.length > 0) {
        setAgendamentosDemanda(responseAgendamento)
      } else {
        setAgendamentosDemanda([])
      }

      setLoadingAccordion(false);
    }

  }

  function handleEditAgendamento(id: number) {
    setAgendamentoId(id)
    handleDialogFormAgendamento()
  }

  function handleEditAtuacao(id: number) {
    setAtuacaoId(id)
    handleDialogFormDemandaAtuacao()
  }
  
  async function handleRemoveDialogAtuacao() {
    setDialogRemoveAtuacao(prev => !prev);
  }

  async function handleRemoveDialogAgendamento() {
    setDialogRemoveAgendamento(prev => !prev);
  }

  const [loadingRemove, setLoadingRemove] = useState(false);

  async function handleRemoveAtuacao() {
    try { 
      setLoadingRemove(true);
      await deleteDemandasAtuacao(atuacaoId!)
      const response = await demandasAtuacaoByIdDemanda(selectIdDemanda);

      if (response.length > 0) {
        setAtuacoesDemanda(response)
      } else {
        setAtuacoesDemanda([])
      }
      toast('Atuação excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleRemoveDialogAtuacao()
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', {type: 'error'});
    }
  }

  async function handleRemoveDemanda() {
    try { 
      setLoadingRemove(true);
      await deleteDemanda(selectIdDemanda)
      toast('Demanda excluída com sucesso', {type: 'success'});
      queryClient.resetQueries(['all-demanda']);
      queryClient.invalidateQueries(['all-demanda']);
      setLoadingRemove(false);
      handleDialogFormDemandaDelete()
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir demanda', {type: 'error'});
    }
  }

  function handleModalEdit(dem: TResponseAllDemanda) {
    setCurrentDemanda(dem)
    handleDialogFormDemanda()
  }

  const queryClient = useQueryClient();

  async function handleRemoveAgendamento() {
    try { 
      setLoadingRemove(true);
      await deleteDemandaAgendamento(agendamentoId!)
      const response = await demandasAgendamentoByIdDemanda(selectIdDemanda);

      if (response.length > 0) {
        setAgendamentosDemanda(response)
      } else {
        setAgendamentosDemanda([])
      }
      toast('Agendamento excluída com sucesso', {type: 'success'});
      queryClient.resetQueries(['all-demanda-agendamento']);
      queryClient.invalidateQueries(['all-demanda-agendamento']);
      setLoadingRemove(false);
      handleRemoveDialogAgendamento()
    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir cenário', {type: 'error'});
    }
  }

  return (
    <>
       <div className="mt-[24px]">
          <div className="flex flex-row">
            <div className="relative flex-1">
              <button className="absolute left-0 top-1/2 -translate-y-1/2 mx-[16px]">
                <FiSearch size={24} />
              </button>

              <input
                type="text"
                placeholder="Buscar..."
                onChange={(e) => setTextSearch(e.target.value)}
                className="w-full bg-transparent pl-12 pr-4 focus:outline-none border py-[8px] rounded-[8px] border-[#E4E4E4] bg-white"
              />
            </div>
            <div className="ml-[16px]">
              <Button className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined" onClick={() => {}}>Pesquisar</Button>
            </div>
          </div>
        </div>
        <div className="mt-[24px]">
          {demandasSearch.length > 0 && demandasSearch.map((d, ix) => (
            <div key={d.id} className="">
              <div className={`flex flex-row items-center pt-[16px] ${openAccordion === d.id ? 'bg-[#F5F8FA]' : 'bg-white'}`}>
                <div className="pr-4" onClick={() => handleToggleAccordion(d.id)}>
                  <HiChevronDown size={32} />
                </div>
                <div className="flex-1 cursor" onClick={() => handleToggleAccordion(d.id)}>
                  <div>
                    <h1 className="text-base font-medium">{d.nome}</h1>
                  </div>
                  <div className="mt-[8px] flex flex-row cursor-pointer">
                    <div className="w-[20%]">
                      <label className="font-light text-xs text-dark-tint">Risco</label>
                      <p className="font-normal text-sm text-dark-base">{formatRiscoEnum(d.riscoEnum)}</p>
                    </div>
                    <div className="w-[30%]">
                      <label className="font-light text-xs text-dark-tint">Comarca</label>
                      <p className="font-normal text-sm text-dark-base">{d.comarca}</p>
                    </div>
                    <div className="w-[20%]">
                      <label className="font-light text-xs text-dark-tint">Estimativa de julgamento e execução</label>
                      <p className="font-normal text-sm text-dark-base">{!!d.estimativa ? formatDate(d.estimativa) : '-'}</p>
                    </div>
                  </div>
                  <div className="mt-[8px] flex flex-row">
                    <div className="w-[20%]">
                      <label className="font-light text-xs text-dark-tint">Valor</label>
                      <p className="font-normal text-sm text-dark-base">R$ {setValueMasks(d.valor || 0)}</p>
                    </div>
                    <div className="w-[30%]">
                      <label className="font-light text-xs text-dark-tint">Número/registro do processo</label>
                      <p className="font-normal text-sm text-dark-base">{d.registro}</p>
                    </div>
                    <div className="w-[20%]">
                      <label className="font-light text-xs text-dark-tint">Parte adversa</label>
                      <p className="font-normal text-sm text-dark-base">{d.parteAdversa}</p>
                    </div>
                    <div className="w-[20%]">
                      <label className="font-light text-xs text-dark-tint">Status</label>
                      <p className="font-normal text-sm text-dark-base">{formatStatusEnum(d.statusEnum)}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <button className="mr-[16px]" onClick={() => {
                    setSelectIdDemanda(d.id)
                    handleDialogFormDemandaDelete()
                  }}>
                    <HiOutlineTrash color="#0062FF" size={24} />
                  </button>
                  <button className="mr-[16px]">
                    <HiOutlinePencil color="#0062FF" size={24} onClick={() => handleModalEdit(d)}/>
                  </button>
                  <Menu>
                    <MenuHandler>
                      <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid rounded-full bg-white" variant="outlined" >
                        <div className="flex flex-row items-center gap-2">
                          <p>Adicionar</p>
                          <FiChevronDown />
                        </div>
                      </Button>
                    </MenuHandler>
                    <MenuList>
                      <MenuItem onClick={() => {
                        setSelectIdDemanda(d.id)
                        setAtuacaoId(undefined)
                        handleDialogFormDemandaAtuacao();
                      }}>Atuação</MenuItem>
                      <MenuItem onClick={() => {
                        setSelectIdDemanda(d.id)
                        setAgendamentoId(undefined)
                        handleDialogFormAgendamento();
                      }}>Agendamento</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
              {openAccordion === d.id && (
                <div className="p-[16px] bg-[#F5F8FA]">
                  <div className="p-[16px] bg-white rounded-lg">
                    <div className="w-full border-b border-b-[#E0E2E7] flex flex-row items-center">
                      <div 
                        onClick={() => setActiveTab('atuacao')}
                        className={`pb-[10px] w-fit	cursor-pointer ${activeTab === 'atuacao' && `border-b-[2px] border-b-blue-button`}`}
                      >
                        <Typography className={`${activeTab === 'atuacao' ? `text-blue-button font-semibold` : 'text-[#667085] font-normal'}`}>Atuação</Typography>
                      </div>
                      <div className="w-[24px]" />
                      <div 
                        onClick={() => setActiveTab('agendamento')}
                        className={`pb-[10px] w-fit	cursor-pointer ${activeTab === 'agendamento' && `border-b-[2px] border-b-blue-button`}`}
                      >
                        <Typography className={`${activeTab === 'agendamento' ? `text-blue-button font-semibold` : 'text-[#667085] font-normal'}`}>Agendamento</Typography>
                      </div>
                    </div>

                    {loadingAccordion ? (
                      <div className="flex items-center justify-center mt-[16px]">
                        <Spinner />
                      </div>
                    ) : (
                      <div className="mt-[16px]">
                        {activeTab === 'atuacao' && (
                          <div>
                            {atuacoesDemanda.length > 0 && atuacoesDemanda.map(ad => (
                              <div key={ad.id} className="w-full flex flex-row mb-[8px] group">
                                <div className="w-[50%]">
                                  <label className="font-light text-xs text-dark-tint">Atuação</label>
                                  <p className="font-normal text-sm text-dark-base">{ad.descricao}</p>
                                </div>
                                <div className="w-[20%]">
                                  <label className="font-light text-xs text-dark-tint">Data</label>
                                  <p className="font-normal text-sm text-dark-base">{formatDate(ad.dataCriacao)}</p>
                                </div>
                                <div className="w-[20%] flex flex-row">
                                  <div>
                                    <label className="font-light text-xs text-dark-tint">Usuário</label>
                                    <p className="font-normal text-sm text-dark-base">{ad.autor}</p>
                                  </div>
                                </div>
                                <div className="w-[10%] flex flex-row items-center justify-center">
                                  <div className="hidden group-hover:inline-block">
                                   <button className="mr-[16px]" onClick={() => {
                                    setSelectIdDemanda(d.id)
                                    setAtuacaoId(ad.id);
                                    handleRemoveDialogAtuacao()
                                   }}>
                                      <HiOutlineTrash color="#0062FF" size={24} />
                                    </button>
                                    <button>
                                      <HiOutlinePencil color="#0062FF" size={24} onClick={() => {
                                        setSelectIdDemanda(d.id)
                                        handleEditAtuacao(ad.id)}
                                      }/>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {atuacoesDemanda.length === 0 && (
                              <EmptyState text="Nenhuma atuação cadastrada" />  
                            )}
                          </div>
                        )}
                        {activeTab === 'agendamento' && (
                          <div>
                          {agendamentosDemanda.length > 0 && agendamentosDemanda.map(ad => (
                            <div key={ad.id} className="w-full flex flex-row mb-[8px] group">
                              <div className="w-[30%]">
                                <label className="font-light text-xs text-dark-tint">Nome</label>
                                <p className="font-normal text-sm text-dark-base">{ad.nome}</p>
                              </div>
                              <div className="w-[30%]">
                                <label className="font-light text-xs text-dark-tint">Descrição</label>
                                <p className="font-normal text-sm text-dark-base">{ad.descricao}</p>
                              </div>
                              <div className="w-[20%] flex flex-row">
                                <div>
                                  <label className="font-light text-xs text-dark-tint">Prazo</label>
                                  <p className="font-normal text-sm text-dark-base">{!!ad.prazo ?formatDate(ad.prazo) : '-'}</p>
                                </div>
                              </div>
                              <div className="w-[10%] flex flex-row items-center justify-center">
                                <div className="hidden group-hover:inline-block">
                                 <button className="mr-[16px]" onClick={() => {
                                  setSelectIdDemanda(d.id)
                                  setAgendamentoId(ad.id);
                                  handleRemoveDialogAgendamento()
                                 }}>
                                    <HiOutlineTrash color="#0062FF" size={24} />
                                  </button>
                                  <button>
                                    <HiOutlinePencil color="#0062FF" size={24} onClick={() => {
                                      setSelectIdDemanda(d.id)
                                      handleEditAgendamento(ad.id)}
                                    } />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                          {agendamentosDemanda.length === 0 && (
                            <EmptyState text="Nenhum agendamento cadastrada" />  
                          )}
                        </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <DialogFormDemandaAtuacao handleOpen={handleDialogFormDemandaAtuacao} open={dialogFormAtuacao} demandaId={selectIdDemanda} atuacaoId={atuacaoId} />
        <DialogFormDemandaAgendamento handleOpen={handleDialogFormAgendamento} open={dialogFormAgendamento} demandaId={selectIdDemanda} agendamentoId={agendamentoId} />
        <DialogViewDemanda handleOpen={handleDialogViewDemanda} open={dialogViewDemanda} id={selectIdDemanda}/>

        <DialogFormDemanda handleOpen={handleDialogFormDemanda} open={dialogFormEdit} currentDemanda={currentDemanda}/>

        <DialogRemoveItem handleOpen={handleRemoveDialogAtuacao} open={dialogRemoveAtuacao} handleConfirm={handleRemoveAtuacao} loadingConfirm={loadingRemove} />
        <DialogRemoveItem handleOpen={handleRemoveDialogAgendamento} open={dialogRemoveAgendamento} handleConfirm={handleRemoveAgendamento} loadingConfirm={loadingRemove} />
        <DialogRemoveItem handleOpen={handleDialogFormDemandaDelete} open={dialogRemoveDemanda} handleConfirm={handleRemoveDemanda} loadingConfirm={loadingRemove} />

    </>
  )
}