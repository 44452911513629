
/* eslint react/prop-types: 0 */
/* eslint react/jsx-sort-props: 0 */

import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import PoppinsRegular from './Poppins/Poppins-Regular.ttf';
import Poppins700 from './Poppins/Poppins-Bold.ttf';
import Poppins600 from './Poppins/Poppins-SemiBold.ttf';
import Poppins500 from './Poppins/Poppins-Medium.ttf';
import Capa from './images/capa.png';
import LogoCapa from './images/logocapa.png';
import LogoBottom from './images/logo-bottom.jpg';
import { format, parseISO } from 'date-fns';
import { setValueMasks } from '../../../utils/number.utils';

type Props = {
  projeto: any
  localizacao: any;
  acoes: any;
  imageMap: string;
}

export function ReportDetailProjeto({projeto, localizacao, acoes, imageMap = ''}: Props) {
  function formatDate(date: string) {
    try {
      return format(parseISO(date), 'dd/MM/yyyy');
    } catch(err) {
      return '-'
    }
  }

  function breakName(name: string): string[] {
    return [name];
    }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <View>
        <View style={{flexDirection: 'row'}}>
          <View>
            <Image source={Capa} style={{width: 220, height: 842}} />
          </View>
          <View style={{flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%', height: 840, padding: 16}}>
            <View>
              <Image source={LogoCapa} style={{width: 174, height: 24}} />
            </View>
            <View style={{width: 273}}>
              <Text hyphenationCallback={e => breakName(e)} style={{fontFamily: 'Poppins', fontSize: 30, textAlign: 'right', fontWeight: 500}}>{projeto?.nome}</Text>
            </View>
            <View>
              <View>
                <Text style={{fontFamily: 'Poppins', fontSize: 30, fontWeight: 700}}>Projeto</Text>
              </View>
              <View style={{marginTop: 24}}>
                <Text style={{fontFamily: 'Poppins', fontSize: 16}}>{format(new Date(), 'dd/MM/yyyy')}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {/* <View break /> */}
      <View fixed render={({ pageNumber }) => (
        <>
          {pageNumber > 1 && (
            <View fixed style={styles.headerBar}></View>
          )}
        </>
      )}></View>
      {!!imageMap && (
        <Image source={imageMap} style={{marginRight: 16, marginLeft: 16, height: 163}} />
      )}
      <View style={styles.header}>
        <Text style={styles.headerText}>{projeto?.nome}</Text>
      </View>
      <View style={styles.boxContent} wrap={false}>
        <View style={styles.boxRow}>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Eixo</Text>
            <Text style={styles.value}>{projeto?.eixo?.nome || '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Tipo de projeto</Text>
            <Text style={styles.value}>{projeto?.tipoProjetoEnum?.toLowerCase() || '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Região</Text>
            <Text style={styles.value}>{localizacao?.length > 0 ? localizacao[0].municipio : '-'}</Text>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.boxRow}>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Etapa</Text>
            <Text style={styles.value}>{projeto?.etapa?.nome || '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Núcleo do Governo</Text>
            <Text style={styles.value}>{projeto?.secretaria?.nucleo?.nome || '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Status</Text>
            <Text style={styles.value}>{projeto?.status?.nome || '-'}</Text>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.boxRow}>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Secretaria</Text>
            <Text style={styles.value}>{projeto?.secretaria?.nome || '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Responsável pelo projeto</Text>
            <Text style={styles.value}>{projeto?.responsavel || '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Registro do Objeto</Text>
            <Text style={styles.value}>{projeto?.registro?.nome || '-'}</Text>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.boxRow}>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Prazo (meta)</Text>
            <Text style={styles.value}>{!!projeto?.prazo ? formatDate(projeto.prazo) : '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Prazo a vencer</Text>
            <Text style={styles.value}>{projeto?.qtdDiasPrazo || '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Ano</Text>
            <Text style={styles.value}>{projeto?.ano || '-'}</Text>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.boxRow}>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Valor global (R$)</Text>
            <Text style={styles.value}>{!!projeto?.valorGlobal ? `R$ ${setValueMasks(projeto?.valorGlobal || 0)}` : '-'}</Text>
          </View>
          {/* <View style={styles.boxItem4}>
            <Text style={styles.label}>Saldo financeiro</Text>
            <Text style={styles.value}>{projeto?.tipoProjetoEnum?.toLowerCase() || '-'}</Text>
          </View> */}
          <View style={styles.boxItem}>
            <Text style={styles.label}>Valor financeiro a executar</Text>
            <Text style={styles.value}>{!!projeto?.valorFinanceiro ? `R$ ${setValueMasks(projeto?.valorFinanceiro || 0)}` : '-'}</Text>
          </View>
          <View style={styles.boxItem}>
            <Text style={styles.label}>Valor financeiro executado</Text>
            <Text style={styles.value}>{!!projeto?.valorFinanceiroExecutar ? `R$ ${setValueMasks(projeto?.valorFinanceiroExecutar || 0)}` : '-'}</Text>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.boxRow}>
          <View style={styles.boxItem2}>
            <Text style={styles.label}>Órgão</Text>
            <Text style={styles.value}>{projeto?.orgao?.nome || '-'}</Text>
          </View>
          <View style={styles.boxItem2}>
            <Text style={styles.label}>Origem</Text>
            <Text style={styles.value}>{projeto?.origem || '-'}</Text>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View style={styles.boxRow}>
          <View style={styles.boxItem2}>
            <Text style={styles.label}>Pauta</Text>
            <Text style={styles.value}>{!!projeto?.pautaEnum ? projeto.pautaEnum.toLowerCase() : '-'}</Text>
          </View>
          {/* <View style={styles.boxItem2}>
            <Text style={styles.label}>Classificação da demanda</Text>
            <Text style={styles.value}>{projeto?.tipoProjetoEnum?.toLowerCase() || '-'}</Text>
          </View> */}
        </View>
        <View style={styles.divider}></View>
        <View style={styles.boxRow}>
          <View style={styles.boxItem2}>
            <Text style={styles.label}>Convênio</Text>
            <Text style={styles.value}>{projeto?.convenio || '-'}</Text>
          </View>
          <View style={styles.boxItem2}>
            <Text style={styles.label}>Empresa</Text>
            <Text style={styles.value}>{projeto?.empresa || '-'}</Text>
          </View>
        </View>
        <View style={styles.divider}></View>
        <View>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Text style={styles.label}>% da execução</Text>
            <Text style={styles.label}>{projeto?.percentualExecutado || 0}%</Text>  
          </View>
        </View>
        
      </View>

      {acoes?.length > 0 && (
        <>
          <View break></View>
          <View style={{padding: 16}}>
            <View>
              <Text style={styles.headerText2}>Ações</Text>
            </View>
          </View>
          {acoes.map((i: any) => (
            <View style={{padding: 16}} key={i.id}>
              <View style={styles.boxContent2} wrap={false}>
                <Text style={[styles.titleAction, {marginBottom: 8, fontWeight: 700}]}>{i.nome}</Text>
                <View style={styles.boxRow}>
                  <View style={styles.boxItem4}>
                    <Text style={styles.label}>Secretaria</Text>
                    <Text style={styles.value}>{projeto?.secretaria?.nucleo?.nome || '-'}</Text>
                  </View>
                  <View style={styles.boxItem4}>
                    <Text style={styles.label}>Responsável</Text>
                    <Text style={styles.value}>{i.responsavel || '-'}</Text>
                  </View>
                  <View style={styles.boxItem4}>
                    <Text style={styles.label}>Prazo</Text>
                    <Text style={styles.value}>{!!i.prazoAcao ? formatDate(i.prazoAcao) : '-'}</Text>
                  </View>
                  <View style={styles.boxItem4}>
                    <Text style={styles.label}>Custo previsto</Text>
                    <Text style={styles.value}>{i.custoPrevisto || '-'}</Text>
                  </View>
                </View>
                <View style={styles.divider}></View>
                <View style={styles.boxRow}>
                  <View style={styles.boxItem2}>
                    <Text style={styles.label}>Recursos necessários</Text>
                    <Text style={styles.value}>{i.recursosNecessarios || '-'}</Text>
                  </View>
                  <View style={styles.boxItem2}>
                    <Text style={styles.label}>Status</Text>
                    <Text style={styles.value}>{i.status?.nome}</Text>
                  </View>
                </View>
              </View>
              {/* {!!i.atuacao}
              <View>
                
              </View> */}
            </View>
          ))}
        </>  
      )}

      

      <View style={styles.pageNumber} fixed render={({ pageNumber }) => (
        <>
          {pageNumber > 1 && (
            <View>
              <View style={{height:1, width: '100%', backgroundColor: '#CBCBCB'}}></View> 
              <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16, paddingTop: 16}}>
                <View>
                  {/* <Image source={LogoBottom} style={{width: 220, height: 842}} /> */}
                </View>
                <Text 
                  style={styles.pageNumberText}
                  render={({ pageNumber }) => `${pageNumber}`}
                />
              </View>
            </View>
          )}
        </>
      )}>
        
      </View>


    </Page>
  </Document>
  );
}

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: PoppinsRegular,
      fontWeight: 400,
    },
    {
      src: Poppins700,
      fontWeight: 700,
    },
    {
      src: Poppins600,
      fontWeight: 600,
    },
    {
      src: Poppins500,
      fontWeight: 500,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    backgroundColor: '#F5F8FA'
  },
  pageCapa: {
    flexDirection: 'row',
    backgroundColor: '#F5F8FA',
    margin: 0
  },
  headerBar: {
    height: 16,
    width: '100%',
    backgroundColor: '#0062FF'
  },
  header: {
    padding: 16,
    fontFamily: 'Poppins',
    fontWeight: 700
  },
  header2: {
  },
  headerText: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700
  },
  headerText2: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700
  },
  boxContent: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
    marginHorizontal: 16
  },
  boxContent2: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
  },
  boxRow: {
    flexDirection: 'row',
  },
  boxItem: {
    width: '33%'
  },
  boxItem2: {
    width: '50%'
  },
  boxItem4: {
    width: '25%'
  },
  label: {
    fontSize: 8,
    fontFamily: 'Poppins',
    color: '#425466'
  },
  value: {
    fontFamily: 'Poppins',
    fontSize: 11,
    width: '80%'
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#F1F1F1',
    marginVertical: 8
  },
  pageNumber: {
    position: 'absolute',
    bottom: 16,
    left: 0,
    right: 0,
  },
  pageNumberText: {
    fontFamily: 'Poppins',
    fontSize: 14
  },
  titleAction: {
    fontFamily: 'Poppins',
    fontSize: 18,
    color: '#425466',
  }
});