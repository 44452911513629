
/* eslint react/prop-types: 0 */
/* eslint react/jsx-sort-props: 0 */

import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useState } from 'react';

import PoppinsRegular from './Poppins/Poppins-Regular.ttf';
import Poppins700 from './Poppins/Poppins-Bold.ttf';
import Poppins600 from './Poppins/Poppins-SemiBold.ttf';
import Poppins500 from './Poppins/Poppins-Medium.ttf';
import Capa from './images/capa.png';
import LogoCapa from './images/logocapa.png';
import Icon1 from './images/icon1.png';
import Icon2 from './images/icon2.png';
import Icon3 from './images/icon3.png';
import Icon4 from './images/icon4.png';
import Exc from './images/exc.png';
import Star from './images/star.png';
import LogoBottom from './images/logo-bottom.jpg';
import { format, parseISO } from 'date-fns';
import { setValueMasks } from '../../../utils/number.utils';
import { formatTipoCrise } from '../../../utils/situacao.utils';

type Props = {
  situacao: any
  impactos: any
  solucoes: any
  cenarios: any
  estrategias: any;
  planos: any;
  nomeLocalizacao: any;
}

export function ReportSalaSituacao({situacao, impactos, solucoes, cenarios, estrategias, planos, nomeLocalizacao}: Props) {

  function formatDate(date: string) {
    try {
      return format(parseISO(date), 'dd/MM/yyyy');
    } catch(err) {
      return '-'
    }
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <View>
        <View style={{flexDirection: 'row'}}>
          <View>
            <Image source={Capa} style={{width: 220, height: 842}} />
          </View>
          <View style={{flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%', height: 840, padding: 16}}>
            <View>
              <Image source={LogoCapa} style={{width: 174, height: 24}} />
            </View>
            <View style={{width: 273}}>
              <Text style={{fontFamily: 'Poppins', fontSize: 30, textAlign: 'right', fontWeight: 500}}>{situacao?.nome}</Text>
            </View>
            <View>
              <View>
                <Text style={{fontFamily: 'Poppins', fontSize: 30, fontWeight: 700}}>Sala de Situação</Text>
              </View>
              <View style={{marginTop: 24}}>
                <Text style={{fontFamily: 'Poppins', fontSize: 16}}>{format(new Date(), 'dd/MM/yyyy')}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {/* <View break /> */}
      <View fixed render={({ pageNumber }) => (
        <>
          {pageNumber > 1 && (
            <View fixed style={styles.headerBar}></View>
          )}
        </>
      )}></View>

      <View style={{padding: 16}}>
        <View>
          <Text style={{fontFamily: 'Poppins', fontSize: 26, fontWeight: 700}}>Informações Gerais</Text>
        </View>
        
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 16}}>
          <View style={{flex: .5, flexDirection: 'row'}}>
            <View style={{marginRight: 8}}>
              <Image source={Icon1} style={{width: 39, height: 39}} />
            </View>
            <View>
              <Text style={{fontFamily: 'Poppins', fontSize: 12, color: '#425466'}}>Frequência da ocorrência</Text>
              <Text style={{fontFamily: 'Poppins', fontSize: 18, fontWeight: 600, textTransform: 'capitalize'}}>{situacao?.frequenciaOcorrenciaEnum?.toLowerCase()}</Text>
            </View>
          </View>
          <View style={{flex: .5, flexDirection: 'row'}}>
            <View style={{marginRight: 8}}>
              <Image source={Icon2} style={{width: 39, height: 39}} />
            </View>
            <View>
              <Text style={{fontFamily: 'Poppins', fontSize: 12, color: '#425466'}}>Tipo de crise</Text>
              <Text style={{fontFamily: 'Poppins', fontSize: 18, fontWeight: 600, textTransform: 'capitalize'}}>{!!situacao?.tipoCriseEnum ? formatTipoCrise(situacao.tipoCriseEnum) : '-'}</Text>
            </View>
          </View>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 8}}>
          <View style={{flex: .5, flexDirection: 'row'}}>
            <View style={{marginRight: 8}}>
              <Image source={Icon3} style={{width: 39, height: 39}} />
            </View>
            <View>
              <Text style={{fontFamily: 'Poppins', fontSize: 12, color: '#425466'}}>Gravidade da ocorrência</Text>
              <Text style={{fontFamily: 'Poppins', fontSize: 18, fontWeight: 600, textTransform: 'capitalize'}}>{situacao?.gravidadeOcorrenciaEnum?.toLowerCase()}</Text>
            </View>
          </View>
          <View style={{flex: .5, flexDirection: 'row'}}>
            <View style={{marginRight: 8}}>
              <Image source={Icon4} style={{width: 39, height: 39}} />
            </View>
            <View>
              <Text style={{fontFamily: 'Poppins', fontSize: 12, color: '#425466'}}>Região</Text>
              <Text style={{fontFamily: 'Poppins', fontSize: 18, fontWeight: 600, textTransform: 'capitalize'}}>{nomeLocalizacao}</Text>
            </View>
          </View>
        </View>

        <View style={{marginTop: 16}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <View>
              <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16}}>Impactos</Text>
            </View>
            <View style={{width: 32, height: 32, borderRadius: 16, backgroundColor: 'rgba(102, 117, 255, 0.10)', alignItems: 'center', justifyContent: 'center'}}>
              <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16, color: '#485FF7'}}>{impactos?.length || 0}</Text>
            </View>
          </View>
        </View>

        {impactos?.length > 0 && impactos.map((i: any) => (
          <View style={{marginTop: 8}} wrap={false} key={i.id}>
            <View style={{padding: 16, borderWidth: 1, borderColor: '#F0F1F3', borderRadius: 4, backgroundColor: 'white'}}>
              <Text style={{fontFamily: 'Poppins', fontWeight: 500, fontSize: 14}}>{i.nome}</Text>
              <View style={styles.divider}></View>
              <View style={styles.boxRow}>
                <View style={styles.boxItem}>
                  <View>
                    <Text style={styles.label}>Quantidade</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.quantidade}</Text>
                  </View>
                </View>
                <View style={styles.boxItem}>
                  <View>
                    <Text style={styles.label}>Unidade</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.unidade}</Text>
                  </View>
                </View>
                <View style={styles.boxItem}>
                  <View>
                    <Text style={styles.label}>Status</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.status?.nome}</Text>
                  </View>
                </View>
              </View>
              <View style={{marginTop: 8}}>
                <View>
                  <Text style={styles.label}>Especificação</Text>
                </View>
                <View>
                  <Text style={styles.value}>{i.especificacao}</Text>
                </View>
              </View>
            </View>
            
          </View>
        ))}        

      </View>

      <View break></View>
      <View style={{padding: 16}}>

        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <View>
            <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16}}>Soluções e medidas iniciais</Text>
          </View>
          <View style={{width: 32, height: 32, borderRadius: 16, backgroundColor: 'rgba(102, 117, 255, 0.10)', alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16, color: '#485FF7'}}>{solucoes?.length || 0}</Text>
          </View>
        </View>
        
        {solucoes?.length > 0 && solucoes.map((i: any) => (
          <View style={{marginTop: 8}} wrap={false} key={i.id}>
            <View style={{padding: 16, borderWidth: 1, borderColor: '#F0F1F3', borderRadius: 4, backgroundColor: 'white'}}>
              <Text style={{fontFamily: 'Poppins', fontWeight: 500, fontSize: 14}}>{i.nome}</Text>
              <View style={styles.divider}></View>
              <View style={{marginBottom: 8}}>
                <View>
                  <Text style={styles.label}>Escopo</Text>
                </View>
                <View>
                  <Text style={styles.value}>{i.descricao}</Text>
                </View>
              </View>
              <View style={[styles.boxRow, {marginBottom: 8}]}>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Eixo</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.eixo?.nome}</Text>
                  </View>
                </View>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Responsável</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.responsavel?.nome}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.boxRow}>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Custo previsto</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>R$ {setValueMasks(i.custoPrevisto || 0)}</Text>
                  </View>
                </View>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Status</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.status?.nome}</Text>
                  </View>
                </View>
              </View>
              
            </View>
            
          </View>
        ))}
      </View>

      <View break />

      <View style={{padding: 16}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <View>
            <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16}}>Cenários</Text>
          </View>
          <View style={{width: 32, height: 32, borderRadius: 16, backgroundColor: 'rgba(102, 117, 255, 0.10)', alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16, color: '#485FF7'}}>{cenarios?.length || 0}</Text>
          </View>
        </View>
        
        {cenarios?.length > 0 && cenarios.map((i: any) => (
          <View style={{borderWidth: 1, borderColor: i.expectativaEnum?.toUpperCase() === "PESSIMISTA" ? '#FF6666' : '#39B100', borderRadius: 4, marginTop: 8}} wrap={false}>
            <View style={{paddingHorizontal: 16, paddingVertical: 4, width: '100%', backgroundColor: i.expectativaEnum?.toUpperCase() === "PESSIMISTA" ? '#FF6666' : '#39B100'}}>
              <Text style={{textTransform:"capitalize", color: 'white', fontFamily: 'Poppins', fontSize: 14, fontWeight: 600}}>{i.expectativaEnum?.toLowerCase()}</Text>
            </View>
            <View style={{padding: 16}}>
              <View>
                <Text style={styles.label}>Evento/condição</Text>
                <Text style={styles.value}>{i.evento}</Text>
              </View>
              <View style={styles.divider} />
              <View>
                <Text style={styles.label}>Consequência</Text>
                <Text style={styles.value}>{i.consequencia}</Text>
              </View>
            </View>
          </View>
        ))}

      </View>

      <View break />

      <View style={{padding: 16}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <View>
            <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16}}>Estratégias de combate e prevenção</Text>
          </View>
          <View style={{width: 32, height: 32, borderRadius: 16, backgroundColor: 'rgba(102, 117, 255, 0.10)', alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16, color: '#485FF7'}}>{estrategias?.length || 0}</Text>
          </View>
        </View>

        {estrategias?.length > 0 && estrategias.map((i: any) => (
          <View style={{marginTop: 8}} wrap={false} key={i.id}>
            <View style={{padding: 16, borderWidth: 1, borderColor: '#F0F1F3', borderRadius: 4, backgroundColor: 'white'}}>
              <Text style={{fontFamily: 'Poppins', fontWeight: 500, fontSize: 14}}>{i.nome}</Text>
              <View style={styles.divider}></View>
              <View style={{marginBottom: 8}}>
                <View>
                  <Text style={styles.label}>Descrição</Text>
                </View>
                <View>
                  <Text style={styles.value}>{i.descricao}</Text>
                </View>
              </View>
              <View style={[styles.boxRow, {marginBottom: 8}]}>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Recurso estimado</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.recursoEstimado}</Text>
                  </View>
                </View>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Custo previsto</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>R$ {setValueMasks(i.custoPrevisto || 0)}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.boxRow}>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Prazo estimado</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{formatDate(i.prazoEstimado)}</Text>
                  </View>
                </View>
              </View>
              
            </View>
            
          </View>
        ))}

      </View>


      <View break />


      <View style={{padding: 16}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <View>
            <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16}}>Plano de ação</Text>
          </View>
          <View style={{width: 32, height: 32, borderRadius: 16, backgroundColor: 'rgba(102, 117, 255, 0.10)', alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontFamily: 'Poppins', fontWeight: 600, fontSize: 16, color: '#485FF7'}}>{planos?.length || 0}</Text>
          </View>
        </View>

        {planos?.length > 0 && planos.map((i: any) => (
          <View style={{marginTop: 8}} wrap={false} key={i.id}>
            <View style={{padding: 16, borderWidth: 1, borderColor: '#F0F1F3', borderRadius: 4, backgroundColor: 'white'}}>
              <Text style={{fontFamily: 'Poppins', fontWeight: 500, fontSize: 14}}>{i.nome}</Text>
              <View style={styles.divider}></View>
              <View style={[styles.boxRow, {marginBottom: 8}]}>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Recurso estimado</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{!! i.recursoEstimado ? i.recursoEstimado : '-'}</Text>
                  </View>
                </View>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Prazo</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{formatDate(i.prazoAcao)}</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.boxRow,{marginBottom: 8}]}>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Atuação</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.atuacao || '-'}</Text>
                  </View>
                </View>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Custo previsto</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>R$ {setValueMasks(i.custoPrevisto || 0)}</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.boxRow,{marginBottom: 8}]}>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Recursos necessários</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.recursosNecessarios || '-'}</Text>
                  </View>
                </View>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Status</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.status?.nome || '-'}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.boxRow}>
                <View style={styles.boxItem2}>
                  <View>
                    <Text style={styles.label}>Secretaria</Text>
                  </View>
                  <View>
                    <Text style={styles.value}>{i.secretaria?.nome || '-'}</Text>
                  </View>
                </View>
              </View>
              
            </View>
            
          </View>
        ))}

      </View>

      
      

      <View style={styles.pageNumber} fixed render={({ pageNumber }) => (
          <>
            {pageNumber > 1 && (
              <View>
                {/* <View style={{height:1, width: '100%', backgroundColor: '#CBCBCB'}}></View>  */}
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16, paddingTop: 16}}>
                  <View>
                    {/* <Image source={LogoBottom} style={{width: 220, height: 842}} /> */}
                  </View>
                  <Text 
                    style={styles.pageNumberText}
                    render={({ pageNumber }) => `${pageNumber}`}
                  />
                </View>
              </View>
            )}
          </>
        )}></View>


    </Page>
  </Document>
  );
}

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: PoppinsRegular,
      fontWeight: 400,
    },
    {
      src: Poppins700,
      fontWeight: 700,
    },
    {
      src: Poppins600,
      fontWeight: 600,
    },
    {
      src: Poppins500,
      fontWeight: 500,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    backgroundColor: '#F5F8FA',
  },
  headerBar: {
    height: 16,
    width: '100%',
    backgroundColor: '#0062FF'
  },
  header: {
    padding: 16,
    fontFamily: 'Poppins',
    fontWeight: 700
  },
  header2: {
  },
  headerText: {
    fontFamily: 'Poppins',
    fontSize: 26,
    fontWeight: 700
  },
  headerText2: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700
  },
  boxContent: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
    marginHorizontal: 16
  },
  boxContent2: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
  },
  boxRow: {
    flexDirection: 'row',
  },
  boxItem: {
    width: '33%'
  },
  boxItem2: {
    width: '50%'
  },
  boxItem4: {
    width: '25%'
  },
  label: {
    fontSize: 8,
    fontFamily: 'Poppins',
    color: '#425466'
  },
  value: {
    fontFamily: 'Poppins',
    fontSize: 11,
    width: '80%'
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#F1F1F1',
    marginVertical: 8
  },
  pageNumber: {
    position: 'absolute',
    bottom: 16,
    left: 0,
    right: 0,
  },
  pageNumberText: {
    fontFamily: 'Poppins',
    fontSize: 14
  },
  titleAction: {
    fontFamily: 'Poppins',
    fontSize: 18,
    color: '#425466',
  }
});