import { Button, Checkbox } from "@material-tailwind/react";
import { HiChevronDown, HiChevronUp, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { TPlanoAcao, removePlanoAcao, updatePlanoAcao } from "../../providers/plano-acao";
import { format, parseISO } from "date-fns";
import { DialogFormPlanoAcao } from "../DialogFormPlanoAcao";
import { useState } from "react";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { setValueMasks } from "../../utils/number.utils";
import TextTruncate from 'react-text-truncate';
import { useNavigate } from "react-router-dom";

type Props = {
  data: TPlanoAcao;
  isSecretaria?: boolean;
}

export function PlanoAcaoMobile({ data, isSecretaria = false }: Props) {
  const [dialogForm, setDialogForm] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [currentPlanoAcao, setCurrentPlanoAcao] = useState<TPlanoAcao>({} as TPlanoAcao);
  const [ixActive, setIxActive] = useState<number>(-1);

  const queryClient = useQueryClient()

  function handleDialogForm() {
    setDialogForm((prev) => !prev);
  }

  function handleDialogRemove() {
    setDialogRemove((prev) => !prev);
  }

  function handleDropdown(index: number) {
    setIxActive(prev => prev === index ? -1 : index)
  }

  const navigate = useNavigate();

  async function handleRemove() {
    try {

      setLoadingRemove(true)
      await removePlanoAcao(currentPlanoAcao.id);
      
      toast('Impacto excluído com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleDialogRemove()

      if (!!currentPlanoAcao.situacaoId) {
        queryClient.resetQueries([currentPlanoAcao.situacaoId, 'find-plano-acao-by-situcao-id']);
        queryClient.invalidateQueries([currentPlanoAcao.situacaoId, 'find-plano-acao-by-situcao-id']);
      }

      if (!!currentPlanoAcao.projetoId) {
        queryClient.resetQueries([currentPlanoAcao.projetoId, 'find-plano-acao-by-projeto-id']);
        queryClient.invalidateQueries([currentPlanoAcao.projetoId, 'find-plano-acao-by-projeto-id']);
      }

    } catch(err) {
      setLoadingRemove(false);
      toast('Erro ao excluir plano de ação', {type: 'error'});
    }
  }

  function formatDate(date: string) {
    const dt = new Date(date);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

    try {
      return format(dtDateOnly, 'dd/MM/yyyy');
    } catch(err) {
      return ''
    }
  }

  function handleEdit(plano: TPlanoAcao) {
    setCurrentPlanoAcao(plano);
    handleDialogForm();
  }

  async function handleMonitorar(plano: TPlanoAcao) {
    try {
      await updatePlanoAcao(plano.id, {
        ...plano,
        monitorar: !plano.monitorar
      })
      toast('Plano de ação atualizado com sucesso', {type: 'success'});
      
      queryClient.resetQueries([plano.situacaoId, 'find-plano-acao-by-situcao-id']);
      queryClient.invalidateQueries([plano.situacaoId, 'find-plano-acao-by-situcao-id']);

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar plano de ação', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
    }
  }

  function handleNavigateOrigem(id?: number, isSituacao = false, isProjeto = false) {
    if (isSituacao && !!id) {
      navigate(`/internal/sala-situacao/${id}`)
      return;
    }
    
    if (isProjeto && !!id) {
      navigate(`/internal/projeto/${id}`)
      return;
    }

  }

  return (
    <div className="flex flex-col justify-between p-[16px] bg-[#F5F8FA] mr-[16px] min-w-[270px] max-w-[270px] min-h-[380px] mb-[8px]">
      <div className="flex flex-col gap-2">
        <div>
          <h1 className="text-[#4A4C56] text-[14px] font-light">Ação</h1>
          <p className="text-dark-tint text-[16px] font-normal">{data.nome}</p>
        </div>
        <div className="h-[0.5px] w-full bg-[#E4E4E4]"></div>
        <div>
          <h1 className="text-[#4A4C56] text-[14px] font-light">Prazo</h1>
          <p className="text-dark-tint text-[16px] font-normal">{formatDate(data.prazoAcao)}</p>
        </div>
        <div className="h-[0.5px] w-full bg-[#E4E4E4]"></div>
        <div className="flex flex-row items-center justify-between">
          <div>
            <h1 className="text-[#4A4C56] text-[14px] font-light">Status</h1>
            <span className="flex h-[24px] px-[16px] items-center justify-between border border-warning-card text-warning-card bg-warning-card-opacity rounded-full text-xs font-semibold absolute">
              <h1>{data.status?.nome}</h1>
            </span>
            {/* <p className="text-dark-tint text-[16px] font-normal">{data.unidade}</p> */}
          </div>
          {/* <div> */}
            {/* <h1 className="text-[#4A4C56] text-[14px] font-light">Monitorar</h1> */}
            {/* <Checkbox color="blue" checked={data.monitorar} crossOrigin={'*'} onClick={() => handleMonitorar(data)} /> */}
            {/* <p className="text-dark-tint text-[16px] font-normal">{data.unidade}</p> */}
          {/* </div> */}
        </div>
        
      </div>
      {/* <DialogFormSolucao open={openForm} handleOpen={handleOpen} idSituacao={data.situacaoId} solucao={data} /> */}
    </div>

    // <div>
      
      
      /* <DialogFormPlanoAcao handleOpen={handleDialogForm} open={dialogForm} idSituacao={currentPlanoAcao.situacaoId || 0} idProjeto={currentPlanoAcao.projetoId || 0} planoAcao={currentPlanoAcao} />
      <DialogRemoveItem handleConfirm={handleRemove} handleOpen={handleDialogRemove} open={dialogRemove} loadingConfirm={loadingRemove} /> */
    // </div>
  )
}
