import { api } from "../services/api";

export type TPlanoAcao = {
  id: number;
  nome: string;
  statusId: number;
  secretariaId?: number;
  situacaoId?: number;
  projetoId?: number;
  atuacao: string;
  monitorar: boolean;
  responsavel: string;
  recursosNecessarios: string;
  custoPrevisto: number;
  prazoAcao: string;
  status: TPlanoAcaoStatus;
}

export type TPlanoAcaoStatus = {
  id: number;
  nome: string;
  // prazoAcao: string;
}

type TPlanoAcaoAgenda = {
  id: number;
  nome: string;
}

export async function allPlanoAcao(page: number, term: string, secretariaId: number) {
  try {
    const { data } = await api.get(`/planos-acao?tamanho=99&pagina=${page}&filtro=${term || ''}&secretariaId=${secretariaId}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function findPlanoAcaoBySituacaoId(situacaoId: number): Promise<TPlanoAcao[]> {
  try {
    const { data } = await api.get(`/planos-acao?situacaoId=${situacaoId}`);
    const planoAcao = data.data;

    
    if (planoAcao?.content?.length > 0) {
      const statusData = await allPlanoAcaoStatus();

      const result = planoAcao.content.map((i: TPlanoAcao) => {
        return {
          ...i,
          status: statusData.find(sd => sd.id === i.statusId)
        }
      })

      return result;

    }
    return [];
  } catch(err) {
    throw err;
  }
}

export async function findPlanoAcaoByProjetoId(projetoId: number): Promise<TPlanoAcao[]> {
  try {
    const { data } = await api.get(`/planos-acao?projetoId=${projetoId}`);
    const planoAcao = data.data;

    
    if (planoAcao?.content?.length > 0) {
      const statusData = await allPlanoAcaoStatus();

      const result = planoAcao.content.map((i: TPlanoAcao) => {
        return {
          ...i,
          status: statusData.find(sd => sd.id === i.statusId)
        }
      })

      return result;

    }
    return [];
  } catch(err) {
    throw err;
  }
}

export async function createPlanoAcao(body: Omit<TPlanoAcao, 'id'>): Promise<TPlanoAcao> {
  try {
    const { data } = await api.post(`/planos-acao`, body);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function updatePlanoAcao(id: number, body: Omit<TPlanoAcao, 'id'>): Promise<TPlanoAcao> {
  try {
    const { data } = await api.put(`/planos-acao/${id}`, body);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function removePlanoAcao(id: number): Promise<TPlanoAcao> {
  try {
    const { data } = await api.delete(`/planos-acao/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allPlanoAcaoStatus(): Promise<TPlanoAcaoStatus[]> {
  try {
    const { data } = await api.get(`/planos-acao/status`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function allPlanoAcaoAgenda(secretariaId: number): Promise<TPlanoAcaoStatus[]> {
  try {
    const { data } = await api.get(`/planos-acao/agenda?secretariaId=${secretariaId}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

type TBodyAtuacao = {
  planoAcaoId: number;
  descricao: string
}

export async function createAtuacaoPlanoAcao(body: TBodyAtuacao) {
  try {
    const { data } = await api.post(`/planos-acao/atuacoes`, body);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function updatePlanoAcaoAtuacao(id: number, params: TBodyAtuacao) {
  try {
    const { data } = await api.put(`/planos-acao/atuacoes/${id}`, params);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function atuacaoByPlanoAcaoId(id: number): Promise<{id: number, descricao: string}[]> {
  try {
    const { data } = await api.get(`/planos-acao/${id}/atuacoes`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function removeAtuacaoPlanoAcao(id: number) {
  try {
    const { data } = await api.delete(`/planos-acao/atuacoes/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}

export async function findAtuacaoPlanoAcaoById(id: number) {
  try {
    const { data } = await api.get(`/planos-acao/atuacoes/${id}`);
    return data.data;
  } catch(err) {
    throw err;
  }
}