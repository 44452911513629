import { api } from "../services/api";

export type TUser = {
  id: number;
  keycloakId: string;
  nome: string;
  sobrenome: string;
  email: string;
}

type TKeycloakGrupo = {
  id: number;
  nome: string;
}

type TKeycloakUsuario = {
    id: string;
    nome: string;
    sobrenome: string;
    email: string;
}

type PatchUsuarioSecretaria = {
  secretariaId: number;
  grupo: string;
  grupoId: string;
}

export async function allUsers(): Promise<TUser[]> {
  try {
    const { data } = await api.get(`/usuarios`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function userByEmail(email: string): Promise<TUser[]> {
  try {
    const { data } = await api.get(`/usuarios?email=${email}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function getKeycloakGroups(): Promise<TKeycloakGrupo[]> {
  try {
    const { data } = await api.get(`/keycloak/grupos`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function getKeycloakUsuarios(email: string): Promise<TKeycloakUsuario> {
  try {
    const { data } = await api.get(`/keycloak/usuarios`, {
      params: {
        email
      }
    });
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function createUsuario(params: Omit<TUser, 'id'>) {
  try {
    const { data } = await api.post(`/usuarios`, params);
    return data.data;
  } catch (err) {
    throw err;
  }

}
export async function updateUsuario(id: number, params: Omit<TUser, 'id'>) {
  try {
    const { data } = await api.put(`/usuarios/${id}`, params);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateUsuarioSecretaria(id: number, params: PatchUsuarioSecretaria) {
  try {
    const { data } = await api.patch(`/usuarios/${id}/secretarias`, params);
    return data.data;
  } catch (err) {
    throw err;
  }
}

type TUsuarioSecretaria = {
  id: number
  secretaria: {
    id: number,
    nome: string;
    sigla: string;
    nucleo: {
      nome: string;
    },
    codigo: string;
  },
  grupo: string;
  grupoId: string;
}

export async function getUsuarioSecretaria(id: number): Promise<TUsuarioSecretaria[]> {
  try {
    const { data } = await api.get(`/usuarios/${id}/secretarias`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function removeUsuarioSecretaria(idUsuario: number, idSecretaria: number) {
  try {
    const { data } = await api.delete(`/usuarios/${idUsuario}/secretarias/${idSecretaria}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}