import { useEffect, useState } from "react"

import { useAllPlanoAcaoAgenda }  from "../../hooks/query/usePlanoAcaoQuery"

import { useCalendarApp, ScheduleXCalendar } from '@schedule-x/react'
import {
  viewWeek,
  viewDay,
  viewMonthGrid,
} from '@schedule-x/calendar'
import EventoMesCustom from "./EventoMesCustom"
import { createEventModalPlugin } from '@schedule-x/event-modal'
import '@schedule-x/theme-default/dist/index.css'
import './style.css'
import { ModalEventDetail } from "./ModalEventDetail"
import { DialogFormDemandaAgendamento } from "../DialogFormDemandaAgendamento"
import { DialogViewDemanda } from "../DialoViewDemanda"

type TProps = {
  agendamentos: any
}

export function CalendarAgendamentos({agendamentos}: TProps) {
  const [modalDetail, setModalDetail] = useState(false);
  const [selectIdDemanda, setSelectIdDemanda] = useState<number>();
  const [calendarDetail, setCalendarDetail] = useState<any>({});
  const [dialogFormAgendamento, setDialogFormAgendamento] = useState(false);
  const [dialogViewDemanda, setDialogViewDemanda] = useState(false);
  
  function handleModalDetail() {
    setModalDetail(prev => !prev);
  }

  function handleDialogFormAgendamento() {
    setDialogFormAgendamento(prev => !prev)
  }

  function handleDialogViewDemanda() {
    setDialogViewDemanda(prev => !prev)
  }

  useEffect(() => {
      setTimeout(() => {
        const a = document.getElementsByClassName("sx__today-button");

        if (a.length > 0) {
          a[0].innerHTML = 'Hoje'
        }
      }, 100);
  }, [agendamentos])


  const events = agendamentos.map((plano: any) => {
    let start = '';

    if (!!plano.prazo) {
      start = plano.prazo.split('T')[0] 
    }

    if (!!plano.estimativa) {
      start = plano.estimativa.split('T')[0] 
    }

    return {
      id: plano.id,
      title: plano.nome,
      start: start,
      end: start,
      ...plano
    }
  })

  const calendar = useCalendarApp({
    defaultView: viewMonthGrid.name,
    views: [viewDay, viewWeek, viewMonthGrid],
    locale: 'pt-BR',
    events,
    callbacks: {
      onEventClick(calendarEvent) {
        console.log('onClickEvent', calendarEvent)
        
        if (!!calendarEvent.demandaId) {
          setSelectIdDemanda(calendarEvent.demandaId);
        } else {
          setSelectIdDemanda(Number(calendarEvent.id));
        }
        // setCalendarDetail(calendarEvent);
        handleDialogViewDemanda();
      },
      onClickDate(date) {
        handleDialogFormAgendamento()
      },
    },
    // plugins: [createEventModalPlugin()],
    
  })
 
  return (
      <>
        <ScheduleXCalendar calendarApp={calendar} customComponents={{ monthGridEvent: EventoMesCustom }} />
        <ModalEventDetail handleOpen={handleModalDetail} open={modalDetail} detail={calendarDetail}/>
        <DialogFormDemandaAgendamento handleOpen={handleDialogFormAgendamento} open={dialogFormAgendamento} />
        <DialogViewDemanda handleOpen={handleDialogViewDemanda} open={dialogViewDemanda} id={selectIdDemanda}/>
      </>
  )
}