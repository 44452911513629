export function Pessoas() {

  return (
    <div className="w-full h-[89vh]">
      <iframe
        title="PowerBI Report"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiZDZiMTc4MDItZDNiZS00YjFlLWE3ODctNTA4NDU1MDFjMzNhIiwidCI6ImJhNmIwMzJmLTU2YmUtNGRkNC1hZmJhLWQ1MTgzN2I1ZjNiNCJ9"
        allow="fullscreen"
      />
    </div>
  )
}
