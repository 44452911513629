import { Spinner } from "@material-tailwind/react";
import { CalendarApp } from "../../components/Calendar";
import { EmptyState } from "../../components/EmptyState";
import { TablePlanoAcao } from "../../components/TablePlanoAcao";
import { useAllPlanoAcao, useAllPlanoAcaoAgenda } from "../../hooks/query/usePlanoAcaoQuery";
import { useAllProjetoFavorito, useAllProjetoSecretaria } from "../../hooks/query/useProjetoQuery";
import Carousel from "react-multi-carousel";
import { CardProjeto } from "../../components/CardProjeto";
import { BoxError } from "../../components/BoxError";
import { useUser } from "../../hooks/UserContext";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export function Secretarias() {


  const { secretariaSelected } = useUser()

  const planoAcaoQuery = useAllPlanoAcao(0, '', secretariaSelected.id);
  const planoAcao = planoAcaoQuery.data || [];


  const allPlanoAcaoAgenda = useAllPlanoAcaoAgenda(secretariaSelected.id);
  const planoAcaoAgenda = allPlanoAcaoAgenda.data || [];


  const allProjetoQuery = useAllProjetoFavorito(secretariaSelected.id);
  const projetos = allProjetoQuery.data || [];

  const allProjetoSecretariaQuery = useAllProjetoSecretaria(secretariaSelected.id);
  const projetosSecretaria = allProjetoSecretariaQuery.data || [];

  console.log('projetosSecretaria', projetosSecretaria)

  return (
    <div>
      <div className="flex flex-row items-center justi-between mb-[24px]">
        <div>
          <h1 className="text-[42px] text-dark-base">Secretaria</h1>
        </div>
      </div>
      <div className="h-[89vh] mb-[24px]">
        <iframe
          title="PowerBI Report"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiN2Q1MTU2NmYtOGRkZC00NTU1LWE2Y2EtZjc0NmI0MGU2OWU0IiwidCI6ImJhNmIwMzJmLTU2YmUtNGRkNC1hZmJhLWQ1MTgzN2I1ZjNiNCJ9"
          allow="fullscreen"
        />
      </div>


      <div className="w-full p-[24px] bg-white mb-[24px] border border-[#EBEBED] rounded-[4px]">
        <div className="flex flex-row items-center justify-between">
          <p className="text-[#27272E] text-lg">Projetos favoritados - Secretaria</p>
        </div>
        <div className="mt-[24px]">
          {!allProjetoQuery.isLoading && allProjetoQuery.isSuccess && (
            <>
              {projetos?.content?.length ? (
                <Carousel
                  responsive={responsive}
                  autoPlaySpeed={5000}
                  autoPlay
                  infinite
                  arrows={false}
                >
                  {projetos.content.map((i: any) => (
                    <CardProjeto key={String(i.id)} data={i} isHeight />
                  ))}
                </Carousel>
              ) : (
                <div className="w-full">
                  <EmptyState text="Nenhum projeto cadastrado" />
                </div>
              )}
            </>
          )}
          {allProjetoQuery.isLoading && (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          )}
          {!allProjetoQuery.isLoading && allProjetoQuery.isError && (
            <div className="w-full">
              <BoxError text="Erro ao carregar as informações" />
            </div>
          )}
        </div>
      </div>

      <div className="bg-white mb-[24px]">
        {!planoAcaoQuery.isLoading && planoAcaoQuery.isSuccess && (
          <>
            {planoAcao.length > 0 ? (
              <TablePlanoAcao data={planoAcao} isSecretaria={true} />
            ) : (
              <EmptyState text="Nenhum plano encontrado" />
            )}
          </>
        )}
        {planoAcaoQuery.isLoading && (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </div>

      <div className="">
        {!allPlanoAcaoAgenda.isLoading && allPlanoAcaoAgenda.isSuccess && !allProjetoSecretariaQuery.isLoading && allProjetoSecretariaQuery.isSuccess && (
          <>
            <CalendarApp planoAcaoAgenda={[...planoAcaoAgenda, ...projetosSecretaria.content]} />
          </>
        )}
        {planoAcaoQuery.isLoading && (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </div>
      
    </div>
  )
}
