import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import { TSituacao, createSituacao, updateSituacao } from "../../providers/situacao";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import './leaflet.css';
import GooglePlacesAutocomplete, { geocodeByLatLng, geocodeByPlaceId } from "react-google-places-autocomplete";
import { findCityInAddressComponents, findPostalCodeInAddressComponents, findStateInAddressComponents } from "../../providers/geocode";
import { TLocalizacao, createLocalizacao, deleteLocalizacao, findLocalizacaoBySituacaoId, updateLocalizacao } from "../../providers/localizacao";
import { useNavigate } from "react-router-dom";
import { RiCloseCircleLine } from "react-icons/ri";
import InputMask from 'react-input-mask';
import { differenceInDays, parse } from "date-fns";


type IProps = {
  open: boolean;
  handleOpen: () => void;
  situacao?: TSituacao;
}

type TFormData = {
  nome: string;
  tipoCriseEnum: string;
  frequenciaOcorrenciaEnum: string;
  gravidadeOcorrenciaEnum: string;
  dataInicial:string;
  dataFinal:string;
}

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  tipoCriseEnum: yup.string().required('Campo obrigatório'),
  frequenciaOcorrenciaEnum: yup.string().required('Campo obrigatório'),
  gravidadeOcorrenciaEnum: yup.string().required('Campo obrigatório'),
})

type LatLng = {
  lat: number;
  lng: number;
}

export function DialogFormSituacao({handleOpen, open, situacao = {} as TSituacao}: IProps) {
  const [isSave, setIsSave] = useState(false);
  const [positions, setPositions] = useState<TLocalizacao[]>([]);
  const [positionsRemove, setPositionsRemove] = useState<TLocalizacao[]>([]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [bounds, setBounds] = useState<any>([])
  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  function handleDismiss() {
    methods.reset();
    setPositions([]);
    setPositionsRemove([]);
    handleOpen();
  }

  useEffect(() => {
    if (open) {
      if (!!situacao.nome) {
        methods.setValue('nome', situacao.nome)
      }
      if (!!situacao.frequenciaOcorrenciaEnum) {
        methods.setValue('frequenciaOcorrenciaEnum', situacao.frequenciaOcorrenciaEnum)
      }
      if (!!situacao.tipoCriseEnum) {
        methods.setValue('tipoCriseEnum', situacao.tipoCriseEnum)
      }
      if (!!situacao.gravidadeOcorrenciaEnum) {
        methods.setValue('gravidadeOcorrenciaEnum', situacao.gravidadeOcorrenciaEnum)
      }

      if (!!situacao.dataInicial) {
        const dataInicial = formatDate(situacao.dataInicial);
        methods.setValue('dataInicial', dataInicial)
      }

      if (!!situacao.dataFinal) {
        const dataFinal = formatDate(situacao.dataFinal);
        methods.setValue('dataFinal', dataFinal)
      }
  
      loadLocalizacoes();
    }


  }, [open, situacao])

  async function loadLocalizacoes() {
    if (!!situacao.id) {
      const response = await findLocalizacaoBySituacaoId(situacao.id);
      if (response.length > 0) {
        setPositions(response);

        const arr: any = []

        response.forEach(l => {
          arr.push([l.latitude, l.longitude])
        })

        bounds.push(arr);

      }
    }
  }


  const onSubmit: SubmitHandler<TFormData> = async (params) => {

    try {
      setIsSave(true)

      let dataInicial = '';
      let dataFinal = '';

      if (!!params.dataInicial) {
        const dataInicialDate = parse(params.dataInicial, 'dd/MM/yyyy', new Date());
        dataInicial = dataInicialDate.toISOString();
      }

      if (!!params.dataFinal) {
        const dataFinalDate = parse(params.dataFinal, 'dd/MM/yyyy', new Date());
        dataFinal = dataFinalDate.toISOString();
      }

      if (!!params.dataInicial && !!params.dataFinal) {
        const dataInicialDate = parse(params.dataInicial, 'dd/MM/yyyy', new Date());
        const dataFinalDate = parse(params.dataFinal, 'dd/MM/yyyy', new Date());

        const diff = differenceInDays(dataFinalDate, dataInicialDate);

        if (diff < 0) {
          toast('Data final deve ser maior ou igual a data inicial', {type: 'error'});
          setIsSave(false)
          return;
        }
      } 

      if (!!situacao.id) {
        await updateSituacao(situacao.id, {
          ...params,
          dataInicial,
          dataFinal
        });
        toast('Situação atualizada com sucesso', {type: 'success'});
        queryClient.resetQueries([situacao.id, 'situacao']);
        queryClient.invalidateQueries([situacao.id, 'situacao']);

        try {
          if (positions.length > 0) {
            for (let i = 0; i < positions.length; i++) {
              if (!!positions[i].id && !!positions[i].municipio) {
                await updateLocalizacao(positions[i].id!, {
                  ...positions[i],
                  situacaoId: situacao.id,
                } as any);
              } else {
  
                await createLocalizacao({
                  ...positions[i],
                  situacaoId: situacao.id,
                });
              }
            }
          }
        } catch {

        }

        if (positionsRemove.length > 0) {
          for (let i = 0; i < positionsRemove.length; i++) {
            if (!!positionsRemove[i].id) {
              await deleteLocalizacao(positionsRemove[i].id!);
            }
          }
        }

        queryClient.resetQueries([situacao.id, 'find-localizacao-by-situcao-id']);
        queryClient.invalidateQueries([situacao.id, 'find-localizacao-by-situcao-id']);

      } else {
        const situacaoResponse = await createSituacao({
          ...params,
          dataInicial,
          dataFinal
        })
        toast('Situação criada com sucesso', {type: 'success'});
        queryClient.resetQueries(['all-situacao']);
        queryClient.invalidateQueries(['all-situacao']);
        
        if (positions.length > 0) {
          for (let i = 0; i < positions.length; i++) {
            await createLocalizacao({
              ...positions[i],
              situacaoId: situacaoResponse.id,
            });
          }
        }
        queryClient.resetQueries([situacaoResponse.id, 'find-localizacao-by-situcao-id']);
        queryClient.invalidateQueries([situacaoResponse.id, 'find-localizacao-by-situcao-id']);

        navigate(`/internal/sala-situacao/${situacaoResponse.id}`)
      }

      setIsSave(false)
      handleDismiss();



    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar situação', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  function formatDate(date: string) {
    try {
      const splitDate = date.split('T');
      const splitDate2 = splitDate[0].split('-');
      return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
      return new Date(date).toLocaleDateString('pt-BR');
    } catch(err) {
      return ''
    }
  }

  const onError = () => {

  }

  function removeLocalizaca(localizacao: TLocalizacao, ix: number) {
    if (!!localizacao.id) {
      setPositionsRemove(prev => [...prev, localizacao])
    }
    setPositions(prev => prev.filter(p => p.latitude !== localizacao.latitude))
  }

  const [position, setPosition] = useState<LatLng>({lat: 0.035574, lng: -51.070534 });
  
  
  
  function LocationMarker() {
    const map = useMapEvents({
      click(e) {
      },
    })

    map.flyToBounds([[position.lat, position.lng]]);

    if (bounds.length)  {
      map.flyToBounds(bounds);
    }

    return <></>
  }


  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{!!situacao.id ? `Editar` : 'Nova'} situação</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="lg:flex lg:flex-row lg:items-center lg:justify-between mt-[16px]">
              <div className="lg:flex-1">
                <Controller
                  name="nome"
                  render={({field, fieldState}) => (
                    <Input label="Situação" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                  )}
                />
              </div>
              <div className="w-[16px] mt-[16px] lg:mt-0"></div>
              <div>
                <Controller
                  name="dataInicial"
                  render={({field, fieldState}) => (
                    <InputMask mask="99/99/9999" value={field.value} onChange={field.onChange} >
                      {((inputProps: any) => {
                        return <Input {...inputProps} label="Data inicial" type="tel" error={!!fieldState.error} size="lg" />
                      }) as any}
                    </InputMask>
                  )}
                />
              </div>
              <div className="w-[16px] mt-[16px] lg:mt-0"></div>
              <div>
                <Controller
                  name="dataFinal"
                  render={({field, fieldState}) => (
                    <InputMask mask="99/99/9999" value={field.value} onChange={field.onChange} >
                      {((inputProps: any) => {
                        return <Input {...inputProps} label="Data final" type="tel" error={!!fieldState.error} size="lg" />
                      }) as any}
                    </InputMask>
                  )}
                />
              </div>

            </div>
            <div className="lg:flex lg:flex-row lg:items-center lg:justify-between mt-[16px]">
              <Controller
                name="tipoCriseEnum"
                render={({field, fieldState}) => (
                  <Select label="Tipo de crise" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    <Option value="SAUDE">Saúde</Option>
                    <Option value="INFRAESTRUTURA">Infraestrutura</Option>
                    <Option value="CIDADANIA">Cidadania</Option>
                    <Option value="MEIO_AMBIENTE">Meio ambiente</Option>
                    <Option value="SEGURANCA_PUBLICA">Segurança pública</Option>
                    <Option value="EDUCACAO">Educação</Option>
                    <Option value="POLITICA">Política</Option>
                    <Option value="OUTRO">Outro</Option>
                  </Select>
                )}
              />
              
              <div className="w-[16px] mt-[16px] lg:mt-0"></div>
              <Controller
                name="frequenciaOcorrenciaEnum"
                render={({field, fieldState}) => (
                  <Select label="Frequência da ocorrência" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    <Option value="ANUAL">Anual</Option>
                    <Option value="SEMESTRAL">Semestral</Option>
                    <Option value="MENSAL">Mensal</Option>
                    <Option value="SEMANAL">Semanal</Option>
                    <Option value="DIARIA">Diária</Option>
                    <Option value="EXCEPCIONALIDADE">Excepcionalidade</Option>
                  </Select>
                )}
              />
              <div className="w-[16px] mt-[16px] lg:mt-0"></div>
              <Controller
                name="gravidadeOcorrenciaEnum"
                render={({field, fieldState}) => (
                  <Select label="Gravidade da ocorrência" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    <Option value="GRAVE">Grave</Option>
                    <Option value="MEDIA">Media</Option>
                    <Option value="LEVE">Leve</Option>
                  </Select>
                )}
              />
            </div>
            <div className="mt-[16px]">
              <GooglePlacesAutocomplete 
                apiKey="AIzaSyBfpn-oks9BVxtQNyAg9JvT-ZxWgu7x-7Y" 
                apiOptions={{language: 'pt-br', region: 'pt-br'}}
                selectProps={{
                  loadingMessage: () => 'Carregando...',
                  noOptionsMessage: () => 'Nenhuma localidade encontrada',
                  placeholder: <div>Selecione uma localidade</div>,
                  onChange(newValue, actionMeta)
                  {

                    if (actionMeta.action === 'select-option') {
                      geocodeByPlaceId(newValue?.value.place_id).then((result) => {

                        if (result.length > 0) {

                          const city = findCityInAddressComponents(result[0].address_components);
                          const state = findStateInAddressComponents(result[0].address_components)
                          const postalCode = findPostalCodeInAddressComponents(result[0].address_components);
                          const lat: number = result[0].geometry.location.lat();
                          const lng: number = result[0].geometry.location.lng();

                          const localizacao: Omit<TLocalizacao, 'id'> = {
                            cep: postalCode || 72000000,
                            endereco: result[0].formatted_address,
                            latitude: lat,
                            longitude: lng,
                            municipio: city || state,
                            situacaoId: 1
                          }

                          if (!localizacao.municipio) {
                            toast('A localidade selecionada não é um município', {type: 'error'});
                            return;
                          }

                          setPositions(prev => [...prev, localizacao]);
                          setPosition({lat, lng})

                        }
                      })
                    }
                  },
                  // isMulti: true as any
                }}
              />
            </div>
            <div className="mt-[16px]">
              <MapContainer bounds={bounds.length > 0 ? bounds : [[0.035574, -51.070534]]} zoom={13} scrollWheelZoom={false} >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {positions.length > 0 && positions.map(i => (
                  <Marker position={[i.latitude, i.longitude]} key={`${i.latitude}${i.longitude}`}>
                    <Popup>
                      {i.endereco}
                    </Popup>
                  </Marker>
                ))}
                <LocationMarker />
              </MapContainer>
            </div>

            {positions.length > 0 && (
              <div className="mt-[16px]">
                {positions.map((i, ix) => (
                  <div key={i.latitude} className="w-full rounded-[4px] bg-[#F5F8FA] p-[8px] flex flex-row items-center justify-between mb-[8px]">
                    <label className="font-medium">{i.endereco}</label>
                    <button onClick={() => removeLocalizaca(i, ix)}>
                      <RiCloseCircleLine size={24} color="#EC407A" />
                    </button>
                  </div>
                ))}
              </div>
            )}
            
            <div className="mt-[24px] flex flex-row justify-end">
              <div className="">
                <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleDismiss}>Cancelar</Button>
              </div>
              <div className="w-[8px]"></div>
              <div className="">
                <Button 
                  className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" variant="outlined"
                  onClick={methods.handleSubmit(onSubmit, onError)}
                  disabled={isSave}
                >
                  {isSave ? <Spinner color="blue" /> : `${!!situacao.id ? `Editar` : 'Adicionar'} situação`}
                  
                </Button>
              </div>
            </div>
          </FormProvider>
        </div>
        
      </Card>
      <ToastContainer />
    </Dialog>
  )
}