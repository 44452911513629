import { Button, Card, Dialog, Input } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { TEstrategia, deleteEstrategia } from "../../providers/estrategia";
import { format, parseISO } from "date-fns";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { DialogFormEstrategia } from "../DialogFormEstrategia";
import { setValueMasks } from "../../utils/number.utils";
import { formatDateString } from "../../utils/date.utils";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  data: TEstrategia;
}

export function DialogViewEstrategia({handleOpen, open, data}: IProps) {
  const [dialogRemove, setDialogRemove] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [dialogFormEstrategia, setDialogFormEstrategia] = useState(false);
  const queryClient = useQueryClient();

  function handleDialogRemove() {
    setDialogRemove(prev => !prev);
  }

  function handleDialogForm() {
    setDialogFormEstrategia(prev => !prev);
  }

  async function handleRemove() {
    try {
      setLoadingRemove(true);
      await deleteEstrategia(data.id);
      await queryClient.resetQueries([data.situacaoId, 'find-estrategia-by-situcao-id']);
      await queryClient.invalidateQueries([data.situacaoId, 'find-estrategia-by-situcao-id']);
      toast('Estratégia excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleOpen();
      handleDialogRemove();
    } catch (err) {
      setLoadingRemove(false);
      toast('Erro ao excluir estratégia', {type: 'error'});
    }
  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between pb-[24px] border-b border-b-[#E4E4E4]">
            <div className="flex-[0.8]">
              <h1 className="text-dark-base text-3xl font-semibold">{data.nome}</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <div>
            <div className="mt-[24px]">
              <label className="font-light text-dark-tint">Escopo</label>
              <p className="text-dark-base text-base">{data.descricao}</p>
            </div>
            <div className="mt-[24px] flex flex-row items-center">
              <div className="flex-[0.5]">
                <label className="font-light text-dark-tint">Recurso estimado</label>
                <p className="text-dark-base text-base">{data.recursoEstimado}</p>
              </div>
              <div className="w-[16px]" />
              <div>
                <label className="font-light text-dark-tint">Custo previsto</label>
                <p className="text-dark-base text-base">R$ {setValueMasks(data.custoPrevisto || 0)}</p>
              </div>
            </div>
            <div className="mt-[24px]">
              <label className="font-light text-dark-tint">Prazo estimado</label>
              <p className="text-dark-base text-base">{formatDateString(data.prazoEstimado)}</p>
            </div>
            
            <div className="mt-[24px] flex flex-row justify-end">
              <Button className="normal-case text-sm font-medium bg-[#EB5757] text-white border-[#EB5757]" variant="outlined" onClick={handleDialogRemove}>
                Excluir
              </Button>
              <div className="w-[16px]" />
              <Button className="normal-case text-sm font-medium text-blue-button bg-white border-blue-button" variant="outlined" onClick={handleDialogForm}>Editar</Button>
            </div>
          </div>
        </div>
        <ToastContainer />
        <DialogRemoveItem handleOpen={handleDialogRemove} open={dialogRemove} handleConfirm={handleRemove} loadingConfirm={loadingRemove}  />
        <DialogFormEstrategia handleOpen={handleDialogForm} open={dialogFormEstrategia} estrategia={data} idSituacao={data.situacaoId} />
      </Card>
    </Dialog>
  )
}