import { Card, Dialog } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useFindLocalizacaoBySituacaoId } from "../../../hooks/query/useLocalizacaoQuery";
import { useMemo } from "react";


type IProps = {
  open: boolean;
  handleOpen: () => void;
  idSituacao: number;
}

export function DialogLocalizacao({handleOpen, open, idSituacao}: IProps) {
  const localizacaoQuery = useFindLocalizacaoBySituacaoId(idSituacao);
  const localizacoes = localizacaoQuery.data || [];
  
  const bounds = useMemo(() => {
    const positions: any[] = [];
    if (localizacoes.length > 0) {
      localizacoes.forEach(l => {
        positions.push([l.latitude, l.longitude])
      })
    }

    return positions
  }, [localizacoes])

  return (
    <>
      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="p-[40px]">
          <div>
            <div className="flex flex-row items-center justify-between pb-[24px] border-b border-b-[#E4E4E4]">
              <div>
                <h1 className="text-dark-base text-3xl font-semibold">Mapa da Situação</h1>
              </div>
              <div>
                <button onClick={handleOpen}>
                  <HiX size={24} color="#425466" />
                </button>
              </div>
            </div>
            <div className="mt-[24px]">
              <MapContainer bounds={bounds} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {!localizacaoQuery.isLoading && localizacaoQuery.isSuccess && localizacoes.length > 0 && localizacoes.map(i => (
                  <Marker position={[i.latitude, i.longitude]} key={`${i.latitude}${i.longitude}`}>
                    <Popup>
                      {i.endereco}
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            </div>
            <div className="mt-[24px]">
              <h4 className="text-dark-tint font-light text-sm">Locais afetados</h4>
              {!localizacaoQuery.isLoading && localizacaoQuery.isSuccess && localizacoes.length && localizacoes.map(i => (
                <p className="text-dark-base font-normal text-base">{i.endereco}</p>
              ))}
            </div>
          </div>
        </Card>
        <ToastContainer />
      </Dialog>
    </>
  )
}