import { useQuery } from "react-query"
import { allEstrategia, findEstrategia, findEstrategiaBySituacaoId } from "../../providers/estrategia";

export const useAllEstrategia = () => {
  return useQuery(['all-estrategia'], async () => await allEstrategia());
}

export const useFindEstrategia = (id: number) => {
  return useQuery([id, 'find-estrategia'], async () => await findEstrategia(id));
}

export const useFindEstrategiaBySituacaoId = (idSituacao: number) => {
  return useQuery([idSituacao, 'find-estrategia-by-situcao-id'], async () => await findEstrategiaBySituacaoId(idSituacao));
}