import axios from 'axios';

const { GOOGLE_MAPS_KEY } = process.env;

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export interface ReverseGeocodingResult {
  country: string;
  state: string;
  city: string;
  postalCode: string;
  neighborhood: string;
  street: string;
  doorNumber: string;
}

export interface GeocodingResult {
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
}

interface MapsApiReverseGeocodingResponse {
  results: Array<{
    address_components: AddressComponent[];
  }>;
}

interface MapsApiGeocodingResponse {
  results: GeocodingResult[];
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: Array<
    | 'administrative_area_level_1'
    | 'administrative_area_level_2'
    | 'administrative_area_level_3'
    | 'administrative_area_level_4'
    | 'administrative_area_level_5'
    | 'archipelago'
    | 'colloquial_area'
    | 'continent'
    | 'country'
    | 'establishment'
    | 'finance'
    | 'floor'
    | 'food'
    | 'general_contractor'
    | 'geocode'
    | 'health'
    | 'intersection'
    | 'landmark'
    | 'locality'
    | 'natural_feature'
    | 'neighborhood'
    | 'place_of_worship'
    | 'plus_code'
    | 'point_of_interest'
    | 'political'
    | 'post_box'
    | 'postal_code'
    | 'postal_code_prefix'
    | 'postal_code_suffix'
    | 'postal_town'
    | 'premise'
    | 'room'
    | 'route'
    | 'street_address'
    | 'street_number'
    | 'sublocality'
    | 'sublocality_level_1'
    | 'sublocality_level_2'
    | 'sublocality_level_3'
    | 'sublocality_level_4'
    | 'sublocality_level_5'
    | 'subpremise'
    | 'town_square'
  >;
}

export async function getAddressCoordinates(
  address: string,
  token: string
): Promise<undefined | Coordinates> {
  const { data } = await axios.get<MapsApiGeocodingResponse>(
    'https://maps.googleapis.com/maps/api/geocode/json',
    {
      params: {
        address,
        key: GOOGLE_MAPS_KEY,
        sessiontoken: token,
      },
    }
  );

  if (!data.results.length) {
    return undefined;
  }

  const result = data.results[0];
  if (!result) {
    return undefined;
  }

  return {
    latitude: result.geometry.location.lat,
    longitude: result.geometry.location.lng,
  };
}

export async function getCoordinatesAddress(
  coordinates: Coordinates,
  token: string
) {
  const { data } = await axios.get<MapsApiReverseGeocodingResponse>(
    'https://maps.googleapis.com/maps/api/geocode/json',
    {
      params: {
        latlng: `${coordinates.latitude},${coordinates.longitude}`,
        language: 'pt-BR',
        key: GOOGLE_MAPS_KEY,
        sessiontoken: token,
      },
      timeout: 60000,
    }
  );

  let result = {} as ReverseGeocodingResult;

  if (data?.results?.length > 0) {
    for (let i = 0; i < data?.results?.length; i++) {
      if (!result?.country) {
        result.country = findCountryInAddressComponents(
          data.results[i].address_components
        );
      }
      if (!result?.state) {
        result.state = findStateInAddressComponents(
          data.results[i].address_components
        );
      }
      if (!result?.city) {
        result.city = findCityInAddressComponents(
          data.results[i].address_components
        );
      }
      if (!result?.postalCode) {
        result.postalCode = findPostalCodeInAddressComponents(
          data.results[i].address_components
        );
      }
      if (!result?.neighborhood) {
        result.neighborhood = findNeighborhoodInAddressComponents(
          data.results[i].address_components
        );
      }
      if (!result?.street) {
        result.street = findStreetInAddressComponents(
          data.results[i].address_components
        );
      }
      if (!result?.doorNumber) {
        result.doorNumber = findDoorNumberInAddressComponents(
          data.results[i].address_components
        );
      }
    }
  }

  return result;
}

function findCountryInAddressComponents(addressComponents: AddressComponent[]) {
  return (
    addressComponents.find((it) => it.types.includes('country'))?.short_name ??
    ''
  );
}

export function findStateInAddressComponents(addressComponents: any[]) {
  console.log(addressComponents)
  return (
    addressComponents.find((it) =>
      it.types.includes('administrative_area_level_1')
    )?.long_name ?? ''
  );
}

export function findCityInAddressComponents(addressComponents: any[]) {
  return (
    addressComponents.find((it) =>
      it.types.includes('administrative_area_level_2')
    )?.long_name ?? ''
  );
}

export function findPostalCodeInAddressComponents(
  addressComponents: any[]
) {
  return (
    addressComponents.find((it) => it.types.includes('postal_code'))
      ?.long_name ?? ''
  );
}

function findNeighborhoodInAddressComponents(
  addressComponents: AddressComponent[]
) {
  return (
    addressComponents.find((it) => it.types.includes('sublocality_level_1'))
      ?.long_name ?? ''
  );
}

function findStreetInAddressComponents(addressComponents: AddressComponent[]) {
  return (
    addressComponents.find((it) => it.types.includes('route'))?.short_name ?? ''
  );
}

function findDoorNumberInAddressComponents(
  addressComponents: AddressComponent[]
) {
  return (
    addressComponents.find((it) => it.types.includes('street_number'))
      ?.long_name ?? ''
  );
}
