import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { format, parse, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { HiX } from "react-icons/hi";
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';
import { useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import * as yup from 'yup';
import { useAllPlanoAcaoStatus } from "../../hooks/query/usePlanoAcaoQuery";
import { TPlanoAcao, createPlanoAcao, updatePlanoAcao } from "../../providers/plano-acao";
import { useAllSecretaria } from '../../hooks/query/useSecretariaQuery';

type IProps = {
  open: boolean;
  handleOpen: () => void;
  planoAcao?: TPlanoAcao;
  idSituacao?: number;
  idProjeto?: number;
}

type TFormData = Omit<TPlanoAcao, 'id'>;

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  statusId: yup.number().required('Campo obrigatório'),
  secretariaId: yup.number().required('Campo obrigatório'),
  // atuacao: yup.string().required('Campo obrigatório'),
  responsavel: yup.string().required('Campo obrigatório'),
  // recursosNecessarios: yup.string().required('Campo obrigatório'),
  // custoPrevisto: yup.number().required('Campo obrigatório'),
  prazoAcao: yup.string().required('Campo obrigatório'),
})

export function DialogFormPlanoAcao({handleOpen, open, idSituacao = 0, idProjeto = 0, planoAcao = {} as TPlanoAcao}: IProps) {
  const [isSave, setIsSave] = useState(false);

  const planoAcaoStatusQuery = useAllPlanoAcaoStatus();
  const planoAcaoStatus = planoAcaoStatusQuery.data || [];
  const queryClient = useQueryClient();
  const inputRef = useRef<any>(null);

  const secretariaQuery = useAllSecretaria();
  const secretarias = secretariaQuery.data || [];

  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  function handleDismiss() {
    methods.reset();
    handleOpen();
  }

  useEffect(() => {
    if (open) {

      if (!!planoAcao.nome) {
        methods.setValue('nome', planoAcao.nome)
      }

      if (!!planoAcao.atuacao) {
        methods.setValue('atuacao', planoAcao.atuacao)
      }

      if (!!planoAcao.custoPrevisto) {
        methods.setValue('custoPrevisto', String(planoAcao.custoPrevisto) as any)
      }

      if (!!planoAcao.prazoAcao) {
        console.log(planoAcao.prazoAcao)
        const dt = formatDate(planoAcao.prazoAcao)
        methods.setValue('prazoAcao', dt)
      }

      if (!!planoAcao.statusId) {
        methods.setValue('statusId', String(planoAcao.statusId) as any);
      }

      if (!!planoAcao.recursosNecessarios) {
        methods.setValue('recursosNecessarios', planoAcao.recursosNecessarios);
      }

      if (!!planoAcao.secretariaId) {
        methods.setValue('secretariaId', String(planoAcao.secretariaId) as any);
      }

      if (planoAcao.secretaria && !!planoAcao.secretaria.id) {
        methods.setValue('secretariaId', String(planoAcao.secretaria.id) as any);
      }

      if (!!planoAcao.responsavel) {
        methods.setValue('responsavel', planoAcao.responsavel);
      }
    }

  }, [open, planoAcao])

  function formatDate(date: string) {
    try {
      const splitDate = date.split('T');
      const splitDate2 = splitDate[0].split('-');
      return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
      return new Date(date).toLocaleDateString('pt-BR');
    } catch(err) {
      return ''
    }
  }

  const onSubmit: SubmitHandler<TFormData> = async (params) => {
    try {

      setIsSave(true);

      const prazoAcaoDate = parse(params.prazoAcao, 'dd/MM/yyyy', new Date());

      let custoPrevisto = null;
      if (!!inputRef.current?.value) {
        custoPrevisto = inputRef.current.value.replace('R$ ', '');
        custoPrevisto = custoPrevisto.replaceAll('.', '');
        custoPrevisto = custoPrevisto.replace(',', '.');
      }

      const formatParams: any = {
        ...params,
        statusId: Number(params.statusId),
        secretariaId: Number(params.secretariaId),
        prazoAcao: prazoAcaoDate.toISOString(),
        custoPrevisto
      }

      if (!!idSituacao) {
        formatParams.situacaoId = idSituacao;
      }

      if (!!idProjeto) {
        formatParams.projetoId = idProjeto;
      }

      if (!!planoAcao.id) {

        await updatePlanoAcao(planoAcao.id, formatParams)
        toast('Plano de ação atualizado com sucesso', {type: 'success'});
      } else {
        await createPlanoAcao(formatParams);
        toast('Plano de ação criado com sucesso', {type: 'success'});
      }

      setIsSave(false)
      handleDismiss();

      if (idSituacao) {
        queryClient.resetQueries([idSituacao, 'find-plano-acao-by-situcao-id']);
        queryClient.invalidateQueries([idSituacao, 'find-plano-acao-by-situcao-id']);
      }

      if (idProjeto) {
        queryClient.resetQueries([idProjeto, 'find-plano-acao-by-projeto-id']);
        queryClient.invalidateQueries([idProjeto, 'find-plano-acao-by-projeto-id']);
      }

      if (!!params.secretariaId) {
        queryClient.resetQueries(['plano-acao-agenda']);
        queryClient.invalidateQueries(['plano-acao-agenda']);
      }

      queryClient.resetQueries(['plano-acao']);
      queryClient.invalidateQueries(['plano-acao']);

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar plano de ação', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {

  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{planoAcao.id ? 'Editar' : 'Nova'} ação</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="mt-[16px]">
              <Controller
                name="nome"
                render={({field, fieldState}) => (
                  <Input label="Nome" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              {/* <Controller
                name="atuacao"
                render={({field, fieldState}) => (
                  <Input label="Atuação" type="string" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              <div className="w-[16px]"></div> */}
              <Controller
                name="custoPrevisto"
                render={({field, fieldState}) => (
                  <NumericFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    decimalScale={2}
                    value={field.value}
                    customInput={(props: any) => <Input label="Custo previsto" type="number" crossOrigin={'*'} size="lg" {...props} inputRef={inputRef} error={fieldState.error} />}
                  />
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="responsavel"
                render={({field, fieldState}) => (
                  <Input label="Responsável" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              {!planoAcaoStatusQuery.isLoading && planoAcaoStatusQuery.isSuccess && (
                <>
                  <div className="w-[16px]"></div>
                  <Controller
                    name="statusId"
                    render={({field, fieldState}) => (
                      <Select label="Status" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                        {planoAcaoStatus.map(is => (
                          <Option value={String(is.id)} key={is.id}>{is.nome}</Option>
                        ))}
                      </Select>
                    )}
                  />
                </>
              )}
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="recursosNecessarios"
                render={({field, fieldState}) => (
                  <Input label="Recurso necessário" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              <div className="w-[16px]"></div>
              <Controller
                name="prazoAcao"
                render={({field, fieldState}) => (
                  <InputMask mask="99/99/9999" value={field.value} onChange={field.onChange} >
                    {((inputProps: any) => {
                      return <Input {...inputProps} label="Prazo estimado" type="tel" error={!!fieldState.error} />
                    }) as any}
                  </InputMask>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              {!secretariaQuery.isLoading && secretariaQuery.isSuccess && (
                <>
                  
                  <Controller
                    name="secretariaId"
                    render={({field, fieldState}) => (
                      <Select label="Secretaria" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                        {secretarias.map(is => (
                          <Option value={String(is.id)} key={is.id}>{is.nome}</Option>
                        ))}
                      </Select>
                    )}
                  />
                </>
              )}
            </div>
            <div className="mt-[24px] flex flex-row justify-end">
              <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleDismiss}>Cancelar</Button>
              <div className="w-[8px]"></div>
              <Button
                className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button"
                variant="outlined"
                disabled={isSave}
                onClick={methods.handleSubmit(onSubmit, onError)}
              >
                {isSave ? <Spinner color="blue" /> : `${!!planoAcao.id ? 'Editar' : 'Adicionar'} plano de ação`}
              </Button>
            </div>
          </FormProvider>
        </div>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}