import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useImpactoStatus } from "../../hooks/query/useImpactoQuery";
import { TImpacto, createImpacto, updateImpacto } from "../../providers/impacto";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import InputMask from 'react-input-mask';
import { NumericFormat } from "react-number-format";
import { createDemandaAtuacao, demandasAtuacaoById, updateDemandasAtuacao } from "../../providers/demandas";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  demandaId: number;
  atuacaoId?: number;
}

type TFormData = any

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  descricao: yup.string().required('Campo obrigatório'),
})

export function DialogFormDemandaAtuacao({handleOpen, open, demandaId, atuacaoId = 0}: IProps) {
  const [isSave, setIsSave] = useState(false);

  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  function handleDismiss() {
    methods.reset();
    handleOpen();
  }

  useEffect(() => {
    async function init() {
      const response = await demandasAtuacaoById(atuacaoId);
      methods.setValue('descricao', response.descricao);
    }

    if (atuacaoId && open) {
      init();
    }
  }, [atuacaoId, open])

  const onSubmit: SubmitHandler<TFormData> = async (params) => {
    try {
      setIsSave(true);

      if (atuacaoId) {
        await updateDemandasAtuacao(atuacaoId, {
          ...params,
          demandaId
        })
        toast('Atuação atualizada com sucesso', {type: 'success'});
      } else {
        await createDemandaAtuacao({
          ...params,
          demandaId
        })
        toast('Atuação criada com sucesso', {type: 'success'});
      }

      handleDismiss();
      setIsSave(false);
      methods.reset();

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar demanda', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {

  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{atuacaoId ? 'Editar' : 'Nova'}   Atuação</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="descricao"
                render={({field, fieldState}) => (
                  <Input label="Atuação"  crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="mt-[24px] flex flex-row justify-end">
              <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
              <div className="w-[8px]" />
              <Button 
                className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                variant="outlined" 
                disabled={isSave}
                onClick={methods.handleSubmit(onSubmit, onError)}
              >
                {atuacaoId ? (
                  <>
                    {isSave ? <Spinner color="blue" /> : `Salvar`}
                  </>
                ) : (
                  <>
                    {isSave ? <Spinner color="blue" /> : `Adicionar atuação`}
                  </>
                )}
              </Button>
            </div>
          </FormProvider>
        </div>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}