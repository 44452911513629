import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { TCenario, createCenario, updateCenario } from "../../providers/cenario";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from "react-query";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  cenario?: TCenario;
  situacaoId: number;
}

type TFormData = {
  nome: string;
  evento: string;
  consequencia: string;
  expectativaEnum: 'OTIMISTA' | 'PESSIMISTA'|'NEUTRO';
}

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  evento: yup.string().required('Campo obrigatório'),
  consequencia: yup.string().required('Campo obrigatório'),
  expectativaEnum: yup.string().required('Campo obrigatório'),
})

export function DialogFormCenario({handleOpen, open, situacaoId, cenario = {} as TCenario}: IProps) {
  const [isSave, setIsSave] = useState(false);
  const queryClient = useQueryClient();

  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  function dismiss() {
    methods.reset();
    handleOpen();
  }

  useEffect(() => {
    if (!!cenario.nome) {
      methods.setValue('nome', cenario.nome)
    }

    if (!!cenario.evento) {
      methods.setValue('evento', cenario.evento)
    }

    if (!!cenario.consequencia) {
      methods.setValue('consequencia', cenario.consequencia)
    }

    if (!!cenario.expectativaEnum) {
      methods.setValue('expectativaEnum', cenario.expectativaEnum)
    }
  }, [cenario])

  const onSubmit: SubmitHandler<TFormData> = async (params) => {
    try {
      setIsSave(true);

      if (!!cenario.id) {
        await updateCenario(cenario.id, {
          ...params,
          situacaoId
        })
        toast('Cenário atualizado com sucesso', {type: 'success'});
      } else {
        await createCenario({
          ...params,
          situacaoId
        });
        toast('Cenário criado com sucesso', {type: 'success'});
      }


      setIsSave(false)
      dismiss();
      
      queryClient.resetQueries([situacaoId, 'find-cenario-by-situacao-id']);
      queryClient.invalidateQueries([situacaoId, 'find-cenario-by-situacao-id']);

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar cenário', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {

  }

  return (
    <>
      <Dialog
        size="lg"
        open={open}
        handler={dismiss}
        className="bg-transparent shadow-none"
      >
        <Card className="p-[24px]">
          <div>
            <div className="flex flex-row items-center justify-between">
              <div>
                <h1 className="text-dark-base text-3xl font-semibold">{!!cenario.id ? 'Editar cenário' : 'Novo cenário'} </h1>
              </div>
              <div>
                <button onClick={dismiss}>
                  <HiX size={24} color="#425466" />
                </button>
              </div>
            </div>
            <FormProvider {...methods}>
              <div className="mt-[16px]">
                <Controller
                  name="nome"
                  render={({field, fieldState}) => (
                    <Input label="Nome do cenário" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                  )}
                />
              </div>
              <div className="mt-[16px]">
                <Controller
                  name="evento"
                  render={({field, fieldState}) => (
                    <Input label="Evento/Condição" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                  )}
                />
              </div>
              <div className="mt-[16px]">
                <Controller
                  name="consequencia"
                  render={({field, fieldState}) => (
                    <Input label="Consequência" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                  )}
                />
              </div>
              <div className="flex flex-row items-center justify-between mt-[16px]">
                <div className="flex-[0.5]">
                  <Controller 
                    name="expectativaEnum"
                    render={({field, fieldState}) => (
                      <Select label="Sentimento" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                        <Option value="OTIMISTA">Otimista</Option>
                        <Option value="PESSIMISTA">Pessimista</Option>
                        <Option value="NEUTRO">Neutro</Option>
                      </Select>
                    )}
                  />
                </div>
              </div>
              <div className="mt-[24px] flex flex-row justify-end">            
                
                <Button 
                  className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                  variant="outlined" 
                  disabled={isSave}
                  onClick={methods.handleSubmit(onSubmit, onError)}>
                    {isSave ? <Spinner color="blue" /> : `${!!cenario.id ? 'Editar' : 'Adicionar'} cenário`}
                  </Button>
              </div>
            </FormProvider>
          </div>
        </Card>
        <ToastContainer />
      </Dialog>
    </>
  )
}