import { Button } from "@material-tailwind/react";
import Carousel from "react-multi-carousel";
import { CardProjeto } from "../CardProjeto";
import { EmptyState } from "../EmptyState";
import { useSituacaoProjeto } from "../../hooks/query/useSituacaoQuery";
import { useNavigate } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

type Props = {
  id: number;
}

export function BoxProjeto({id}: Props) {

  const navigate = useNavigate();
  const situacaoProjetoQuery = useSituacaoProjeto(id);
  const situacaoProjeto = situacaoProjetoQuery.data || [];

  function handleGoProjeto() {
    navigate('/internal/projeto?tab=list')
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-[16px]">
        <h1 className="text-dark-base text-lg font-normal">Projetos</h1>
        <div className="flex flex-row items-center">
          <span className="mr-3">{(!situacaoProjetoQuery.isLoading && situacaoProjetoQuery.isSuccess && situacaoProjeto.length)} projetos</ span>
          <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleGoProjeto}>Pesquisar</Button>
        </div>
      </div>

      {!situacaoProjetoQuery.isLoading && situacaoProjetoQuery.isSuccess && (
            <>
              {situacaoProjeto.length > 0 ? (
                <Carousel
                  responsive={responsive}
                  autoPlaySpeed={5000}
                  autoPlay
                  infinite
                  arrows={false}
                >
                  {situacaoProjeto.map((i) => (
                    <CardProjeto key={String(i.id)} data={i} />
                  ))}
                </Carousel>
              ) : (
                <div className="w-full">
                  <EmptyState text="Nenhum projeto encontrado" />
                </div>
              )}
            </>
          )}

      {/* <EmptyState text="Nenhum projeto encontrado" /> */}
      {/* <Carousel responsive={responsive} autoPlaySpeed={5000} autoPlay={true} infinite arrows={false}>
        <CardProjeto />
        <CardProjeto />
        <CardProjeto />
        <CardProjeto />
        <CardProjeto />
      </Carousel> */}
    </div>
  )
}