import { api } from "../services/api";

type TResponseAllCenario = {
  data: TCenario[];
}

type TResponseFindCenario = {
  data: TCenario;
}

export type TCenario = {
  id: number;
  nome: string;
  evento: string;
  consequencia: string;
  expectativaEnum: 'OTIMISTA' | 'PESSIMISTA'|'NEUTRO';
  situacaoId: number;
}

/**
 * Retorna todos os cenários
 * @returns 
 */
export async function allCenario(): Promise<TCenario[]> {
  try {
    const { data } = await api.get<TResponseAllCenario>('/cenarios');
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function findCenariosBySituacaoId(situacaoId: number): Promise<TCenario[]> {
  try {
    const { data } = await api.get<TResponseAllCenario>(`/cenarios?situacaoId=${situacaoId}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

/**
 * Retorna um cenario a partir de um id
 * @param id 
 * @returns 
 */
export async function findCenario(id: number): Promise<TCenario> {
  try {
    const { data } = await api.get<TResponseFindCenario>(`/cenarios/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}


/**
 * Criar um novo cenário
 * @param body 
 */
export async function createCenario(body: Omit<TCenario, 'id'>): Promise<TResponseFindCenario> {
  try {
    const { data } = await api.post<TResponseFindCenario>('/cenarios', body);
    return data;
  } catch (err) {
    throw err;
  }
}

/**
 * Edita um cenário
 * @param id 
 * @param body 
 * @returns 
 */
export async function updateCenario(id: number, body: Omit<TCenario, 'id'>) {
  try {
    const { data } = await api.put(`/cenarios/${id}`, body);
    return data;
  } catch (err) {
    throw err;
  }
}

/**
 * Exclui um cenário
 * @param id 
 * @returns 
 */
export async function removeCenario(id: number) {
  try {
    const { data } = await api.delete(`/cenarios/${id}`);
    return data;
  } catch(err) {
    throw err;
  }
}