import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { TBodyProjeto, TProjeto, updateProjeto } from "../../providers/projeto";
import { HiX } from "react-icons/hi";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useAllEtapa } from "../../hooks/query/useEtapaQuery";
import { useAllProjetoOrgaos, useAllProjetoRegistros, useAllProjetoStatus } from "../../hooks/query/useProjetoQuery";
import { useAllSecretaria } from "../../hooks/query/useSecretariaQuery";
import ReactInputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";
import { useEffect, useRef, useState } from "react";
import { formatDateString, formatDateStrToDateBr } from "../../utils/date.utils";
import { parse } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useAllEixo } from "../../hooks/query/useEixoQuery";
import { useUser } from "../../hooks/UserContext";

type TProps = {
  open: boolean;
  handleOpen: () => void;
  projeto?: TProjeto;
}

export function DialogFormEditProjeto({handleOpen, open, projeto}: TProps) {

  const { ...methods } = useForm<TBodyProjeto>();

  const [isSave, setIsSave] = useState(false)

  const etapaQuery = useAllEtapa();
  const etapas = etapaQuery.data || [];

  const eixoQuery = useAllEixo();
  const eixos = eixoQuery.data || [];

  const projetoStatusQuery = useAllProjetoStatus();
  const projetoStatus = projetoStatusQuery.data || [];

  const secretariaQuery = useAllSecretaria();
  const secretarias = secretariaQuery.data || [];

  const registroQuery = useAllProjetoRegistros();
  const registros = registroQuery.data || [];

  const inputValorGlobalRef = useRef<any>(null);
  const inputValorFinanceiroRef = useRef<any>(null);

  const projetoOrgaoQuery = useAllProjetoOrgaos();
  const projetoOrgao = projetoOrgaoQuery.data || [];

  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<TBodyProjeto> = async (params) => {
    try {

      setIsSave(true);

      let valorGlobal = null;
      if (!!inputValorGlobalRef.current?.value) {
        valorGlobal = inputValorGlobalRef.current.value.replace('R$ ', '');
        valorGlobal = valorGlobal.replaceAll('.', '');
        valorGlobal = valorGlobal.replace(',', '.');
      }

      let valorFinanceiro = null;
      if (!!inputValorFinanceiroRef.current?.value) {
        valorFinanceiro = inputValorFinanceiroRef.current.value.replace('R$ ', '');
        valorFinanceiro = valorFinanceiro.replaceAll('.', '');
        valorFinanceiro = valorFinanceiro.replace(',', '.');
      }

      let prazo = '';

      if (!!params.prazo) {
        const prazoDate = parse(params.prazo, 'dd/MM/yyyy', new Date());
        prazo = prazoDate.toISOString();
      }

      if (!!projeto?.id) {
        await updateProjeto(projeto.id, {
          ...params,
          prazo,
          valorGlobal,
          valorFinanceiro
        });
        

        queryClient.resetQueries(['all-projeto']);
        queryClient.invalidateQueries(['all-projeto']);
        queryClient.resetQueries([projeto.id, 'find-projeto']);
        queryClient.invalidateQueries([projeto.id, 'find-projeto']);
        
        queryClient.resetQueries([secretariaSelected.id, 'all-projeto-secretaria']);
        queryClient.invalidateQueries([secretariaSelected.id, 'all-projeto-secretaria']);
        
        toast('Estratégia atualizada com sucesso', {type: 'success'});
        setIsSave(false)
        handleDismiss();
        
        
      }


    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar estratégia', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {
    
  }

  const { secretariaSelected } = useUser()

  useEffect(() => {
    if (open) {
      if (!!projeto?.nome) {
        methods.setValue('nome', projeto.nome)
      }
      if (!!projeto?.etapa?.id) {
        methods.setValue('etapaId', String(projeto.etapa.id))
      }
      if (!!projeto?.eixo?.id) {
        methods.setValue('eixoId', String(projeto.eixo.id))
      }
      if (!!projeto?.status?.id) {
        methods.setValue('statusId', String(projeto.status.id))
      }
      if (!!projeto?.secretaria?.id) {
        methods.setValue('secretariaId', String(projeto.secretaria.id))
      }
      if (!!projeto?.responsavel) {
        methods.setValue('responsavel', projeto.responsavel)
      }
      if (!!projeto?.registro?.id) {
        methods.setValue('registroId', String(projeto.registro.id))
      }
      if (!!projeto?.prazo) {
        const dt = formatDateString(projeto.prazo)
        methods.setValue('prazo', dt);
      }
      if (!!projeto?.ano) {
        methods.setValue('ano', projeto.ano)
      }
      if (!!projeto?.valorGlobal) {
        methods.setValue('valorGlobal', projeto?.valorGlobal)
      }
      if (!!projeto?.valorFinanceiro) {
        methods.setValue('valorFinanceiro', projeto?.valorFinanceiro)
      }
      if (!!projeto?.orgao?.id) {
        methods.setValue('orgaoId', String(projeto.orgao.id))
      }
      if (!!projeto?.origem) {
        methods.setValue('origem', projeto?.origem)
      }
      if (!!projeto?.pautaEnum) {
        methods.setValue('pautaEnum', projeto?.pautaEnum)
      }
      if (!!projeto?.tipoProjetoEnum) {
        methods.setValue('tipoProjetoEnum', projeto?.tipoProjetoEnum)
      }
      if (!!projeto?.convenio) {
        methods.setValue('convenio', projeto?.convenio)
      }
      if (!!projeto?.empresa) {
        methods.setValue('empresa', projeto?.empresa)
      }
    }
  }, [open, projeto])

  function handleDismiss() {
    methods.reset();
    handleOpen();
  }

  return (
    <Dialog
      size="xxl"
      open={open}
      handler={handleDismiss}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px] h-screen">
        <div className="flex flex-row items-center justify-between">
          <div>
            <h1 className="text-dark-base text-3xl font-semibold">Editar projeto</h1>
          </div>
          <div>
            <button onClick={handleDismiss}>
              <HiX size={24} color="#425466" />
            </button>
          </div>
        </div>
        <FormProvider {...methods}>
          <div className="mt-[24px]">
            <Controller
              name="nome"
              render={({field, fieldState}) => (
                <Input label="Nome do projeto" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
              )}
            />
          </div>
          <div className="mt-[16px] flex flex-row items-center justify-between">
            {!eixoQuery.isLoading && eixoQuery.isSuccess && eixos.length > 0 && (
              <Controller
                name="eixoId"
                render={({field, fieldState}) => (
                  <Select label="Eixo" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    {eixos.map(e => (
                      <Option value={String(e.id)}>{e.nome}</Option>
                    ))}
                  </Select>
                )}
              />
            )}
            <div className="w-[16px]"></div>
            <Controller
              name="tipoProjetoEnum"
              render={({field, fieldState}) => (
                <Select label="Tipo de projeto" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid"> 
                  <Option value="OBRA">Obra</Option>
                  <Option value="SERVIÇO">Serviço</Option>
                  <Option value="AQUISIÇÃO">Aquisição</Option>
                </Select>
              )}
            />
          </div>
          <div className="mt-[24px] flex flex-row items-center justify-between">
            {!etapaQuery.isLoading && etapaQuery.isSuccess && etapas.length > 0 && (
              <Controller
                name="etapaId"
                render={({field, fieldState}) => (
                  <Select label="Etapa" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    {etapas.map(e => (
                      <Option value={String(e.id)}>{e.nome}</Option>
                    ))}
                  </Select>
                )}
              />
            )}
            <div className="w-[16px]"></div>
            {!projetoStatusQuery.isLoading && projetoStatusQuery.isSuccess && projetoStatus.length > 0 && (
              <Controller
                name="statusId"
                render={({field, fieldState}) => (
                  <Select label="Status" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="capitalize" className="border-solid">
                    {projetoStatus.map(e => (
                      <Option value={String(e.id)} className="capitalize">{e.nome.toLowerCase()}</Option>
                    ))}
                  </Select>
                )}
              />
            )}
          </div>
          <div className="mt-[24px] flex flex-row items-center justify-between">
            {!secretariaQuery.isLoading && secretariaQuery.isSuccess && secretarias.length > 0 && (
              <Controller
                name="secretariaId"
                render={({field, fieldState}) => (
                  <Select label="Secretaria" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    {secretarias.map(e => (
                      <Option value={String(e.id)}>{e.nome}</Option>
                    ))}
                  </Select>
                )}
              />
            )}
            <div className="w-[16px]"></div>
            <Controller
              name="responsavel"
              render={({field, fieldState}) => (
                <Input label="Responsável" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
            />
            <div className="w-[16px]"></div>
            {!registroQuery.isLoading && registroQuery.isSuccess && registros.length > 0 && (
              <Controller
                name="registroId"
                render={({field, fieldState}) => (
                  <Select label="Registro do objeto" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    {registros.map(e => (
                      <Option value={String(e.id)}>{e.nome}</Option>
                    ))}
                  </Select>
                )}
              />
            )}
          </div>
          <div className="mt-[24px] flex flex-row items-center justify-between">
            <Controller
              name="prazo"
              render={({field, fieldState}) => (
                <ReactInputMask mask="99/99/9999" value={field.value} onChange={field.onChange} >
                  {((inputProps: any) => {
                    return <Input {...inputProps} label="Prazo" type="tel" error={!!fieldState.error} />
                  }) as any}
                </ReactInputMask>
              )}
            />
            <div className="w-[16px]"></div>
            <Controller
              name="ano"
              render={({field, fieldState}) => (
                <Input label="Ano" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
              )}
            />
          </div>
          <div className="mt-[24px] flex flex-row items-center justify-between">
            <Controller
              name="valorGlobal"
              render={({field, fieldState}) => (
                // <Input label="Valor global" type="number" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                <NumericFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  decimalScale={2}
                  value={field.value}
                  customInput={(props: any) => <Input label="Valor global" type="number" crossOrigin={'*'} size="lg" {...props} inputRef={inputValorGlobalRef} error={fieldState.error} />}
                />
              )}
            />
            <div className="w-[16px]"></div>
            <Controller
              name="valorFinanceiro"
              render={({field, fieldState}) => (
                // <Input label="Valor global" type="number" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                <NumericFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  decimalScale={2}
                  value={field.value}
                  customInput={(props: any) => <Input label="Valor financeiro executado" type="number" crossOrigin={'*'} size="lg" {...props} inputRef={inputValorFinanceiroRef} error={fieldState.error} />}
                />
              )}
            />
          </div>
          <div className="mt-[24px] flex flex-row items-center justify-between">
            {!projetoOrgaoQuery.isLoading && projetoOrgaoQuery.isSuccess && projetoOrgao.length > 0 && (
              <Controller
                name="orgaoId"
                render={({field, fieldState}) => (
                  <Select label="Orgão" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    {projetoOrgao.map(e => (
                      <Option value={String(e.id)}>{e.nome}</Option>
                    ))}
                  </Select>
                )}
              />
            )}
            <div className="w-[16px]"></div>
            <Controller
              name="origem"
              render={({field, fieldState}) => (
                <Input label="Origem" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
              )}
            />
            <div className="w-[16px]"></div>
            <Controller
              name="pautaEnum"
              render={({field, fieldState}) => (
                <Select label="Pauta" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                  <Option value="CRITICA">Crítica</Option>
                  <Option value="POSITIVA">Positiva</Option>
                </Select>
              )}
            />
          </div>
          <div className="mt-[24px] flex flex-row items-center justify-between">
            <Controller
              name="convenio"
              render={({field, fieldState}) => (
                <Input label="Convênio" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
              )}
            />
            <div className="w-[16px]"></div>
            <Controller
              name="empresa"
              render={({field, fieldState}) => (
                <Input label="Empresa" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
              )}
            />
          </div>
          <div className="mt-[24px] flex flex-row items-center justify-between">
            <Button className="normal-case text-sm font-medium text-blue-button border-blue-button" variant="outlined" onClick={handleDismiss}>Cancelar</Button>       
            <div className="w-[16px]" />
            <Button 
              className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
              variant="outlined" 
              disabled={isSave}
              onClick={methods.handleSubmit(onSubmit, onError)}>
              {isSave ? <Spinner color="blue" /> : `Editar projeto`}
            </Button>
          </div>  
        </FormProvider>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}