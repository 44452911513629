import { useQuery } from "react-query"
import { allCenario, findCenario, findCenariosBySituacaoId } from "../../providers/cenario";

export const useAllCenario = () => {
  return useQuery(['all-cenario'], async () => await allCenario());
}

export const useFindCenario = (id: number) => {
  return useQuery([id, 'find-cenario'], async () => await findCenario(id));
}

export const useFindCenarioBySituacaoId = (idSituacao: number) => {
  return useQuery([idSituacao, 'find-cenario-by-situacao-id'], async () => await findCenariosBySituacaoId(idSituacao));
}