import { format, parseISO } from "date-fns";
import { HiOutlineStar, HiStar } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { TProjeto, TProjetoContent, findProjeto, patchFavoritar, patchFavoritarEstado } from "../../providers/projeto";
import { RiKey2Fill } from "react-icons/ri";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Progress } from "@material-tailwind/react";


type TProps = {
  data: TProjetoContent;
  isHeight?: boolean;
}

export function CardProjeto({data, isHeight = false}: TProps) {
  const [estadoFavorito, setEstadoFavorito] = useState(data.favoritoEstado || false);
  const [secretariaFavorito, setSecretariaFavorito] = useState(data.favorito || false);
  const navigate = useNavigate();
  function formatDate(date: string) {
    try {
      return format(parseISO(date), 'dd/MM/yyyy');
    } catch(err) {
      return '-'
    }
  }

  function handleGoDetail(id: number) {
    navigate(`/internal/projeto/${id}`)
  }


  async function handleFavoritarEstado() {
    try {
      await patchFavoritarEstado(data.id);
      setEstadoFavorito(prev => !prev)

      if (!estadoFavorito) {
        toast('Projeto favoritado pelo estado', {type: 'success', toastId: 1 });
      } else {
        toast('Projeto desfavoritado pelo estado', {type: 'success', toastId: 2});
      }

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        if (!estadoFavorito) {
          toast('Erro ao favoritar estado', {type: 'error', toastId: 1 });
        } else {
          toast('Erro ao desfavoritar estado', {type: 'error', toastId: 2});
        }
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
    }
  }

  async function handleFavoritar() {
    try {
      await patchFavoritar(data.id);
      setSecretariaFavorito(prev => !prev)

      if (!secretariaFavorito) {
        toast('Projeto favoritado pela secretaria', {type: 'success', toastId: 3});
      } else {
        toast('Projeto desfavoritado pela secretaria', {type: 'success', toastId: 4});
      }

    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        if (!secretariaFavorito) {
          toast('Erro ao favoritar secretaria', {type: 'error', toastId: 1 });
        } else {
          toast('Erro ao desfavoritar secretaria', {type: 'error', toastId: 2});
        }
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
    }
  }

  return (
    // border-l-[#6C8DFF] border-l-[4px]
    <div className={`shadow-md rounded-[4px] bg-white p-[24px]  relative mr-[8px] flex flex-col justify-between ${isHeight && 'min-h-[340px]'}`}>
      {estadoFavorito && secretariaFavorito && (
        <>
          <div className="w-1 h-[50%] bg-[#66CB9F] absolute left-0 top-0"></div>
          <div className="w-1 h-[50%] bg-[#FFA24C] absolute left-0 top-[50%]"></div>
        </>
      )}
      {!estadoFavorito && !secretariaFavorito && (
        <>
          <div className="w-1 h-[50%] bg-[#A8A8A8] absolute left-0 top-0"></div>
          <div className="w-1 h-[50%] bg-[#A8A8A8] absolute left-0 top-[50%]"></div>
        </>
      )}
      {estadoFavorito && !secretariaFavorito && (
        <>
          <div className="w-1 h-full bg-[#66CB9F] absolute left-0 top-0"></div>
        </>
      )}
      {!estadoFavorito && secretariaFavorito && (
        <>
          <div className="w-1 h-full bg-[#FFA24C] absolute left-0 top-0"></div>
        </>
      )}
      <div>
        <div className="flex flex-row items-center justify-between mb-[24px]">
          <div className="flex-[0.7]">
            <p className="text-lg font-semibold">{data.nome}</p>
          </div>
          <div className="flex flex-[0.3] flex-row justify-end gap-2">
            <RiKey2Fill color={(data?.registro?.nome === "Demanda G1" || data?.registro?.nome === "Programa de Governo") ? "#0062FF" : "#A8A8A8"} size={24} className="self-start min-w-[24px]"/>
            {estadoFavorito ? <HiStar color={"#66CB9F"} size={24} className="self-start min-w-[24px] cursor-pointer" onClick={handleFavoritarEstado} /> : <HiOutlineStar color={"#A8A8A8"} size={24} className="self-start min-w-[24px] cursor-pointer" onClick={handleFavoritarEstado} />}
            {secretariaFavorito ? <HiStar color={"#FFA24C"} size={24} className="self-start min-w-[24px] cursor-pointer " onClick={handleFavoritar} /> : <HiOutlineStar color={"#A8A8A8"} size={24} className="self-start min-w-[24px] cursor-pointer" onClick={handleFavoritar} />}
          </div>
        </div>
        <div className="flex flex-row items-start">
          <div className="flex-[0.6]">
            <label className="text-dark-tint text-xs font-normal">Eixo</label>
            <p>{data?.eixo?.nome || '-'}</p>
          </div>
          <div className="flex-[0.4]">
            <label className="text-dark-tint text-xs font-normal">Etapa</label>
            <p>{data?.etapa?.nome || '-'}</p>
          </div>
        </div>
        <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[16px]"></div>
        {/* <div className="flex flex-row items-center">
          <div className="flex-[0.6]">
            <label className="text-dark-tint text-xs font-normal">Última ação realizada</label>
            <p>{data.ultimaAcaoRealizada || '-'}</p>
          </div>
          <div className="flex-[0.4]">
            <label className="text-dark-tint text-xs font-normal">Data da realização</label>
            <p>{formatDate(data.dataRealizacao || '-')}</p>
          </div>
        </div> */}
        {/* <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[16px]"></div>
        <div className="flex flex-row items-center ">
          <div className="flex-[0.6]">
            <label className="text-dark-tint text-xs font-normal">Próxima ação a realizar</label>
            <p>{data.proximaAcao || '-'}</p>
          </div>
          <div className="flex-[0.4]">
            <label className="text-dark-tint text-xs font-normal">Prazo próxima ação</label>
            <p>{formatDate(data.prazoProximaAcao || '-')}</p>
          </div>
        </div> */}
        {/* <div className="flex h-[0.5px] w-full bg-[#E4E4E4] my-[16px]"></div> */}
      </div>
      
      

      <div className="">
        <div className="mb-[16px]">
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 lg:gap-0">
            <p className="text-dark-tint font-light text-xs">% da execução</p>
            <p className="text-dark-tint font-light text-sm">{data?.percentualExecutado || 0}%</p>
          </div>
          <Progress value={data?.percentualExecutado || 0} size="lg" color="blue" />
        </div>
        <a className="text-[#3A57F2] text-xs cursor-pointer" onClick={() => handleGoDetail(data.id)}>Ver projeto {'>'}</a>
      </div>
      
    </div>
  )
}