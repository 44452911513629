import { api } from "../services/api";
import { allEixo } from "./eixo";
import { allSecretaria } from "./secretaria";

export type TSolucaoInicial = {
  id: number;
  nome: string;
  descricao: string;
  eixoId: number;
  responsavelId: number;
  custoPrevisto: number;
  statusId: number;
  situacaoId: number;
  status: TSolucaoInicialStatus;
}

type TSolucaoInicialStatus = {
  id: number;
  nome: string;
}

export async function findSolucaoBySituacaoId(idSituacao: number): Promise<TSolucaoInicial[]> {
  try {
    const { data } = await api.get(`/solucoes-iniciais?situacaoId=${idSituacao}`);
    const solucao = data.data;

    console.log(data)

    if (solucao.length > 0) {
      const statusData = await allSolucaoStatus();

      const result = solucao.map((i: TSolucaoInicial) => {
        return {
          ...i,
          status: statusData.find(sd => sd.id === i.statusId)
        }
      })
    

      return result;

    }
    return [];
  } catch (err) {
    throw err;
  }
}

export async function findSolucaoBySituacaoIdReport(idSituacao: number): Promise<TSolucaoInicial[]> {
  try {
    const { data } = await api.get(`/solucoes-iniciais?situacaoId=${idSituacao}`);
    const solucao = data.data;

    console.log('aaaaaaaaaaa', data)

    if (solucao.length > 0) {
      const statusData = await allSolucaoStatus();

      let result = solucao.map((i: TSolucaoInicial) => {
        return {
          ...i,
          status: statusData.find(sd => sd.id === i.statusId)
        }
      })

      const eixosData = await allEixo();
      result = result.map((i: TSolucaoInicial) => {
        return {
          ...i,
          eixo: eixosData.find(sd => sd.id === i.eixoId)
        }
      })

      const secretariaData = await allSecretaria();
      result = result.map((i: TSolucaoInicial) => {
        return {
          ...i,
          responsavel: secretariaData.find(sd => sd.id === i.responsavelId)
        }
      })

      return result;

    }
    return [];
  } catch (err) {
    throw err;
  }
}

export async function createSolucao(params: Omit<TSolucaoInicial, 'id'>) {
  try {
    const { data } = await api.post(`/solucoes-iniciais`, params);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function findSolucao(id: number): Promise<TSolucaoInicial> {
  try {
    const { data } = await api.get(`/solucoes-iniciais/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function allSolucaoStatus(): Promise<TSolucaoInicialStatus[]> {
  try {
    const { data } = await api.get(`/solucoes-iniciais/status`);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function updateSolucao(id: number, params: Omit<TSolucaoInicial, 'id'>) {
  try {
    const { data } = await api.put(`/solucoes-iniciais/${id}`, params);
    return data.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteSolucao(id: number) {
  try {
    const { data } = await api.delete(`/solucoes-iniciais/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
}