import { Alert } from "@material-tailwind/react";
import { FaSearch } from "react-icons/fa";

type TProps = {
  text: string;
}

export function EmptyState({ text }: TProps) {
  return (
    <Alert variant="outlined" className="flex flex-row items-center justify-center h-[100px] border-dashed border-gray-400 bg-white">
      <div className="flex">
        {/* <FaSearch size={28} /> */}
        <h1 className="font-medium text-xl ml-[16px] text-gray-500">{text}</h1>
      </div>
    </Alert>
  )
}