import { Alert, Button, Spinner } from "@material-tailwind/react";
import { TableImpacto } from "../TableImpacto";
import { useState } from "react";
import { DialogFormImpacto } from "../DialogFormImpacto";
import { useFindImpactoBySituacaoId } from "../../hooks/query/useImpactoQuery";
import { EmptyState } from "../EmptyState";
import { BoxError } from "../BoxError";
import { ImpactoMobile } from "../TableImpacto/ImpactoMobile";

type TProps = {
  situacaoId: number;
}

export function BoxImpacto({ situacaoId }: TProps) {
  const [openForm, setOpenForm] = useState(false)

  const impactoQuery = useFindImpactoBySituacaoId(situacaoId);
  const impactos = impactoQuery.data || [];

  function handleOpen() {
    setOpenForm((cur) => !cur)
  }
  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-[16px]">
        <h1 className="text-dark-base text-lg font-normal">Impacto</h1>
        <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleOpen}>Adicionar</Button>
      </div>
      {!impactoQuery.isLoading && impactoQuery.isSuccess && (
        <>
          {impactos.length > 0 ? 
            (
              <div>
                <div className="hidden lg:block">
                  <TableImpacto data={impactos} />
                </div>
                <div className="flex overflow-x-scroll pb-[16px] lg:hidden">
                  {impactos.map(imp => (
                    <ImpactoMobile data={imp} />
                  ))}
                </div>
              </div>
            )
           : (<>
            <EmptyState text="Nenhum impacto encontrado" />
          </>)}
        </>
      )}
      {impactoQuery.isLoading && (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!impactoQuery.isLoading && impactoQuery.isError && (
        <div className="w-full">
          <BoxError text="Erro ao carregar as informações" />
        </div>
      )}
      
      <DialogFormImpacto open={openForm} handleOpen={handleOpen} idSituacao={situacaoId} />
    </div>
  )
}