import { Button, Card, Dialog, Input } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import { TEstrategia, deleteEstrategia } from "../../providers/estrategia";
import { format, parseISO } from "date-fns";
import { DialogRemoveItem } from "../DialogRemoveItem";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { DialogFormEstrategia } from "../DialogFormEstrategia";
import { setValueMasks } from "../../utils/number.utils";
import { TPlanoAcao, atuacaoByPlanoAcaoId } from "../../providers/plano-acao";
import { useAllSecretaria } from "../../hooks/query/useSecretariaQuery";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  data: TPlanoAcao;
}

export function DialogViewAcoesMobile({handleOpen, open, data}: IProps) {
  const [dialogRemove, setDialogRemove] = useState(false);
  const [dialogView, setDialogView] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [dialogFormEstrategia, setDialogFormEstrategia] = useState(false);
  const [atuacoes, setAtuacoes] = useState<any[]>([])
  const queryClient = useQueryClient();

  function handleDialogRemove() {
    setDialogRemove(prev => !prev);
  }

  function handleDialogForm() {
    setDialogFormEstrategia(prev => !prev);
  }

  function formatDate(date: string) {
    try {
      return format(parseISO(date), 'dd/MM/yyyy');
    } catch(err) {
      return '-'
    }
  }

  async function handleRemove() {
    try {
      setLoadingRemove(true);
      await deleteEstrategia(data.id);
      await queryClient.resetQueries([data.situacaoId, 'find-estrategia-by-situcao-id']);
      await queryClient.invalidateQueries([data.situacaoId, 'find-estrategia-by-situcao-id']);
      toast('Estratégia excluída com sucesso', {type: 'success'});
      setLoadingRemove(false);
      handleOpen();
      handleDialogRemove();
    } catch (err) {
      setLoadingRemove(false);
      toast('Erro ao excluir estratégia', {type: 'error'});
    }
  }

  useEffect(() => {
    async function init() {
      const response = await atuacaoByPlanoAcaoId(data.id);
      if (response.length > 0) {
        setAtuacoes(response)
      } else {
        setAtuacoes([])
      }
    }

    if (open && !!data.id) {
      init();
    }
  }, [open, data.id])

  const allSecretariaQuery = useAllSecretaria();
  const secretarias = allSecretariaQuery.data || [];

  function handleSecretariaName(id: number = 0) {
    try {
      if (secretarias.length > 0) {
        const name = secretarias.find(s => s.id === id)?.nome;
        return name || id;
      }

      return id;
    } catch (err) {
      return id
    }
  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between pb-[24px] border-b border-b-[#E4E4E4]">
            <div className="flex-[0.8]">
              <h1 className="text-dark-base text-3xl font-semibold">{data.nome}</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <div className="max-h-[42rem] overflow-y-scroll">
            <div className="mt-4">
              <label className="text-sm font-light text-[#4A4C56]">Secretaria</label>
              <p className="text-dark-base font-normal text-base">{handleSecretariaName(data.secretariaId)}</p>
            </div>
            <div className="mt-4">
              <label className="text-sm font-light text-[#4A4C56]">Responsável</label>
              <p className="text-dark-base font-normal text-base">{data.responsavel}</p>
            </div>
            <div className="mt-4">
              <label className="text-sm font-light text-[#4A4C56]">Prazo</label>
              <p className="text-dark-base font-normal text-base">{formatDate(data.prazoAcao)}</p>
            </div>
            <div className="mt-4">
              <label className="text-sm font-light text-[#4A4C56]">Custo previsto</label>
              <p className="text-dark-base font-normal text-base">R$ {setValueMasks(data.custoPrevisto || 0)}</p>
            </div>
            <div className="mt-4">
              <label className="text-sm font-light text-[#4A4C56]">Recursos necessários</label>
              <p className="text-dark-base font-normal text-base">{data.recursosNecessarios}</p>
            </div>

            <div className="w-full h-[1px] bg-[#E4E4E4] my-[16px]" />

            {atuacoes.length > 0 ? (
              <>
                {atuacoes.map(a => (
                  <div key={a.id} className="p-[16px] rounded mb-[10px] bg-[#F5F8FA]">
                    <div className="mb-4">
                      <label className="text-sm font-light text-[#4A4C56]">Atuação</label>
                      <p className="text-dark-base font-normal text-base">{a.descricao}</p>
                    </div>
                    <div className="mb-4">
                      <label className="text-sm font-light text-[#4A4C56]">Data</label>
                      <p className="text-dark-base font-normal text-base">{formatDate(a.dataCriacao)}</p>
                    </div>
                    <div>
                      <label className="text-sm font-light text-[#4A4C56]">Usuário</label>
                      <p className="text-dark-base font-normal text-base">{a.autor}</p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
              </>
            )}
            
          </div>
        </div>
        <ToastContainer />
        <DialogRemoveItem handleOpen={handleDialogRemove} open={dialogRemove} handleConfirm={handleRemove} loadingConfirm={loadingRemove}  />
        {/* <DialogFormEstrategia handleOpen={handleDialogForm} open={dialogFormEstrategia} estrategia={data} idSituacao={data.situacaoId} /> */}
      </Card>
    </Dialog>
  )
}