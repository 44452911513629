import { Button, Card, Dialog, Input, Option, Select, Spinner } from "@material-tailwind/react";
import { HiX } from "react-icons/hi";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useImpactoStatus } from "../../hooks/query/useImpactoQuery";
import { TImpacto, createImpacto, updateImpacto } from "../../providers/impacto";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "react-query";
import InputMask from 'react-input-mask';
import { NumericFormat } from "react-number-format";
import { format, parse, parseISO } from "date-fns";
import { TResponseAllDemanda, createDemanda, updateDemanda } from "../../providers/demandas";

type IProps = {
  open: boolean;
  handleOpen: () => void;
  currentDemanda?: TResponseAllDemanda
}

type TFormData = {
  nome: string;
  riscoEnum: string;
  comarca: string;
  estimativa: string;
  valor: number;
  registro: string;
  parteAdversa: string;
  statusEnum: string;
  secretariaId: number;
}

const formSchema: yup.AnyObjectSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  // unidade: yup.string().required('Campo obrigatório'),
  // quantidade: yup.number().required('Campo obrigatório'),
  // statusId: yup.string().required('Campo obrigatório'),
})

export function DialogFormDemanda({handleOpen, open, currentDemanda = {} as TResponseAllDemanda}: IProps) {
  const [isSave, setIsSave] = useState(false);

  const inputRef = useRef<any>(null);

  const queryClient = useQueryClient();

  const { ...methods } = useForm<TFormData>(
    {
      resolver: yupResolver(formSchema)
    }
  );

  function handleDismiss() {
    methods.reset();
    handleOpen();
  }

  useEffect(() => {
    if (open) {
      if (!!currentDemanda.comarca) {
        methods.setValue('comarca', currentDemanda.comarca)
      }
  
      if (!!currentDemanda.estimativa) {
        const dtE = formatDate(currentDemanda.estimativa);
        methods.setValue('estimativa', dtE)
      }
  
      if (!!currentDemanda.nome) {
        methods.setValue('nome', currentDemanda.nome)
      }
  
      if (!!currentDemanda.parteAdversa) {
        methods.setValue('parteAdversa', currentDemanda.parteAdversa)
      }
  
      if (!!currentDemanda.registro) {
        methods.setValue('registro', currentDemanda.registro)
      }
  
      if (!!currentDemanda.riscoEnum) {
        methods.setValue('riscoEnum', currentDemanda.riscoEnum)
      }
  
      if (!!currentDemanda.statusEnum) {
        methods.setValue('statusEnum', currentDemanda.statusEnum)
      }
  
      if (!!currentDemanda.valor) {
        methods.setValue('valor', currentDemanda.valor)
      }
    }

  }, [currentDemanda, open])

  function formatDate(date: string) {
    try {
      const splitDate = date.split('T');
      const splitDate2 = splitDate[0].split('-');
      return `${splitDate2[2]}/${splitDate2[1]}/${splitDate2[0]}`;
    } catch(err) {
      return ''
    }
  }

  const onSubmit: SubmitHandler<TFormData> = async (params) => {
    try {
      setIsSave(true)
      let estimativa = '';

      if (!!params.estimativa) {
        const prazoEstimadoDate = parse(params.estimativa, 'dd/MM/yyyy', new Date());
        estimativa = prazoEstimadoDate.toISOString();
      }

      let valor = null;
      if (!!inputRef.current?.value) {
        valor = inputRef.current.value.replace('R$ ', '');
        valor = valor.replaceAll('.', '');
        valor = valor.replace(',', '.');
      }

      const req = {
        ...params,
        estimativa,
        valor,
        secretariaId: 11
      }

      if (!!currentDemanda.id) {
        await updateDemanda(currentDemanda.id, req);
        toast('Demanda atualizada com sucesso', {type: 'success'});
      } else {
        await createDemanda(req);
        toast('Demanda criada com sucesso', {type: 'success'});
      }


      setIsSave(false)
      handleDismiss();

      queryClient.resetQueries(['all-demanda']);
      queryClient.invalidateQueries(['all-demanda']);
    } catch (err: any) {
      if (!err?.response?.data?.errors?.length) {
        toast('Erro ao criar demanda', {type: 'error'});
      } else {
        toast(err.response.data.errors.join(' | '), {type: 'error'});
      }
      setIsSave(false)
    }
  }

  const onError = async () => {

  }

  return (
    <Dialog
      size="lg"
      open={open}
      handler={handleOpen}
      className="bg-transparent shadow-none"
    >
      <Card className="p-[24px]">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div>
              <h1 className="text-dark-base text-3xl font-semibold">{!!currentDemanda.id ? `Editar` : 'Nova'} demanda</h1>
            </div>
            <div>
              <button onClick={handleOpen}>
                <HiX size={24} color="#425466" />
              </button>
            </div>
          </div>
          <FormProvider {...methods}>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="nome"
                render={({field, fieldState}) => (
                  <Input label="Nome da demanda"  crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              <div className="w-[16px]"></div>
              <Controller
                name="riscoEnum"
                render={({field, fieldState}) => (
                  <Select label="Risco" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    <Option value="BAIXO">Baixo</Option>
                    <Option value="MEDIO">Médio</Option>
                    <Option value="ALTO">Alto</Option>
                  </Select>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="comarca"
                render={({field, fieldState}) => (
                  <Input label="Comarca" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              <div className="w-[16px]"></div>
              <Controller
                name="estimativa"
                render={({field, fieldState}) => (
                  <InputMask mask="99/99/9999" value={field.value} onChange={field.onChange} >
                    {((inputProps: any) => {
                      return <Input {...inputProps} label="Estimativa de julgamento e execucão" type="tel" error={!!fieldState.error} />
                    }) as any}
                  </InputMask>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="valor"
                render={({field, fieldState}) => (
                  <NumericFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    decimalScale={2}
                    value={field.value}
                    customInput={(props: any) => <Input label="Valor" type="number" crossOrigin={'*'} size="lg" {...props} inputRef={inputRef} error={fieldState.error} />}
                  />
                )}
              />
              <div className="w-[16px]"></div>
              <Controller
                name="registro"
                render={({field, fieldState}) => (
                  <Input label="Número/registero do processo" crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
            </div>
            <div className="flex flex-row items-center justify-between mt-[16px]">
              <Controller
                name="parteAdversa"
                render={({field, fieldState}) => (
                  <Input label="Parte adversa"  crossOrigin={'*'} size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error}/>
                )}
              />
              <div className="w-[16px]"></div>
              <Controller
                name="statusEnum"
                render={({field, fieldState}) => (
                  <Select label="Status" size="lg" onChange={field.onChange} value={field.value} error={!!fieldState.error} className="border-solid">
                    {/* [ A_INICIAR, EM_EXECUCAO, CONCLUIDO, IMPEDIDO, PAUSADO ] */}
                    <Option value="A_INICIAR">A iniciar</Option>
                    <Option value="EM_EXECUCAO">Em execução</Option>
                    <Option value="CONCLUIDO">Concluído</Option>
                    <Option value="IMPEDIDO">Impedido</Option>
                    <Option value="PAUSADO">Pausado</Option>
                  </Select>
                )}
              />
            </div>
            <div className="mt-[24px] flex flex-row justify-between">
              <Button className="normal-case text-sm font-medium text-blue-button border-blue-button border-solid" variant="outlined" onClick={handleDismiss}>Cancelar</Button>     
              {!!currentDemanda.id ? (
                <Button 
                  className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                  variant="outlined" 
                  disabled={isSave}
                  onClick={methods.handleSubmit(onSubmit, onError)}
                >
                  {isSave ? <Spinner color="blue" /> : `Editar`}
                </Button>
              ) : (
                <Button 
                  className="normal-case text-sm font-medium text-white bg-blue-button border-blue-button" 
                  variant="outlined" 
                  disabled={isSave}
                  onClick={methods.handleSubmit(onSubmit, onError)}
                >
                  {isSave ? <Spinner color="blue" /> : `Adicionar`}
                </Button>
              )}
            </div>
          </FormProvider>
        </div>
      </Card>
      <ToastContainer />
    </Dialog>
  )
}