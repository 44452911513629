import { api } from "../services/api";

export type TSecretaria = {
  id: number;
  nome: string;
}

export async function allSecretaria(): Promise<TSecretaria[]> {
  try {
    const { data } = await api.get(`/secretarias`);
    return data.data;
  } catch (err) {
    throw err;
  }
}