import { Link } from "react-router-dom";
import { TSituacao } from "../../providers/situacao"
import { formatTipoCrise } from "../../utils/situacao.utils";

type Props = {
  data: TSituacao;
}

export function CardCrise({ data }: Props) {
  return (
    <Link to={`/internal/sala-situacao/${data.id}`}>
      <div className="p-[24px] bg-[#F5F8FA] rounded-[4px] flex mr-[16px] min-h-[310px]">
        <div>
          <h1 className="text-lg text-[#425466] font-bold mt-[4px]">{data.nome}</h1>
          <div>
            <p className="text-sm text-[#425466] font-light">{data.regiao?.nome}</p>
          </div>
          <div className="mt-[24px]">
            <p className="text-sm text-[#425466] font-light">Tipo de crise</p>
            <p className="text-[16px] text-[#425466] font-normal capitalize">{!!data?.tipoCriseEnum ? formatTipoCrise(data.tipoCriseEnum) : '-'}</p>
          </div>
          <div className="mt-[24px]">
            <p className="text-sm text-[#425466] font-light">Frequência</p>
            <p className="text-[16px] text-[#425466] font-normal capitalize">{data.frequenciaOcorrenciaEnum.toLowerCase()}</p>
          </div>
          <div className="mt-[24px]">
            <p className="text-sm text-[#425466] font-light">Gravidade</p>
            {data.gravidadeOcorrenciaEnum.toUpperCase() === 'GRAVE' && (
              <p className="text-[16px] text-red-500 font-normal capitalize ">{data.gravidadeOcorrenciaEnum.toLowerCase()}</p>
            )}
            {data.gravidadeOcorrenciaEnum.toUpperCase() === 'MEDIA' && (
              <p className="text-[16px] text-orange-500 font-normal capitalize ">{data.gravidadeOcorrenciaEnum.toLowerCase()}</p>
            )}
            {data.gravidadeOcorrenciaEnum.toUpperCase() === 'LEVE' && (
              <p className="text-[16px] text-blue-500 font-normal capitalize ">{data.gravidadeOcorrenciaEnum.toLowerCase()}</p>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}