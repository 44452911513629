type TProps = {
  icon: React.ReactNode;
  title: string;
  value: number;
  isMoney?: boolean;
  setType: () => void
  active?: boolean;
}

export function CardTotalizador({ icon, title, value, isMoney = false, setType, active = false}: TProps) {

  const setValueMasks = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);
  };

  return (
    <div className={`rounded-[8px] border border-[#EBEBED] bg-white px-[24px] py-[16px] flex flex-col items-center justify-between cursor-pointer ${active && 'border border-solid border-blue-400'}`} onClick={setType}>
      {icon}
      <div className="mt-[16px]">
        <h1 className="text-[24px] lg:text-[32px] font-bold">{isMoney ? `R$ ${setValueMasks(value)}` :value}</h1>
      </div>
      <div>
        <p className="text-[12px] font-light">{title}</p>
      </div>
    </div>
  )
}