import axios, { AxiosInstance } from 'axios';

import qs from 'qs';

type PromiseType = {
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
};

type ProcessQueueParams = {
  error: Error | null;
  token: string | null;
};

type RegisterInterceptTokenManagerProps = {
  signOut: () => void;
  refreshTokenUpdated: (newToken: string, newRefreshToken: string) => void;
};

type APIInstanceProps = AxiosInstance & {
  registerInterceptTokenManager: ({}: RegisterInterceptTokenManagerProps) => () => void;
};

const api = axios.create({}) as APIInstanceProps;;

let isRefreshing = false;
let failedQueue: Array<PromiseType> = [];

const processQueue = ({ error, token = null }: ProcessQueueParams): void => {
  failedQueue.forEach((request) => {
    if (error) {
      request.reject(error);
    } else {
      request.resolve(token);
    }
  });

  failedQueue = [];
};

api.registerInterceptTokenManager = ({ signOut, refreshTokenUpdated }) => {
  const interceptTokenManager = api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (requestError) => {
      if (requestError?.response?.status === 401) {

          const oldToken = localStorage.getItem('ProcessoGov:refreshToken')

          if (!oldToken) {
            signOut();
            return Promise.reject(requestError);
          }

          const originalRequest = requestError.config;

          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                return axios(originalRequest);
              })
              .catch((err) => {
                throw err;
              });
          }

          isRefreshing = true;

          return new Promise(async (resolve, reject) => {
            try {

              const realmKeycloak = localStorage.getItem('ProcessoGov:keycloak:realm') || '';
              const clientIdKeycloak = localStorage.getItem('ProcessoGov:keycloak:clientId') || '';
              const urlKeycloak = localStorage.getItem('ProcessoGov:keycloak:url') || '';

              let data2 = qs.stringify({
                'client_id': clientIdKeycloak,
                'grant_type': 'refresh_token',
                'refresh_token': oldToken
              });

              const { data } = await axios.post(`${urlKeycloak}realms/${realmKeycloak}/protocol/openid-connect/token`, data2, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
              localStorage.setItem('ProcessoGov:token', data.access_token);
              localStorage.setItem('ProcessoGov:refreshToken', data.refresh_token);

              api.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${data.access_token}`;
              api.defaults.headers.authorization = `Bearer ${data.access_token}`;
              refreshTokenUpdated(data.access_token, data.refresh_token);
              processQueue({ error: null, token: data.access_token });
              resolve(originalRequest);
            } catch (error: any) {
              processQueue({ error, token: null });
              signOut();
              reject(error);
            } finally {
              isRefreshing = false;
            }
          });

      }
      return Promise.reject(requestError)
    }
  );

  return () => {
    api.interceptors.response.eject(interceptTokenManager);
  };
};

export { api };
