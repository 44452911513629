type props = {
  calendarEvent: {
    id: string | number
    title: string
    start: string
    end: string
    projetoId: number;
    situacaoId: number;
  }
}

export default function EventoMesCustom({ calendarEvent }: props) {
  

  return (
    <div
      style={{
        backgroundColor: calendarEvent.projetoId ? '#6F5FB8' : calendarEvent.situacaoId ? '#BC8802' : '#FFA24C',
        color: 'white',
        width: '100%',
        fontSize: 12,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '4px 8px'
      }}
    >
      {calendarEvent.title}
    </div>
  )
}